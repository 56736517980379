import { makeVar, ReactiveVar } from "@apollo/client";
import { isString } from "lodash";

// Based on this open Apollo PR: https://github.com/apollographql/apollo-client/pull/7148

const getCleanValueForStorage = (value: any) => {
  return isString(value) ? value : JSON.stringify(value);
};
const getOriginalValueFromStorage = (initialValue: any, value: any) => {
  return isString(initialValue) ? value : JSON.parse(value);
};

const makeVarPersisted = <T = any>(
  initialValue: T,
  config?: {
    replace?: boolean;
    storage: typeof window.localStorage;
    storageKey: string;
  }
): ReactiveVar<T> => {
  const rv = makeVar(initialValue);

  // Local storage is shared between windows and tabs. This event handler ensures the state stays synchronized
  if (config && config.storage === window.localStorage) {
    window.addEventListener("storage", ({ key, newValue }) => {
      if (key === config.storageKey) {
        const value = getCleanValueForStorage(rv());
        if (value !== newValue) {
          rv(getOriginalValueFromStorage(initialValue, newValue));
        }
      }
    });
  }

  const rvFn = function (newState?: T) {
    if (arguments.length > 0) {
      try {
        if (config) {
          const oldValue = config.storage.getItem(config.storageKey);
          const newValue = getCleanValueForStorage(newState);

          if (newValue !== oldValue) {
            config.storage.setItem(config.storageKey, newValue);
          }
        }
      } catch (error) {
        console.warn(error);
      }
      return rv(newState);
    } else {
      return rv();
    }
  };

  const restore = () => {
    try {
      if (config) {
        const previousValue = config.storage.getItem(config.storageKey);

        if (previousValue) {
          rv(getOriginalValueFromStorage(initialValue, previousValue));
        }
      }
    } catch {
      // ignore
    }
  };

  rvFn.onNextChange = rv.onNextChange;
  rvFn.attachCache = rv.attachCache;
  rvFn.forgetCache = rv.forgetCache;

  if (config && !config.replace) {
    restore();
  }

  return rvFn;
};

export default makeVarPersisted;
