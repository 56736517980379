import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { print } from "graphql/language/printer";
import { CASHFLOW_FRAGMENT } from "../fragments/Cashflow";
import { defragment } from "../lib/graphqlTools";
import { IAnnualCashflow } from "../types/Cashflow";

export interface IGetCashflowResponse {
  cashflows: IAnnualCashflow[];
}

export const GET_CASHFLOWS = gql`
  ${defragment(`
    ${print(CASHFLOW_FRAGMENT)}
    query GetCashflows {
      cashflows {
        ...CashflowFragment
      }
    }
  `)}
`;

export const useCashflows = ({ skip = false } = {}) => {
  const { loading, data, error, called } = useQuery<IGetCashflowResponse>(
    GET_CASHFLOWS,
    { skip }
  );
  return {
    loading,
    error,
    cashflows: data && data.cashflows,
  };
};
