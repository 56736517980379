/* eslint-disable no-script-url */

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { keys, map } from "lodash";
import React from "react";

export type IRawTransaction = {
  [key: string]: string | number | Date;
  [key: number]: string | number | Date;
};

export interface IRawTransactionsProps {
  rawTransactions: IRawTransaction[];
}

export default function RawTransactions({
  rawTransactions,
}: IRawTransactionsProps) {
  const header = keys(rawTransactions[0]);
  return (
    <React.Fragment>
      <Table size="small">
        <TableHead>
          <TableRow>
            {map(header, (value, col) => (
              <TableCell style={{ fontWeight: "bold" }} key={col}>
                {value}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {map(rawTransactions, (transaction, idx) => (
            <TableRow key={idx}>
              {map(transaction, (value, col) => (
                <TableCell key={col}>
                  {value instanceof Date ? value.toString() : value}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
