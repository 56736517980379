import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Snackbar from "@material-ui/core/Snackbar";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useCallback, useMemo, useState } from "react";
import { IAnnualCashflow } from "../../types/Cashflow";
import {
  AnnualizedCashflowForm,
  useComputeAnnualizedCashflow,
} from "../forms/AnnualizedCashflowForm";
import { LoanForm } from "../forms/LoanForm";
import { MortgageForm, useComputeMortgage } from "../forms/MortgageForm";

export interface ICashflowDialogProps {
  isOpen: boolean;
  type: string;
  initialValue?: Required<IAnnualCashflow>;
  onSubmit: (cashflow: Required<IAnnualCashflow>) => void;
  onClose: () => void;
}

export default function CashflowDialog({
  onSubmit,
  type,
  isOpen,
  onClose,
  initialValue,
}: ICashflowDialogProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [cashflow, setCashflow] = useState<any>();
  const calculateMortgageCashflow = useComputeMortgage();
  const calculateAnnualizedCashflow = useComputeAnnualizedCashflow();
  const handleSubmit = useCallback(() => {
    let computed: any;
    switch (type) {
      case "mortgage": {
        computed = calculateMortgageCashflow(cashflow);
        break;
      }
      case "loan": {
        computed = calculateAnnualizedCashflow(cashflow);
        break;
      }
      case "investment": {
        computed = calculateAnnualizedCashflow(cashflow);
        break;
      }
      case "annuity": {
        computed = calculateAnnualizedCashflow(cashflow);
        break;
      }
      default: {
        throw new Error(`Unsupported cashflow type given "${type}"`);
      }
    }
    if (computed) {
      onSubmit({
        ...computed,
        type,
      });
    }
  }, [cashflow, type]);

  const error = null as any;

  const title = useMemo(() => {
    switch (type) {
      case "mortgage": {
        return "Add a Mortgage";
      }
      case "loan": {
        return "Add a Loan";
      }
      case "investment": {
        return "Add an Investment";
      }
      case "annuity": {
        return "Add an Annuity";
      }
      default: {
        return "Add a Cashflow";
      }
    }
  }, [type]);

  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={!!error}
        autoHideDuration={2500}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{error && error.message}</span>}
      />
      <Dialog
        fullScreen={fullScreen}
        disableBackdropClick
        disableEscapeKeyDown
        open={isOpen}
        onClose={onClose}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {type === "mortgage" ? (
            <MortgageForm
              onChange={setCashflow}
              initialValue={initialValue as any}
            />
          ) : null}
          {type === "loan" ? (
            <LoanForm
              onChange={setCashflow}
              initialValue={initialValue as any}
            />
          ) : null}
          {type === "investment" ? (
            <LoanForm
              type="investment"
              onChange={setCashflow}
              initialValue={initialValue}
            />
          ) : null}
          {type === "annuity" ? (
            <AnnualizedCashflowForm
              onChange={setCashflow}
              initialValue={initialValue}
            />
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
