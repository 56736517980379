import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { filter, find, flatten, get, map, omit, uniq } from "lodash";
import * as math from "mathjs";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { addBalances, addCashBalance } from "../helpers";
import { getAccountCache, useAccounts } from "../hooks/accounts";
import { useBalances, useEquivalentBalances } from "../hooks/balances";
import { getPriceStats, sortPrices } from "../lib/calculators";
import { useExchangeRates } from "../queries/GetExchangeRates";
import { useStockQuotes } from "../queries/GetStockQuotes";
import { useSearchSymbols } from "../queries/SearchSymbol";
import { CurrencyType } from "../types";
import { IAccount, IAsset } from "../types/Account";
import { IBalance } from "../types/Balance";
import { IExchangeRate } from "../types/ExchangeRate";
import { Balance } from "./Balance";
import { ProjectionChart } from "./ProjectionChart";
import { StatusIcon } from "./StatusIcon";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  balanceContainer: {
    //  paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  title: {
    textAlign: "start",
    color: theme.palette.primary.main,
  },
  text: {
    textAlign: "start",
  },
  center: {
    alignItems: "center",
  },
  rightText: {
    textAlign: "end",
  },
  balance: {
    textAlign: "right",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 480,
  },
  menuIconContainer: {
    marginLeft: 6,
    display: "flex",
    alignContent: "center",
  },
}));

export interface IHolding {
  type: string;
  symbol: string;
  description: string;
  balances: IBalance[];
}

export interface IHoldingsProps {
  holdings: IHolding[];
}
export interface IHoldingProps {
  holding: IHolding;
}

export const Holding = ({ holding }: IHoldingProps) => {
  const classes = useStyles();

  const { symbol, type, balances } = holding;

  const assets = useMemo(() => {
    const accountIds = uniq(map(balances, "account.id"));
    return map(accountIds, (accountId) =>
      find(
        getAccountCache({ accountId }).assets,
        (asset: IAsset) => asset.symbol === symbol
      )
    );
  }, [balances]);
  const isUnverified = useMemo(() => !!find(assets, "isUnverified"), [assets]);
  const name = useMemo(() => get(find(assets, "name"), "name"), [assets]);
  const altSymbol = useMemo(
    () => get(find(assets, "altSymbol"), "altSymbol"),
    [assets]
  );
  const assetCurrency = useMemo(
    () => get(find(assets, "currency"), "currency"),
    [assets]
  );

  const searchSymbol =
    type !== "Currency" && !isUnverified && !name
      ? altSymbol || symbol
      : void 0;

  const { bestMatches, loading: searchLoading } = useSearchSymbols({
    symbol: searchSymbol,
  });
  const details = useMemo(() => {
    if (bestMatches && bestMatches.length) {
      return bestMatches[0];
    }
  }, [bestMatches]);

  const ticker = details ? details.symbol : altSymbol;
  const currency = details ? (details.currency as CurrencyType) : assetCurrency;
  const { stockQuotes, loading: stockQuotesLoading } = useStockQuotes({
    symbol: ticker,
  });
  const {
    getExchangeRates,
    exchangeRates,
    loading: exchangeRatesLoading,
  } = useExchangeRates({} as any, { lazy: true });
  const { accounts } = useAccounts();
  const {
    balances: allBalances,
    getBalances,
    loading: balancesLoading,
  } = useBalances(
    {
      // accountIds: map(accounts, "id"),
    },
    { lazy: true }
  );

  const loading =
    searchLoading ||
    stockQuotesLoading ||
    exchangeRatesLoading ||
    balancesLoading;
  const isUnknownSymbol =
    isUnverified ||
    (!details &&
      !name &&
      type !== "Currency" &&
      !stockQuotesLoading &&
      !exchangeRatesLoading);
  const accountIds = useMemo(() => map(accounts, "id"), [accounts]);

  useEffect(() => {
    if (type === "Currency") {
      if (symbol !== CurrencyType.CAD) {
        getExchangeRates({
          variables: {
            input: {
              toSymbol: CurrencyType.CAD,
              fromSymbol: symbol,
              outputsize: "full",
            },
          },
        });
      }
    } else if (isUnknownSymbol) {
      getBalances({
        variables: {
          input: {
            asset: symbol,
            accountIds,
          },
        },
      });
    }
  }, [symbol, type, isUnknownSymbol, accountIds]);

  const quotesOrExchangeRates = isUnknownSymbol
    ? map(
        filter(allBalances || balances, (balance) => !!balance),
        (balance) =>
          ({ close: balance.assetPrice, date: balance.date } as IExchangeRate)
      )
    : stockQuotes && stockQuotes.length
    ? stockQuotes
    : exchangeRates && exchangeRates.length
    ? exchangeRates
    : ([
        {
          date: moment().subtract(1, "year").format("YYYY-MM-DD"),
          close: 1.0,
        },
        {
          date: moment().subtract(0.5, "year").format("YYYY-MM-DD"),
          close: 1.0,
        },
        {
          date: moment().format("YYYY-MM-DD"),
          close: 1.0,
        },
      ] as IExchangeRate[]);

  const balance = useMemo(() => {
    const totalBalance = addBalances(holding.balances)!;
    if (type === "Currency") {
      if (totalBalance.asset) {
        delete totalBalance.asset;
      }
    }
    if (stockQuotes.length) {
      const latestQuote = stockQuotes[0];
      totalBalance.assetPrice = latestQuote.close;
    } else if (exchangeRates && exchangeRates.length) {
      const latestExchange = exchangeRates[0];
      totalBalance.assetPrice = latestExchange.close;
    }

    return totalBalance;
  }, [holding.balances, exchangeRates, stockQuotes]);

  const [isGraphOpen, setIsGraphOpen] = useState(false);
  const handleClick = () => {
    setIsGraphOpen(!isGraphOpen);
  };

  const { dates, prices } = useMemo(() => {
    return sortPrices({
      prices: map(quotesOrExchangeRates, "close"),
      dates: map(quotesOrExchangeRates, ({ date }) =>
        moment(date, "YYYY-MM-DD")
      ),
    });
  }, [quotesOrExchangeRates]);

  const { medianReturn, moeReturn } = getPriceStats({
    confidence: 0.95,
    prices,
    dates,
    timestep: "years",
  });

  const projectTo = moment().add(5, "years");

  return (
    <Grid container item alignItems="center">
      <Grid container item alignItems="center" onClick={handleClick}>
        <Grid item xs={1} className={classes.text}>
          <Box display="flex" className={classes.center}>
            <Typography component="p">{symbol}</Typography>
            <span className={classes.menuIconContainer}>
              <StatusIcon
                size={14}
                loading={loading}
                warning={
                  isUnverified
                    ? "This symbol is manually marked as unverified"
                    : isUnknownSymbol
                    ? "This symbol was not found"
                    : void 0
                }
              />
            </span>
          </Box>
        </Grid>
        <Grid item xs={3} className={classes.rightText}>
          <Typography component="p">
            {details ? details.name : name || ""}
          </Typography>
        </Grid>
        <Grid item xs={2} className={classes.rightText}>
          <Typography component="p">{type}</Typography>
        </Grid>
        <Grid item xs={2} className={classes.rightText}>
          <Typography component="p">
            {Math.round(medianReturn * 10000) / 100}% ±{" "}
            {math.isNaN(moeReturn) ? "∞" : Math.round(moeReturn * 10000) / 100}%
          </Typography>
        </Grid>
        <Grid item xs={2} className={classes.balance}>
          <Typography component="p">
            {Math.round(balance.balance * 100) / 100}
          </Typography>
        </Grid>
        <Grid item xs={2} className={classes.rightText}>
          <Grid container justify="flex-end">
            <Box flexShrink={1} display="flex" style={{ gap: 3 }}>
              <Balance balance={balance} variant="subtitle2" slim />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      {isGraphOpen ? (
        <Grid item xs={12}>
          <ProjectionChart
            originalPrices={prices}
            originalDates={dates}
            projectTo={projectTo}
            currency={currency}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export const Holdings = ({ holdings }: IHoldingsProps) => {
  const classes = useStyles();
  const balances = useMemo(
    () => flatten(map(holdings, "balances")),
    [holdings]
  );
  const { equivalentBalances, loading, error } = useEquivalentBalances(
    balances,
    "CAD"
  );
  const totalBalance = useMemo(() => {
    if (equivalentBalances && equivalentBalances.length) {
      const lastBalance = equivalentBalances[equivalentBalances.length - 1];
      const balance = addCashBalance(
        {
          ...omit(lastBalance, [
            "id",
            "account",
            "equivalentAssetPrice",
            "equivalentBalance",
          ]),
          balance: lastBalance.equivalentBalance || lastBalance.balance,
          id: `${lastBalance.asset}-${lastBalance.date}`,
          account: {
            id: `${lastBalance.asset}-account`,
            currency: CurrencyType.CAD,
          } as IAccount,
        } as IBalance,
        equivalentBalances.slice(0, equivalentBalances.length - 1)
      );
      return balance;
    } else {
      return null;
    }
  }, [equivalentBalances]);
  return (
    <Card>
      <Box p={1} display="flex">
        <Box flexGrow={1} display="flex" className={classes.center}>
          <Typography variant="h6" color="primary" className={classes.title}>
            Holdings
          </Typography>
        </Box>
        {totalBalance ? (
          <Box flexShrink={1} display="flex" className={classes.center}>
            <Balance balance={totalBalance} variant="subtitle2" slim />
          </Box>
        ) : null}
      </Box>
      <Box p={1}>
        <Grid container className={classes.balanceContainer}>
          <Grid item xs={1} className={classes.text}>
            <Typography variant="subtitle1">Symbol</Typography>
          </Grid>
          <Grid item xs={3} className={classes.rightText}>
            <Typography variant="subtitle1">Name</Typography>
          </Grid>
          <Grid item xs={2} className={classes.rightText}>
            <Typography variant="subtitle1">Type</Typography>
          </Grid>
          <Grid item xs={2} className={classes.rightText}>
            <Typography variant="subtitle1">Annual ROI %</Typography>
          </Grid>
          <Grid item xs={2} className={classes.balance}>
            <Typography variant="subtitle1">Balance</Typography>
          </Grid>
          <Grid item xs={2} className={classes.balance}>
            <Typography variant="subtitle1">Cash Balance</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} direction="column">
          {map(holdings, (holding) => (
            <Holding key={holding.symbol} holding={holding} />
          ))}
        </Grid>
      </Box>
    </Card>
  );
};
