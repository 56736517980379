import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

export interface ISymbol {
  symbol: string;
  name: string;
  type: string;
  region: string;
  currency: string;
  marketOpen: string;
  marketClose: string;
  timezone: string;
  matchScore: number;
}

export interface ISearchSymbolsResponse {
  alphaVantage: {
    symbols: {
      bestMatches: ISymbol[];
    };
  };
}

export const SEARCH_SYMBOLS = gql`
  query SearchSymbols($input: AVGetSymbolInput!) {
    alphaVantage {
      symbols(input: $input) {
        bestMatches {
          symbol
          name
          type
          region
          marketOpen
          marketClose
          timezone
          currency
          matchScore
        }
      }
    }
  }
`;

export const useSearchSymbols = ({ symbol }: { symbol?: string }) => {
  const { loading, data, error, called } = useQuery<ISearchSymbolsResponse>(
    SEARCH_SYMBOLS,
    {
      variables: {
        input: {
          keywords: [symbol],
        },
      },
      skip: !symbol,
      fetchPolicy: "cache-and-network",
    }
  );
  return {
    loading,
    error,
    bestMatches: (data && data.alphaVantage.symbols.bestMatches) || [],
  };
};
