import { IAccount } from "./Account";
import { ITransaction } from "./Transaction";

export enum AssetPriceType {
  actual = "actual",
  last = "last",
}

export interface IBalance {
  assetPriceType: AssetPriceType;
  assetPrice: number;
  equivalentAssetPrice: number;
  id: string;
  balance: number;
  equivalentBalance: number;
  date: Date;
  asset: string;
  isManual: boolean;
  account: IAccount;
  transactions: ITransaction[];
}
