import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { each, filter, flatten, groupBy, map, mapKeys } from "lodash";
import moment from "moment";
import { TimeRange } from "pondjs";
import React, { useCallback, useEffect, useMemo } from "react";
import { USER_ID } from "../../constants";
import { useAccounts } from "../../hooks/accounts";
import { useBalances } from "../../hooks/balances";
import { search } from "../../hooks/search";
import {
  ChartMode,
  useChartMode,
  useSearchText,
  useSelectedAccounts,
  useTimerange,
} from "../../hooks/state";
import {
  IGetTransactionsInput,
  useTransactions,
} from "../../hooks/transactions";
import { IAccount } from "../../types/Account";
import AddStuffSpeedDial from "../AddStuffSpeedDial";
import BalanceChart from "../BalanceChart";
import CashflowChart from "../CashflowChart";
import ErrorCatcher from "../ErrorCatcher";
import Transactions from "../Transactions";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  chartContainer: {
    paddingTop: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 480, // Set this based on device size
  },
}));

function addDays(date: Date, days: number) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export default function Cashflow() {
  const classes = useStyles();
  const [chartMode, setChartMode] = useChartMode(ChartMode.cashflow);
  const toggleMode = useCallback(() => {
    if (chartMode !== ChartMode.balance) {
      setChartMode(ChartMode.balance);
    } else {
      setChartMode(ChartMode.cashflow);
    }
  }, [setChartMode, chartMode]);
  const [selectedAccounts, setSelectedAccounts] = useSelectedAccounts();
  const [selectedTimeRange, setSelectedTimeRange] = React.useState<
    typeof TimeRange | undefined
  >();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  //const datestring = (date: Date) => date.toISOString().split('T')[0]
  const {
    loading: accountLoading,
    accounts,
    error: accountError,
  } = useAccounts();
  const [timerange, setTimerange] = useTimerange();
  const [searchText] = useSearchText();

  const accountIds = useMemo(() => map(accounts, "id"), [accounts]);
  const { getTransactions, loading, loadingTransactions, transactions, error } =
    useTransactions(null!, { lazy: true });
  useEffect(() => {
    if (timerange) {
      const getTransactionsInput: IGetTransactionsInput = {
        date: {
          from: timerange.begin(),
          to: addDays(timerange.end(), 1),
        },
      };
      if (selectedAccounts) {
        getTransactionsInput["accountIds"] = map(selectedAccounts, "id");
      }
      getTransactions({ variables: { input: getTransactionsInput } });
    }
  }, [
    timerange && timerange.begin(),
    timerange && timerange.end(),
    selectedAccounts,
  ]);

  const { getBalances, ...balancesResults } = useBalances(
    {
      accountIds: map(accounts, "id"),
    },
    { lazy: true }
  );

  useEffect(() => {
    getBalances({
      variables: {
        input: {
          accountIds,
        },
      },
    });
  }, [accountIds.join(":")]);

  const searchedTransactions = useMemo(
    () =>
      transactions && searchText
        ? search(transactions, searchText, {
            threshold: 0.3,
            keys: [
              "description",
              "originalDescription",
              "category",
              "amount",
              "account.name",
              "balances.balance",
            ],
          })
        : transactions,
    [transactions, searchText]
  );

  const selectedTransactions = useMemo(() => {
    if (selectedTimeRange && searchedTransactions) {
      return filter(
        searchedTransactions,
        (transaction) =>
          new Date(moment(transaction.date).toDate()) <=
            selectedTimeRange.end() &&
          new Date(moment(transaction.date).toDate()) >=
            selectedTimeRange.begin()
      );
    }
    return searchedTransactions;
  }, [searchedTransactions, selectedTimeRange]);

  const accountsById = useMemo(() => {
    const accountsById = mapKeys(accounts, "id");
    const balances = balancesResults.balances
      ? balancesResults.balances
      : flatten(
          map(transactions, (transaction) =>
            map(transaction.balances, (balance) => ({
              ...balance,
              account: transaction.account,
            }))
          )
        );
    each(groupBy(balances, "account.id"), (balances, accountId) => {
      if (accountsById[accountId]) {
        accountsById[accountId].balances = map(balances, (balance) => ({
          ...balance,
          account: accountsById[accountId],
        }));
      }
    });
    return accountsById;
  }, [accounts, balancesResults.balances, transactions]);

  const searchedAccounts = useMemo(
    () =>
      selectedAccounts && searchText
        ? search(selectedAccounts, searchText, {
            threshold: 0.3,
            keys: [
              "type",
              "name",
              "institution.name",
              "currentBalances.balance",
            ],
          })
        : selectedAccounts,
    [selectedAccounts, searchText, accounts]
  );

  const accountBalances = useMemo(() => {
    return map(
      searchedAccounts.length ? searchedAccounts : accounts,
      (account) => ({
        ...accountsById[account.id],
      })
    );
  }, [searchedAccounts, accounts, accountsById]);

  const handleSelectAccount = useMemo(
    () => (account: IAccount | null) => {
      if (!account) {
        setSelectedAccounts([]);
      } else {
        const selectedAccountsWithoutNewAccount = filter(
          selectedAccounts,
          ({ id }) => id !== account.id
        );
        if (
          selectedAccountsWithoutNewAccount.length !== selectedAccounts.length
        ) {
          setSelectedAccounts(selectedAccountsWithoutNewAccount);
        } else {
          setSelectedAccounts([...selectedAccounts, account]);
        }
      }
    },
    [selectedAccounts]
  );

  return (
    <React.Fragment>
      <ErrorCatcher error={error || accountError} />
      <Container maxWidth="xl" className={classes.chartContainer}>
        <Paper style={{ overflow: "visible" }} className={fixedHeightPaper}>
          {(chartMode === ChartMode.cashflow && (
            <CashflowChart
              timerange={timerange}
              setTimerange={setTimerange}
              onTitleClick={toggleMode}
              setSelectedTimeRange={setSelectedTimeRange}
              transactions={transactions || []}
              loading={loadingTransactions}
            />
          )) || (
            <BalanceChart
              onTitleClick={toggleMode}
              onAccountSelection={handleSelectAccount as any}
              accountBalances={accountBalances}
              loading={loadingTransactions}
            />
          )}
        </Paper>
      </Container>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          {/* Transactions */}
          <Grid item xs={12}>
            <Transactions
              transactions={selectedTransactions || []}
              title="Transactions"
            />
          </Grid>
        </Grid>
      </Container>
      <AddStuffSpeedDial
        userId={USER_ID}
        selectedAccounts={selectedAccounts}
        accounts={accounts || []}
      />
    </React.Fragment>
  );
}
