import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import * as moment from "moment";
import { IExchangeRate } from "../types/ExchangeRate";

export interface IGetExchangeRatesInput {
  toSymbol: string;
  fromSymbol: string;
  date?: moment.Moment | Date | string;
  outputsize?: "full";
}

export const GET_EXCHANGE_RATES = gql`
  query GetExchangeRate($input: AVGetExchangeRateInput!) {
    alphaVantage {
      exchangeRate(input: $input) {
        metadata {
          toSymbol
          fromSymbol
        }
        exchangeRates {
          date
          open
          close
          high
          low
        }
      }
    }
  }
`;

export const useExchangeRates = (
  input: IGetExchangeRatesInput,
  { lazy = false } = {}
) => {
  const [getExchangeRates, { loading, data, error }] = useLazyQuery<{
    alphaVantage: {
      exchangeRate: {
        exchangeRates: IExchangeRate[];
      };
    };
  }>(GET_EXCHANGE_RATES, { variables: { input } });
  if (!lazy) {
    getExchangeRates({ variables: { input } });
  }
  return {
    loading,
    error,
    getExchangeRates,
    exchangeRates: data && data.alphaVantage.exchangeRate.exchangeRates,
  };
};
