import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { filter, groupBy, keys, map, sortBy, zipObject } from "lodash";
import React, { useMemo } from "react";
import { useToggle } from "react-use";
import { USER_ID } from "../../constants";
import { useAccounts } from "../../hooks/accounts";
import { search } from "../../hooks/search";
import { useSearchText } from "../../hooks/state";
import { AccountsBalances } from "../AccountsBalances";
import { AccountsSummary } from "../AccountsSummary";
import AddStuffSpeedDial from "../AddStuffSpeedDial";
import ErrorCatcher from "../ErrorCatcher";
import { Institution } from "../Institution";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

export default function AccountsView() {
  const classes = useStyles();
  const { loading, accounts, error } = useAccounts();
  const [searchText] = useSearchText();

  const [showInactiveAccounts, toggleInactiveAccounts] = useToggle(false);
  const activeAccounts = useMemo(
    () => (showInactiveAccounts ? accounts : filter(accounts, "isActive")),
    [showInactiveAccounts, accounts]
  );
  const searchedAccounts = useMemo(
    () =>
      activeAccounts && searchText
        ? search(activeAccounts, searchText, {
            threshold: 0.3,
            keys: [
              "type",
              "name",
              "institution.name",
              "currentBalances.balance",
            ],
          })
        : activeAccounts,
    [activeAccounts, searchText]
  );

  const accountsByInstitution = useMemo(
    () => groupBy(searchedAccounts, "institution.id"),
    [searchedAccounts]
  );
  const institutionByInstitutionId = useMemo(
    () =>
      zipObject(
        keys(accountsByInstitution),
        map(accountsByInstitution, "0.institution")
      ),
    [accountsByInstitution]
  );
  const sortedInstitutions = useMemo(
    () => sortBy(map(accountsByInstitution, "0.institution"), "name"),
    [accountsByInstitution]
  );

  /*
  const [selectedAccounts, setSelectedAccounts] = useSelectedAccounts()
  const selectedAccountsByInstitution = useMemo(() => groupBy(selectedAccounts, 'institution.id'), [selectedAccounts]);
  const setSelectedAccountsPerInstitution = useCallback((institutionId: string) => (accounts: IAccount[]) => {
    setSelectedAccounts([
      ...filter(selectedAccounts, account => account.institution.id !== institutionId),
      ...accounts
    ])
  }, []);
  */

  /*
  const { true: assets, false: liabilities } = groupBy(reverse(sortBy(searchedAccounts, 'balance.amount')), 'isAsset')

  const setSelectedAssets = (accounts: IAccount[]) => setSelectedAccounts([
    ...filter(selectedAccounts, ['isAsset', false]),
    ...accounts
  ])

  const setSelectedLiabilities = (accounts: IAccount[]) => setSelectedAccounts([
    ...filter(selectedAccounts, ['isAsset', true]),
    ...accounts
  ])
    */

  return (
    <React.Fragment>
      <ErrorCatcher error={error || error} />
      <Container maxWidth="md">
        <Grid container spacing={2} className={classes.container}>
          <Grid item xs={12} sm={6} md={8}>
            <AccountsBalances
              accounts={searchedAccounts}
              showInactiveAccounts={showInactiveAccounts}
              toggleInactiveAccounts={toggleInactiveAccounts}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AccountsSummary
              accounts={searchedAccounts}
              showInactiveAccounts={showInactiveAccounts}
              toggleInactiveAccounts={toggleInactiveAccounts}
            />
          </Grid>
          {map(sortedInstitutions, (institution) => (
            <Grid item xs={12} key={institution.id}>
              <Institution
                institution={institution}
		showInactiveAccounts={showInactiveAccounts}
                accounts={accountsByInstitution[institution.id]}
              />
            </Grid>
          ))}
        </Grid>
        {/*map(accountsByInstitution, (accounts, institutionId) => (
          <Grid item xs={12} key={institutionId}>
            <Institution
              institution={institutionByInstitutionId[institutionId]}
              accounts={accounts}
            />
            <Accounts
              accounts={accounts}
              selectedAccounts={selectedAccountsByInstitution[institutionId]}
              isLoading={loading}
              title={institutionByInstitutionId[institutionId].name}
              onAccountSelect={setSelectedAccountsPerInstitution(institutionId)}
            />
          </Grid>
        ))*/}
        {/*
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Accounts
                accounts={assets}
                selectedAccounts={filter(selectedAccounts, ['isAsset', true])}
                isLoading={loading}
                title="Assets"
                onAccountSelect={setSelectedAssets}
              />
            </Grid>
            <Grid item xs={12}>
              <Accounts
                accounts={liabilities}
                selectedAccounts={filter(selectedAccounts, ['isAsset', false])}
                isLoading={loading}
                initialOrder="asc"
                title="Liabilities"
                onAccountSelect={setSelectedLiabilities}
              />
            </Grid>
          </Grid>
        </Container>
        */}
      </Container>
      <AddStuffSpeedDial userId={USER_ID} accounts={accounts || []} />
    </React.Fragment>
  );
}
