import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React from "react";

export default function Title(props: any) {
  return (
    <Typography
      style={props.style || {}}
      component="h2"
      variant="h6"
      color="primary"
      gutterBottom
    >
      {props.children}
    </Typography>
  );
}

Title.propTypes = {
  style: PropTypes.any,
  children: PropTypes.node,
};
