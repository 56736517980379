import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { TRANSACTION_BASE_FRAGMENT } from "../fragments/Transaction";
import { IBalance } from "../types/Balance";
import { ITransaction, TransactionType } from "../types/Transaction";
import { IIDInput } from "./CreateTransactions";

export interface IUpdateTransactionInput {
  id: string;
  account: IIDInput;
  amount: number;
  description: string;
  type: TransactionType;
  category?: string;
  investment?: string;
  price?: number;
  units?: number;
  date?: Date;
  labels?: string;
  balances?: IBalance[];
  originalDescription?: string;
}

export const UPDATE_TRANSACTION = gql`
  ${TRANSACTION_BASE_FRAGMENT}
  mutation UpdateTransaction($transaction: UpdateTransactionInput!) {
    updateTransaction(transaction: $transaction) {
      ...TransactionBaseFragment
    }
  }
`;

export const useUpdateTransaction = () => {
  const [updateTransaction, { called, loading, data, error }] = useMutation<{
    updateTransaction: ITransaction;
  }>(UPDATE_TRANSACTION, {
    refetchQueries: ["GetTransaction"],
  });

  return {
    called,
    updateTransaction,
    loading,
    error,
    transaction: data && data.updateTransaction,
  };
};
