import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { map, reduce, reverse, sortBy } from "lodash";
import React from "react";

type PropertyType<T, TKey extends keyof T = keyof T> = T extends {
  [key in TKey]: infer U;
}
  ? U
  : never;

export interface ISortableListCell<
  TItem,
  TKey extends keyof TItem = keyof TItem,
  TValue extends string | null | number | Date = string | number | null | Date
> {
  label: string;
  align?: "right" | "left";
  key: TKey;
  getValue?: (item: TItem) => TValue;
  format?: (cellValue: TValue) => string | number | null;
  style?: any | ((cellValue: TValue) => any);
}

export interface ISortableListProps<TItem = any> {
  cells: Array<ISortableListCell<TItem>>;
  items: TItem[];
  initialOrderBy: keyof TItem;
  itemIdKey?: keyof TItem;
  initialOrder?: IOrder;
  footerItem?: TItem;
  stickyHeader?: boolean;
  containerStyle?: any;
  children?: React.ReactNode;
}

export type IOrder = "asc" | "desc";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export function SortableList<T = any>(props: ISortableListProps<T>) {
  const classes = useStyles();

  const [orderBy, setOrderBy] = React.useState<keyof T>(props.initialOrderBy);
  const [order, setOrder] = React.useState<IOrder>(
    props.initialOrder || "desc"
  );

  const createSortHandler = (id: keyof T) => () => {
    const isDesc = orderBy === id && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(id);
  };

  const cellMap = reduce(
    props.cells,
    (cm, cell) => {
      cm[cell.key] = cell;
      return cm;
    },
    {} as Record<keyof T, ISortableListCell<T>>
  );

  const getValue = (cellKey: keyof T, item: T) => {
    const cell = cellMap[cellKey];
    if (cell.getValue) {
      return cell.getValue(item as any);
    } else {
      return item[cellKey] as unknown as Date | string | number | null;
    }
  };

  let sortedItems = sortBy(props.items, (item) => getValue(orderBy, item));

  if (order === "desc") {
    reverse(sortedItems);
  }

  return (
    <React.Fragment>
      <TableContainer style={props.containerStyle}>
        <Table size="small" stickyHeader={props.stickyHeader}>
          <TableHead>
            <TableRow>
              {map(props.cells, (cell) => (
                <TableCell
                  key={`${cell.key}`}
                  sortDirection={orderBy === cell.key ? order : false}
                  align={cell.align || "left"}
                >
                  <TableSortLabel
                    active={orderBy === cell.key}
                    direction={order}
                    onClick={createSortHandler(cell.key)}
                  >
                    {cell.label}
                    {orderBy === cell.key ? (
                      <span className={classes.visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {map(sortedItems, (item: T, i) => (
              <TableRow key={`${props.itemIdKey || i}`}>
                {map(props.cells, (cell) => (
                  <TableCell
                    key={`${cell.key}`}
                    style={
                      typeof cell.style === "function"
                        ? cell.style(getValue(cell.key, item))
                        : cell.style
                    }
                    align={cell.align || "left"}
                  >
                    {cell.format
                      ? cell.format(getValue(cell.key, item))
                      : getValue(cell.key, item)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
            {props.children}
          </TableBody>
        </Table>
      </TableContainer>
      {props.footerItem && (
        <Table size="small">
          <TableFooter>
            {map(props.cells, (cell) => (
              <TableCell
                key={`${cell.key}`}
                style={
                  typeof cell.style === "function"
                    ? cell.style(getValue(cell.key, props.footerItem!))
                    : cell.style
                }
                align={cell.align || "left"}
              >
                <b>
                  {cell.format
                    ? cell.format(getValue(cell.key, props.footerItem!))
                    : getValue(cell.key, props.footerItem!)}
                </b>
              </TableCell>
            ))}
          </TableFooter>
        </Table>
      )}
    </React.Fragment>
  );
}
