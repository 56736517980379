import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { print } from "graphql/language/printer";
import { BALANCE_FRAGMENT } from "../fragments/Balance";
import { defragment } from "../lib/graphqlTools";
import { IBalance } from "../types/Balance";

export interface IDateRangeInput {
  from?: Date;
  to?: Date;
}

export interface IGetBalancesInput {
  accountId?: string;
  accountIds?: string[];
  date?: IDateRangeInput;
}

export const GET_BALANCES = gql`
  ${defragment(`
    ${print(BALANCE_FRAGMENT)}
    query GetBalances($input: GetBalancesInput!) {
      balances(input: $input) {
        ...BalanceFragment
      }
  }`)}
`;

export const useBalances = (
  input: IGetBalancesInput,
  { lazy = false } = {}
) => {
  const [getBalances, { loading, data, error }] = useLazyQuery<{
    balances: IBalance[];
  }>(GET_BALANCES, { variables: { input } });
  if (!lazy) {
    getBalances({ variables: { input } });
  }
  return {
    loading,
    error,
    getBalances,
    balances: data && data.balances,
  };
};
