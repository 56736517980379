import { gql } from "apollo-boost";

export const INSTITUTION_FRAGMENT = gql`
  fragment InstitutionFragment on Institution {
    id
    name
    logo
    plaidId
  }
`;
