import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { TRANSACTION_BASE_FRAGMENT } from "../fragments/Transaction";
import { ITransaction, TransactionType } from "../types/Transaction";

export interface IIDInput {
  id: string;
}

export interface ICreateTransactionInput {
  id: string;
  account: IIDInput;
  amount: number;
  description: string;
  type: TransactionType;
  category?: string;
  investment?: string;
  price?: number;
  units?: number;
  date?: Date;
  labels?: string;
  originalDescription?: string;
}

export const CREATE_TRANSACTIONS = gql`
  ${TRANSACTION_BASE_FRAGMENT}
  mutation CreateTransactions($transactions: [CreateTransactionInput!]!) {
    createTransactions(transactions: $transactions) {
      ...TransactionBaseFragment
    }
  }
`;

export const useCreateTransactions = () => {
  const [createTransactions, { called, loading, data, error }] = useMutation<{
    createTransactions: ITransaction[];
  }>(CREATE_TRANSACTIONS, {
    refetchQueries: ["GetTransactions"],
  });

  return {
    called,
    createTransactions,
    loading,
    error,
    transactions: data && data.createTransactions && data.createTransactions,
  };
};
