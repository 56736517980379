import { Moment } from "moment";
import { IAccount } from "./Account";
import { IBalance } from "./Balance";
import { ITransfer } from "./Transfer";

export interface ISymbol {
  symbol: string;
  name: string;
  type: string;
  marketOpen: string;
  marketClose: string;
  timezone: string;
  currency: string;
  matchScore: number;
}

export interface IStockQuote {
  date: string | Date;
  open: number;
  close: number;
  high: number;
  low: number;
  volume: number;
}

export interface IExchangeRate {
  date: string | Date;
  open: number;
  close: number;
  high: number;
  low: number;
}

export interface INumberLabel {
  start: number;
  end: number;
  label: string;
  value: number;
}

export interface ITextLabel {
  start: number;
  end: number;
  label: string;
  value: string;
}

export interface IAssetLabel {
  start: number;
  end: number;
  label: string;
  value: string;
  price?: number;
  isPriceInRange?: boolean;
  symbol?: ISymbol;
  exchangeRate?: IExchangeRate;
  currency?: ITextLabel;
  quote?: IStockQuote;
  properQuote?: IStockQuote;
  quantity?: INumberLabel;
  cost?: INumberLabel;
}

export type ILabel = IAssetLabel | INumberLabel | ITextLabel;

export enum TransactionType {
  credit = "credit",
  debit = "debit",
}

export interface ITransaction {
  incomingTransfers: ITransfer[];
  outgoingTransfers: ITransfer[];
  equivalentAmount: number;
  balances: IBalance[];
  id: string;
  plaidTransactionId?: string;
  account: IAccount;
  category: string;
  amount: number;
  investment: string;
  price: number;
  units: number;
  type: TransactionType;
  date: Date | string | number | Moment;
  labels: {
    description: IAssetLabel[];
  };
  description: string;
  originalDescription: string;
}
