import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import { filter, omit } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { formatCurrency, prettyDate } from "../../helpers";
import { search } from "../../hooks/search";
import { useTransactions } from "../../hooks/transactions";
import { ITransaction, TransactionType } from "../../types/Transaction";
import { ITransfer } from "../../types/Transfer";
import Select from "../Select";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    justifyContent: "space-around",
  },
  item: {
    padding: theme.spacing(2),
    fontSize: 16,
  },
  fixedHeight: {
    height: 480, // Set this based on device size
  },
}));

export interface ITransferFormProps {
  onChange?: (transfer: Partial<ITransfer>) => void;
  onSubmit?: (transfer: ITransfer) => void;
  onDelete?: (transfer: ITransfer) => void;
  showButtons?: boolean;
  initialTransfer?: Partial<ITransfer>;
}

const useSearchTransactions = ({
  date,
  amount,
  description,
  type,
}: Partial<ITransaction>) => {
  const input = useMemo(() => {
    return {
      date: {
        from: moment(date).subtract(10, "day").toDate(),
        to: moment(date).add(10, "day").toDate(),
      },
    };
  }, [date]);
  const { transactions } = useTransactions(input);
  return useMemo(() => {
    let searchedTransactions = transactions;
    if (description) {
      searchedTransactions = search(searchedTransactions, description, {
        threshold: 0.9,
        keys: ["description"],
      });
    }
    if (typeof amount === "number" || type) {
      searchedTransactions = filter(searchedTransactions, (transaction) => {
        if (amount) {
          const diff = Math.abs(transaction.amount - amount);
          if (diff > 50 || diff / amount > 0.1) {
            return false;
          }
        }
        if (type) {
          if (transaction.type !== type) {
            return false;
          }
        }
        return true;
      });
    }

    return searchedTransactions;
  }, [description, amount, transactions]);
};

const TransactionSearchSelect = ({
  transaction,
  onChange,
  label,
  id,
}: {
  transaction: Partial<ITransaction>;
  onChange: any;
  label?: string;
  id?: string;
}) => {
  const { date, amount, type } = transaction;

  const transactions = useSearchTransactions({
    date,
    amount,
    type,
  });

  const value = useMemo(() => {
    if (transaction && transaction.id) {
      return transaction;
    } else {
      onChange(transactions[0]);
      return transactions[0];
    }
  }, [transactions, transaction && transaction.id]);
  return (
    <Select
      id={id}
      label={label}
      value={transaction && transaction.id ? transaction : transactions[0]}
      getDisplayName={(transaction) => {
        return `${prettyDate(transaction.date!)} ${formatCurrency(
          transaction.amount
        )} ${transaction.description}`;
      }}
      selections={transactions}
      onChange={onChange}
    />
  );
};

export const TransferForm = ({
  onChange,
  onSubmit,
  onDelete,
  initialTransfer,
  showButtons,
}: ITransferFormProps) => {
  const classes = useStyles();

  const [transfer, setTransfer] = useState<Partial<ITransfer>>({
    ...initialTransfer,
  });

  const handleTransactionChange = useCallback(
    (property: keyof ITransfer) => (transaction: ITransaction) => {
      setTransfer({
        ...transfer,
        [property]: transaction,
      });
    },
    [transfer]
  );

  useEffect(() => {
    onChange && onChange(transfer);
  }, [transfer]);

  const handleSave = useCallback(
    (evt: any) => {
      onSubmit && onSubmit(transfer as ITransfer);
    },
    [transfer]
  );

  const handleDelete = useCallback(
    (evt: any) => {
      onDelete && onDelete(transfer as ITransfer);
    },
    [transfer]
  );

  const incomingOrOutgoing =
    transfer.incomingTransaction ||
    (transfer.outgoingTransaction && omit(transfer.outgoingTransaction, "id"));
  const outgoingOrIncoming =
    transfer.outgoingTransaction ||
    (transfer.incomingTransaction && omit(transfer.incomingTransaction, "id"));

  return (
    <form className={classes.container}>
      <FormControl className={classes.item}>
        <FormControl>
          <InputLabel htmlFor="standard-date">Date</InputLabel>
          <Input
            id="standard-date"
            value={prettyDate(
              (transfer.incomingTransaction || transfer.outgoingTransaction)
                ?.date || new Date()
            )}
            onFocus={(event) => event.target.select()}
            disabled
          />
        </FormControl>
      </FormControl>
      <FormControl className={classes.item} fullWidth>
        {incomingOrOutgoing ? (
          <TransactionSearchSelect
            key={incomingOrOutgoing.amount}
            transaction={{
              ...incomingOrOutgoing,
              type: TransactionType.debit,
            }}
            label="From"
            onChange={handleTransactionChange("incomingTransaction")}
          />
        ) : null}
      </FormControl>
      <FormControl className={classes.item} fullWidth>
        {outgoingOrIncoming ? (
          <TransactionSearchSelect
            key={outgoingOrIncoming.amount}
            transaction={{
              ...outgoingOrIncoming,
              type: TransactionType.credit,
            }}
            label="To"
            onChange={handleTransactionChange("outgoingTransaction")}
          />
        ) : null}
      </FormControl>
      {showButtons ? (
        <Grid className={classes.item} item xs={6} sm={4}>
          <Button variant="contained" color="secondary" onClick={handleDelete}>
            Delete
          </Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </Grid>
      ) : null}
    </form>
  );
};
