import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { setContext } from "apollo-link-context";
import { createHttpLink } from "apollo-link-http";
import { withScalars } from "apollo-link-scalars";
import Decimal from "decimal.js";
import { buildClientSchema } from "graphql";
import { uncrunch } from "graphql-crunch";
import { getToken } from "./lib/auth";
import introspectionResult from "./__generated__/graphql.schema.json";

const schema = buildClientSchema(introspectionResult as any);

const BACKEND_URL = process.env.REACT_APP_FINDB_URL;

/**
 * Old code for connecting to django
let _csrfToken: any = null;

async function getCsrfToken() {
  if (_csrfToken === null) {
    const response = await fetch(`${BACKEND_URL}/csrf/`, {
      credentials: 'include',
    });
    const data = await response.json();
    _csrfToken = data.csrfToken;
  }
  return _csrfToken;
}
*/

const httpLink = createHttpLink({
  uri: BACKEND_URL,
  // uri: `${BACKEND_URL}/?crunch=2`,
});

const authLink = setContext(async (_, { headers }) => {
  const token = getToken();
  return {
    headers: {
      ...headers,
      // 'X-CSRFToken': await getCsrfToken(),
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const uncrunchLink = new ApolloLink((operation, forward) =>
  forward(operation).map((response) => {
    if (response && response.data && response.data.crunched) {
      response.data = uncrunch(response.data);
    }
    return response;
  })
);

const typesMap = {
  Decimal: {
    serialize: (parsed: Decimal | number) => parsed.toString(),
    parseValue: (raw: string): Decimal | null => {
      if (!raw) return null; // if for some reason we want to treat empty string as null, for example
      return new Decimal(raw).toNumber() as any;
    },
  },
};

const client = new ApolloClient({
  link: authLink
    .concat(uncrunchLink)
    .concat(withScalars({ schema, typesMap }) as unknown as ApolloLink)
    .concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
