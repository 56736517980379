import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { TRANSACTION_LABELS_FRAGMENT } from "../fragments/Transaction";
import { ITransaction } from "../types/Transaction";
import { ILabelTransactionInput } from "./LabelTransaction";

export interface ILabelTransactionsInput {
  transactions: ILabelTransactionInput[];
}

export const LABEL_TRANSACTIONS = gql`
  ${TRANSACTION_LABELS_FRAGMENT}
  mutation LabelTransactions($input: LabelTransactionsInput!) {
    labelTransactions(input: $input) {
      id
      ...TransactionLabelsFragment
    }
  }
`;

export const useLabelTransactions = () => {
  const [labelTransactions, { called, loading, data, error }] = useMutation<{
    labelTransactions: Array<Pick<ITransaction, "labels">>;
  }>(LABEL_TRANSACTIONS, {
    refetchQueries: ["LabelTransactions"],
  });

  return {
    called,
    labelTransactions,
    loading,
    error,
    transactions: data && data.labelTransactions,
  };
};
