import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { ACCOUNT_FRAGMENT } from "../fragments/Account";
import { GET_ACCOUNTS, IGetAccountsResponse } from "../queries/GetAccounts";
import { AccountType, IAccount } from "../types/Account";
import { IBalance } from "../types/Balance";
import { IIDInput } from "./CreateTransactions";

export interface ICreateAccountInput {
  name: string;
  number: string;
  type: AccountType;
  institution: IIDInput;
  balances: IBalance[];
}

export const CREATE_ACCOUNT = gql`
  ${ACCOUNT_FRAGMENT}
  mutation CreateAccount($account: CreateAccountInput!) {
    createAccount(account: $account) {
      ...AccountFragment
    }
  }
`;

export const useCreateAccount = () => {
  const [createAccount, { called, loading, data, error }] = useMutation<{
    createAccount: IAccount;
  }>(CREATE_ACCOUNT, {
    update: (cache, { data }) => {
      const newAccount = data && data.createAccount;
      if (newAccount) {
        const cachedData = cache.readQuery<IGetAccountsResponse>({
          query: GET_ACCOUNTS,
        });
        const accounts = (cachedData && cachedData.accounts) || [];
        cache.writeQuery({
          query: GET_ACCOUNTS,
          data: {
            accounts: accounts.concat(newAccount),
          },
        });
      }
    },
  });
  return {
    called,
    createAccount,
    loading,
    error,
    account: data && data.createAccount && data.createAccount,
  };
};
