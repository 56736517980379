import { parseDate } from "chrono-node";
import { isDate, isNaN, isNumber, isString, keys, map, reduce } from "lodash";
import * as math from "mathjs";
import { ICreateTransactionInput } from "./mutations/CreateTransactions";

export interface Dictionary<T = any> {
  [key: string]: T;
}

export type IRawTransactionValue = string | number | Date;

export interface IRawTransaction {
  [rawField: number]: IRawTransactionValue;
  [rawField: string]: IRawTransactionValue;
}

export type IRawTransactionField = keyof IRawTransaction;
export type ITransactionField = keyof ICreateTransactionInput;

export interface ITransactionFieldMap<
  TKey extends ITransactionField = ITransactionField
> {
  field: TKey;
  equation: string;
  fix?: (value: IRawTransaction, error?: Error) => void;
  defaultValue?: ICreateTransactionInput[TKey];
  sources?: IRawTransactionField[];
  format?: (value: IRawTransactionValue) => ICreateTransactionInput[TKey];
  merge?: (
    formattedValues: Dictionary<ICreateTransactionInput[TKey]>
  ) => ICreateTransactionInput[TKey];
}

const defaultMerge = <TKey extends keyof ICreateTransactionInput = any>(
  formattedValues: Dictionary<ICreateTransactionInput[TKey]>
) => {
  const rawFields = keys(formattedValues);
  return reduce(
    rawFields.slice(1),
    (res, rawField) =>
      ((res as number) +
        (formattedValues[rawField] as number)) as ICreateTransactionInput[TKey],
    formattedValues[rawFields[0]]
  );
};

const defaultFormatter = <TKey extends keyof ICreateTransactionInput>(
  rawValue: IRawTransactionValue
) => rawValue as ICreateTransactionInput[TKey];

export const commonFormatters = {
  currency: (rawAmount: IRawTransactionValue) =>
    parseFloat(`${rawAmount}`.replace("$", "").replace(",", "")),
  float: (rawFloat: IRawTransactionValue) => parseFloat(`${rawFloat}`),
  integer: (rawInteger: IRawTransactionValue) => parseFloat(`${rawInteger}`),
  date: (rawDate: IRawTransactionValue) =>
    rawDate instanceof Date ? rawDate : parseDate(rawDate),
};

export const defaultFormatters: {
  [field in keyof ICreateTransactionInput]?: (
    val: IRawTransactionValue
  ) => ICreateTransactionInput[field];
} = {
  date: commonFormatters.date,
  amount: commonFormatters.currency,
  price: commonFormatters.currency,
  units: commonFormatters.float,
};

const getTransactionKeys = (node: math.MathNode) => {
  let transactionKeys: string[] = [];
  switch (node.type) {
    case "ParenthesisNode":
      transactionKeys = [
        ...transactionKeys,
        ...getTransactionKeys((node as any).content),
      ];
      break;
    case "ConditionalNode":
      transactionKeys = [
        ...transactionKeys,
        ...getTransactionKeys((node as any).condition),
        ...getTransactionKeys((node as any).trueExpr),
        ...getTransactionKeys((node as any).falseExpr),
      ];
      break;
    case "FunctionNode":
      map(node.args, (node) => {
        transactionKeys = [...transactionKeys, ...getTransactionKeys(node)];
        return node;
      });
      break;
    case "OperatorNode":
      node.map((node: math.MathNode) => {
        transactionKeys = [...transactionKeys, ...getTransactionKeys(node)];
        return node;
      });
      break;
    case "ConstantNode":
      if (node.value) {
        transactionKeys.push(node.value);
      }
      break;
    default:
      if (node.name) {
        transactionKeys.push(node.name);
      }
      break;
  }
  return transactionKeys;
};

export const mapTransactionField = <
  TKey extends keyof ICreateTransactionInput = any
>(
  rawTransaction: IRawTransaction,
  fieldMap: ITransactionFieldMap<TKey>
) => {
  const merge = fieldMap.merge || defaultMerge;
  const format =
    fieldMap.format || defaultFormatters[fieldMap.field] || defaultFormatter;

  let transactionKeys = fieldMap.sources || [];
  if (transactionKeys.length === 0) {
    const expression = math.parse(fieldMap.equation);

    transactionKeys = getTransactionKeys(expression);
  }

  const formattedValues = reduce(
    transactionKeys,
    (formattedValues, rawField) => {
      const rawValue = rawTransaction[rawField as any];
      if (rawValue !== undefined && rawValue !== null) {
        formattedValues[rawField] = format!(
          rawValue as any
        ) as ICreateTransactionInput[TKey];
        if (isNaN(formattedValues[rawField])) {
          formattedValues[rawField] = rawValue as any;
        }
      } else if (fieldMap.defaultValue !== undefined) {
        formattedValues[rawField] = format!(
          fieldMap.defaultValue as any
        ) as ICreateTransactionInput[TKey];
      }
      return formattedValues;
    },
    {} as Dictionary<ICreateTransactionInput[TKey]>
  );

  if (fieldMap.equation) {
    const func = math.compile(fieldMap.equation);
    const result = func.evaluate(formattedValues);
    if (isNumber(result) || isString(result) || isDate(result)) {
      if (!isDate(result) && formattedValues[result] !== undefined) {
        return formattedValues[result];
      } else {
        return result as ICreateTransactionInput[TKey];
      }
    } else {
      throw new Error("Issue parsing equation");
    }
  }

  return merge(formattedValues) as ICreateTransactionInput[TKey];
};

export const mapRawTransaction = (
  transactionFieldMaps: ITransactionFieldMap[],
  rawTransaction: IRawTransaction
): ICreateTransactionInput => {
  return reduce(
    transactionFieldMaps,
    (transaction, fieldMap) => {
      transaction[fieldMap.field] = mapTransactionField(
        rawTransaction,
        fieldMap
      ) as any;
      return transaction;
    },
    {} as Partial<ICreateTransactionInput>
  ) as ICreateTransactionInput;
};

export const mapRawTransactions = (
  transactionFieldMaps: ITransactionFieldMap[],
  rawTransactions: IRawTransaction[]
) =>
  map(rawTransactions, (rawTransaction) =>
    mapRawTransaction(transactionFieldMaps, rawTransaction)
  );
