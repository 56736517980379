import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AddIcon from "@material-ui/icons/Add";
import SyncIcon from "@material-ui/icons/Sync";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import { groupBy, reverse, sortBy } from "lodash";
import React, { useMemo } from "react";
import { getTotalBalance } from "../helpers";
import { useLinkInstitution, useSyncInstitutions } from "../hooks/institution";
import { useGenerateTransfers } from "../mutations/GenerateTransfers";
import { IAccount } from "../types/Account";
import { Balance } from "./Balance";
import { DropdownMenu } from "./DropdownMenu";

export interface IAccountsBalancesProps {
  accounts: IAccount[];
  showInactiveAccounts: boolean;
  toggleInactiveAccounts: () => void;
}

const useStyles = makeStyles((theme) => ({
  left: {
    textAlign: "start",
  },
}));

export const AccountsBalances = ({
  accounts,
  showInactiveAccounts,
  toggleInactiveAccounts,
}: IAccountsBalancesProps) => {
  const classes = useStyles();
  const linkInstitution = useLinkInstitution({});
  const { syncInstitutions } = useSyncInstitutions();
  const { generateTransfers } = useGenerateTransfers();
  const { assets, liabilities, total } = useMemo(() => {
    const { true: assets, false: liabilities } = groupBy(
      reverse(sortBy(accounts, "balance.amount")),
      "isAsset"
    );
    return {
      total: getTotalBalance(accounts),
      assets: getTotalBalance(assets),
      liabilities: getTotalBalance(liabilities),
    };
  }, [accounts]);
  return (
    <Card>
      <Box p={1}>
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography variant="h6" color="primary" className={classes.left}>
              Balances
            </Typography>
          </Box>
          <Box flexShrink={1}>
            <DropdownMenu
              items={[
		{
	          onClick: () => linkInstitution.open(),
                  icon: <AddIcon />,
                  title: "Link More Accounts",
                },

                {
                  onClick: syncInstitutions,
                  icon: <SyncIcon />,
                  title: "Sync Accounts",
                },
                {
                  onClick: generateTransfers,
                  icon: <SyncAltIcon />,
                  title: "Find Transfers",
                },
                {
                  title: `${
                    showInactiveAccounts ? "Hide" : "Show"
                  } Inactive Accounts`,
                  icon: <AccountBalanceIcon color="disabled" />,
                  onClick: toggleInactiveAccounts,
                },
              ]}
            />
          </Box>
        </Box>
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography
              variant="subtitle1"
              color="primary"
              className={classes.left}
            >
              Total
            </Typography>
          </Box>
          <Box flexShrink={1}>
            <Balance balance={total} slim />
          </Box>
        </Box>
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography
              variant="subtitle1"
              color="primary"
              className={classes.left}
            >
              Assets
            </Typography>
          </Box>
          <Box flexShrink={1}>
            <Balance balance={assets} slim />
          </Box>
        </Box>
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography
              variant="subtitle1"
              color="primary"
              className={classes.left}
            >
              Liabilities
            </Typography>
          </Box>
          <Box flexShrink={1}>
            <Balance balance={liabilities} slim />
          </Box>
        </Box>
      </Box>
    </Card>
  );
};
