import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { find, map } from "lodash";
import React, { useMemo } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      //     margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);

export interface IGenericSelectProps<T = string> {
  label?: string;
  placeholder?: string;
  value?: T;
  id?: string;
  variant?: "standard" | "outlined" | "filled";
  displayEmpty?: boolean;
  selections: T[];
  noneTitle?: string;
  displayField?: keyof T;
  getDisplayName?: (value: T) => string;
  onChange: (value: T) => void;
  style?: object;
}

export default function GenericSelect<T = any>(props: IGenericSelectProps<T>) {
  const classes = useStyles();
  const getValue = (displayName: string): T =>
    props.displayField || props.getDisplayName
      ? (find(
          props.selections,
          (selection) => getDisplayName(selection) === displayName
        ) as T)
      : (displayName as unknown as T);
  const getDisplayName = (selection: T | undefined): string => {
    if (!selection) {
      return "";
    }
    return props.displayField
      ? (selection[props.displayField] as unknown as string)
      : props.getDisplayName
      ? props.getDisplayName(selection)
      : (selection as unknown as string);
  };
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    props.onChange(getValue(event.target.value as string));
  };
  const inputId = useMemo(
    () => props.id || `select-${Math.round(Math.random() * 1e6)}`,
    []
  );
  const name = getDisplayName(props.value);

  return (
    <FormControl style={props.style} className={classes.formControl}>
      {props.label && (
        <InputLabel id={`${inputId}-label`}>{props.label}</InputLabel>
      )}
      <Select
        displayEmpty={props.displayEmpty}
        variant={props.variant}
        id={inputId}
        labelId={`${inputId}-label`}
        value={name}
        onChange={handleChange}
      >
        <MenuItem value="">
          <em>{props.noneTitle || "None"}</em>
        </MenuItem>
        {map(props.selections, (selection) => {
          const displayName = getDisplayName(selection);
          return (
            <MenuItem
              disabled={selection && (selection as any).disabled}
              key={displayName}
              value={displayName}
            >
              {displayName}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
