import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";

export enum ProgressMode {
  circular = "circular",
}

export interface IProgressProps {
  mode?: ProgressMode;
}

export const Progress = ({ mode }: IProgressProps) => (
  <CircularProgress
    size={24}
    style={{
      color: "green",
      position: "relative",
      marginTop: -12,
      marginLeft: -12,
      top: "50%",
      left: "50%",
    }}
  />
);
