import { useApolloClient } from "@apollo/react-hooks";
import { useToken } from "./authentication";

export const useLogout = () => {
  const [token, setToken] = useToken();
  const client = useApolloClient();
  const logout = async () => {
    setToken();
    client.resetStore();
  };

  return logout;
};
