import { flatten, get, map, mapKeys, uniqBy } from "lodash";
import { useEffect, useMemo } from "react";
import client from "../client";
import {
  GET_TRANSACTIONS,
  IGetTransactionsInput as IGetTransactionsInputRaw,
  useTransactions as useTransactionsQuery,
} from "../queries/GetTransactions";
import { useEquivalentBalances } from "./balances";
import { useEquivalentAmount } from "./equivalentAmounts";

export type IGetTransactionsInput = IGetTransactionsInputRaw;

export const useTransactions = (
  input: IGetTransactionsInput,
  { lazy = false, currency = "CAD" } = {}
) => {
  const { getTransactions, transactions, ...results } = useTransactionsQuery(
    input,
    { lazy }
  );
  const {
    items: equivalentTransactions,
    loading,
    error,
  } = useEquivalentAmount(transactions, {
    currency,
    getCurrency: (transaction) => get(transaction, ["account", "currency"]),
    getDate: (transaction) => transaction.date,
    getResult: (transaction, exchangeRate) => {
      return {
        ...transaction,
        equivalentPrice:
          transaction.price && transaction.price * exchangeRate.close,
        equivalentAmount:
          transaction.amount && transaction.amount * exchangeRate.close,
      };
    },
  });

  const balances = useMemo(
    () => uniqBy(flatten(map(transactions, "balances")), "id"),
    [equivalentTransactions]
  );
  const { equivalentBalances, ...balanceResults } = useEquivalentBalances(
    balances,
    currency
  );

  const finalTransactions = useMemo(() => {
    const balancesById = mapKeys(equivalentBalances, "id");
    return map(equivalentTransactions, (transaction) => {
      return {
        ...transaction,
        balances: map(
          transaction.balances,
          (balance) => balancesById[balance.id] || balance
        ),
      };
    });
  }, [equivalentTransactions, equivalentBalances]);

  useEffect(() => {
    client.writeQuery({
      query: GET_TRANSACTIONS,
      data: {
        transactions: equivalentTransactions,
      },
    });
  }, [finalTransactions]);

  return {
    transactions: finalTransactions,
    getTransactions,
    loadingTransactions: results.loading,
    loading: results.loading || loading,
    error: results.error || error,
  };
};
