import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import { map } from "lodash";
import React, { useMemo } from "react";
import { useToggle } from "react-use";
import { formatCurrency, prettyDate } from "../helpers";
import { useDeleteTransfer } from "../mutations/DeleteTransfer";
import { useGenerateTransfers } from "../mutations/GenerateTransfers";
import { useUpsertTransfer } from "../mutations/UpsertTransfer";
import { ITransfer } from "../types/Transfer";
import TransferDialog from "./dialogs/TransferDialog";
import { DropdownMenu } from "./DropdownMenu";

export interface ITransfersProps {
  transfers: ITransfer[];
}

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  balanceContainer: {
    //  paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  title: {
    textAlign: "start",
    color: theme.palette.primary.main,
  },
  text: {
    textAlign: "start",
  },
  center: {
    alignItems: "center",
  },
  rightText: {
    textAlign: "end",
  },
  balance: {
    textAlign: "right",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 480,
  },
  menuIconContainer: {
    marginLeft: 6,
    display: "flex",
    alignContent: "center",
  },
}));

export interface ITransferProps {
  transfer: ITransfer;
}

const TransferGrid = ({ date, amount, description }: any) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.balanceContainer}>
      <Grid item xs={2} className={classes.text}>
        {date}
      </Grid>
      <Grid item xs={2} className={classes.text}>
        {amount}
      </Grid>
      <Grid item xs={8} className={classes.text}>
        {description}
      </Grid>
    </Grid>
  );
};

const Transfer = ({ transfer }: ITransferProps) => {
  const { date, amount } = transfer.incomingTransaction;
  const { upsertTransfer } = useUpsertTransfer();
  const { deleteTransfer } = useDeleteTransfer();
  const classes = useStyles();
  const [isEditing, toggleIsEditing] = useToggle(false);
  const handleDelete = (transfer: Partial<ITransfer>) => {
    toggleIsEditing();
    if (transfer.id) {
      deleteTransfer({
        variables: {
          id: transfer.id,
        },
      });
    }
  };
  const handleSubmit = (transfer: Partial<ITransfer>) => {
    toggleIsEditing();
    upsertTransfer(transfer as ITransfer);
  };
  return (
    <Grid container>
      <TransferDialog
        initialTransfer={transfer}
        isOpen={isEditing}
        onClose={toggleIsEditing}
        onSubmit={handleSubmit}
        onDelete={handleDelete}
      />
      <Grid container onClick={toggleIsEditing}>
        <TransferGrid
          date={<Typography component="p">{prettyDate(date)}</Typography>}
          amount={
            <Typography component="p">{formatCurrency(amount)}</Typography>
          }
          description={
            <Tooltip
              title={
                <Box display="flex" alignItems="center">
                  {transfer.incomingTransaction.account.name}
                  <ArrowRightAltIcon />
                  {transfer.outgoingTransaction.account.name}
                </Box>
              }
            >
              <Typography component="p">
                {transfer.incomingTransaction.description}
              </Typography>
            </Tooltip>
          }
        />
      </Grid>
    </Grid>
  );
};

export const Transfers = ({ transfers }: ITransfersProps) => {
  const { generateTransfers } = useGenerateTransfers();
  const classes = useStyles();
  const menuItems = useMemo(
    () => [
      {
        onClick: generateTransfers,
        icon: <SyncAltIcon />,
        title: "Find Transfers",
      },
    ],
    []
  );

  return (
    <Card>
      <Box p={1} display="flex">
        <Box flexGrow={1} display="flex" className={classes.center}>
          <Typography
            variant="subtitle2"
            color="primary"
            className={classes.title}
          >
            Transfers
          </Typography>
        </Box>
        <Box flexShrink={1}>
          {menuItems.length ? <DropdownMenu items={menuItems} /> : null}
        </Box>
      </Box>
      <Box p={1} style={{ padding: 8 }}>
        <TransferGrid
          date={<Typography variant="subtitle1">Date</Typography>}
          amount={<Typography variant="subtitle1">Amount</Typography>}
          description={<Typography variant="subtitle1">Description</Typography>}
        />
        <Grid container spacing={1} direction="column">
          {map(transfers, (transfer) => (
            <Transfer transfer={transfer} key={transfer.id} />
          ))}
        </Grid>
      </Box>
    </Card>
  );
};
