import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Snackbar from "@material-ui/core/Snackbar";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";
import {
  ICreateBalanceInput,
  useCreateBalance,
} from "../../mutations/CreateBalance";
import { IAccount } from "../../types/Account";
import { IBalance } from "../../types/Balance";
import { AddBalanceForm } from "../forms/BalanceForm";
import { SelectAccountForm } from "../forms/SelectAccount";

export interface IAddBalanceDialogProps {
  isOpen: boolean;
  userId: string;
  account?: IAccount;
  accounts: IAccount[];
  onSubmit: (balance: IBalance) => void;
  onClose: () => void;
}

export default function AddBalanceDialog({
  onSubmit,
  userId,
  account,
  accounts,
  isOpen,
  onClose,
}: IAddBalanceDialogProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedAccount, setSelectedAccount] = React.useState(account);
  const [balanceInput, setBalanceInput] = React.useState<
    Omit<ICreateBalanceInput, "account">
  >({
    balance: 0,
    date: new Date(),
  });
  const { balance, createBalance, error, loading, called } = useCreateBalance();

  const handleSubmit = () => {
    createBalance({
      variables: {
        balance: {
          ...balanceInput,
          account: {
            id: selectedAccount && selectedAccount.id,
          },
        },
      },
    }).catch(console.error);
  };

  const onEnter = () => {
    setSelectedAccount(account);
  };

  if (called && !loading && !error) {
    onSubmit(balance!);
  }

  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={!!error}
        autoHideDuration={2500}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{error && error.message}</span>}
      />
      <Dialog
        fullScreen={fullScreen}
        disableBackdropClick
        disableEscapeKeyDown
        open={isOpen}
        onClose={onClose}
        onEnter={onEnter}
      >
        <DialogTitle>Add Balance</DialogTitle>
        <DialogContent>
          <SelectAccountForm
            fullWidth
            selectedAccount={selectedAccount}
            accounts={accounts}
            onChange={setSelectedAccount}
          />
          <AddBalanceForm
            initialBalance={balanceInput}
            onChange={setBalanceInput}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
