import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import Select from "../Select";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);

export interface IDialogSelectProps<T = any> {
  isOpen: boolean;
  title: string;
  label: string;
  value?: T | undefined;
  selections: T[];
  noneTitle?: string;
  displayField?: keyof T;
  onSubmit: (value: T) => void;
  onClose: () => void;
}

export default function DialogSelect(props: IDialogSelectProps) {
  const classes = useStyles();
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={props.isOpen}
      onClose={props.onClose}
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <form className={classes.container}>
          <FormControl className={classes.formControl} fullWidth>
            <Select
              label={props.label}
              value={props.value}
              selections={props.selections}
              noneTitle={props.noneTitle}
              displayField={props.displayField}
              onChange={props.onSubmit}
            />
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
