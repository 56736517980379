import LinearProgress from "@material-ui/core/LinearProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Alert, { AlertProps } from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";

export interface IToastProps {
  message?: string;
  severity: AlertProps["severity"];
  autoHideDuration?: number | null;
  progress?: boolean;
}

const Toast = ({
  message,
  progress,
  severity = "info",
  autoHideDuration = 2500,
}: IToastProps) => {
  const [isOpen, setIsOpen] = useState(!!message);
  useEffect(() => {
    setIsOpen(!!message);
  }, [message]);
  const handleClose = () => setIsOpen(false);
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={isOpen}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
    >
      <div>
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={severity}
        >
          {message}
        </Alert>
        {progress ? (
          <LinearProgress
            style={{
              borderBottomLeftRadius: 4,
              borderBottomRightRadius: 4,
              position: "relative",
              bottom: 4,
            }}
          />
        ) : null}
      </div>
    </Snackbar>
  );
};

export default Toast;
