import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AssignmentIcon from "@material-ui/icons/Assignment";
import BarChartIcon from "@material-ui/icons/BarChart";
import CallSplitIcon from "@material-ui/icons/CallSplit";
import DashboardIcon from "@material-ui/icons/Dashboard";
import TimelineIcon from "@material-ui/icons/Timeline";
import React from "react";
import { Link, useLocation } from "react-router-dom";

export enum ViewType {
  CALCULATOR = "Calculators",
  INVESTMENT = "Investment",
  ACCOUNTS = "Accounts",
  TRANSACTION = "Transactions",
  FORECAST = "Forecast",
  REPORTS = "Reports",
}

export interface IMainListItemsProps {
  onClick: (view: ViewType) => void;
}

export const MainListItems = ({ onClick }: IMainListItemsProps) => {
  const location = useLocation();
  return (
    <React.Fragment>
      <Link
        to={{
          ...location,
          pathname: "/accounts",
        }}
      >
        <ListItem button onClick={() => onClick(ViewType.ACCOUNTS)}>
          <ListItemIcon>
            <AccountBalanceIcon />
          </ListItemIcon>
          <ListItemText primary={ViewType.ACCOUNTS} />
        </ListItem>
      </Link>
      <Link
        to={{
          ...location,
          pathname: "/transactions",
        }}
      >
        <ListItem button onClick={() => onClick(ViewType.TRANSACTION)}>
          <ListItemIcon>
            <BarChartIcon />
          </ListItemIcon>
          <ListItemText primary={ViewType.TRANSACTION} />
        </ListItem>
      </Link>
      <Link
        to={{
          ...location,
          pathname: "/investments",
        }}
      >
        <ListItem button onClick={() => onClick(ViewType.INVESTMENT)}>
          <ListItemIcon>
            <TimelineIcon />
          </ListItemIcon>
          <ListItemText primary={ViewType.INVESTMENT} />
        </ListItem>
      </Link>

      <Link
        to={{
          ...location,
          pathname: "/calculators",
        }}
      >
        <ListItem button onClick={() => onClick(ViewType.CALCULATOR)}>
          <ListItemIcon>
            <CallSplitIcon />
          </ListItemIcon>
          <ListItemText primary={ViewType.CALCULATOR} />
        </ListItem>
      </Link>
      {/*
      <ListItem button onClick={() => onClick(ViewType.FORECAST)}>
        <ListItemIcon>
          <TimelineIcon/>
        </ListItemIcon>
        <ListItemText primary={ViewType.FORECAST} />
      </ListItem>
      */}
      <Link
        to={{
          ...location,
          pathname: "/reports",
        }}
      >
        <ListItem button onClick={() => onClick(ViewType.REPORTS)}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary={ViewType.REPORTS} />
        </ListItem>
      </Link>
    </React.Fragment>
  );
};

export const secondaryListItems = (
  <div>
    <ListSubheader inset>Saved reports</ListSubheader>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItem>
  </div>
);
