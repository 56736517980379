import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { groupBy, map, mapValues, reduce, sumBy } from "lodash";
import moment from "moment";
import React from "react";
import { formatCurrency } from "../helpers";
import { ITransaction, TransactionType } from "../types/Transaction";
import { IOrder, ISortableListCell, SortableList } from "./SortableList";
import Title from "./Title";

export interface IMonthlyVolume {
  date: string;
  value: number;
}

export interface IMonthlySummary {
  volumes: IMonthlyVolume[];
  lastMonth: number;
  threeMonthAvg: number;
  sixMonthAvg: number;
  twelveMonthAvg: number;
}

type IOrderBy = Exclude<keyof IMonthlySummary, "volumes"> | "category";

export interface IMonthlyAveragesProps {
  title: string;
  loading?: boolean;
  transactions: ITransaction[];
  initialOrder?: IOrder;
  initialOrderBy?: IOrderBy;
}

const monthlyTransactionVolumes = (transactions: ITransaction[]) => {
  return reduce(
    groupBy(
      transactions,
      (t) =>
        `${new Date(moment(t.date).toDate()).getFullYear()}-${new Date(
          moment(t.date).toDate()
        ).getMonth()}`
    ),
    (volumes, trns, monthIdx) => {
      volumes[monthIdx] = sumBy(trns, (t) =>
        t.type === TransactionType.credit ? t.amount : -t.amount
      );
      return volumes;
    },
    {} as { [key: string]: number }
  );
};

const createMonthlySummary = (transactions: ITransaction[]) => {
  const monthlyVolumes = monthlyTransactionVolumes(transactions);
  const date = new Date();
  const currentMonth = date.getMonth();
  const currentYear = date.getFullYear();
  const fullMonthlyVolumes: IMonthlyVolume[] = [];
  for (let i = 1; i <= 12; i++) {
    const month = (currentMonth - i) % 12;
    const year = month < 0 ? currentYear - 1 : currentYear;
    const dateString = `${year}-${Math.abs(month)}`;
    fullMonthlyVolumes.push({
      value: monthlyVolumes[dateString] || 0,
      date: dateString,
    });
  }

  const getAvg = (values: IMonthlyVolume[]) =>
    Math.round((sumBy(values, "value") / values.length) * 100) / 100;

  return {
    volumes: fullMonthlyVolumes,
    lastMonth: fullMonthlyVolumes[0].value,
    threeMonthAvg: getAvg(fullMonthlyVolumes.slice(0, 3)),
    sixMonthAvg: getAvg(fullMonthlyVolumes.slice(0, 6)),
    twelveMonthAvg: getAvg(fullMonthlyVolumes),
  };
};

const categorizeAndCreateSummary = (transactions: ITransaction[]) =>
  mapValues(groupBy(transactions, "category"), createMonthlySummary);

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  container: {
    maxHeight: 500,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const getAmountStyle = (val: number) =>
  val >= 0 ? { color: "green" } : { color: "red" };

export const MonthlyAverages = (props: IMonthlyAveragesProps) => {
  const classes = useStyles();

  const cells: ISortableListCell<
    IMonthlySummary & { category: string },
    keyof IMonthlySummary | "category",
    any
  >[] = [
    {
      key: "category",
      label: "Category",
    },
    {
      key: "twelveMonthAvg",
      format: formatCurrency,
      style: getAmountStyle,
      align: "right",
      label: "12 Mth Avg",
    },
    {
      key: "sixMonthAvg",
      format: formatCurrency,
      style: getAmountStyle,
      align: "right",
      label: "6 Mth Avg",
    },
    {
      key: "threeMonthAvg",
      format: formatCurrency,
      style: getAmountStyle,
      align: "right",
      label: "3 Mth Avg",
    },
    {
      key: "lastMonth",
      format: formatCurrency,
      style: getAmountStyle,
      align: "right",
      label: "Last Month",
    },
  ];

  const categorizedSummary = categorizeAndCreateSummary(props.transactions);
  const summary = {
    ...createMonthlySummary(props.transactions),
    category: "All",
  };

  let flattenedCategories = map(categorizedSummary, (summary, category) => ({
    ...summary,
    category: category === "null" ? "" : category,
  }));

  return (
    <Paper className={classes.paper}>
      <Title>
        {props.title}
        {props.loading && (
          <CircularProgress
            size={24}
            style={{ color: "green", marginLeft: 15 }}
          />
        )}
      </Title>
      <SortableList
        items={flattenedCategories}
        footerItem={summary}
        stickyHeader
        initialOrder={props.initialOrder}
        initialOrderBy={props.initialOrderBy || "threeMonthAvg"}
        cells={cells}
        containerStyle={{ maxHeight: 500 }}
      />
    </Paper>
  );
};
