import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import chroma from "chroma-js";
import clsx from "clsx";
import { filter, find, isEqual, map, startCase } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { useDeleteCashflow } from "../../mutations/DeleteCashflow";
import { useDeletePortfolio } from "../../mutations/DeletePortfolio";
import { useUpsertCashflow } from "../../mutations/UpsertCashflow";
import { useUpsertPortfolio } from "../../mutations/UpsertPortfolio";
import { useCashflows } from "../../queries/GetCashflows";
import { usePortfolios } from "../../queries/GetPortfolios";
import {
  IAnnualCashflow,
  ICashflow,
  IMortgageCashflow,
} from "../../types/Cashflow";
import { IPortfolio } from "../../types/Portfolio";
import {
  BasicAnnualCashflowCalculator,
  BasicCashflowCalculator,
  MonteCarloCashflowCalculator,
  MortgageCashflowCalculator,
  PortfolioBuilder,
} from "../calculators";
import ErrorCatcher from "../ErrorCatcher";

const useStyles = makeStyles((theme) => ({
  container: {
    fontSize: theme.typography.fontSize,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    justifyContent: "space-around",
  },
  menuContainer: {
    justifyContent: "space-around",
  },
  menuSectionContainer: {
    borderRadius: 4,
    padding: theme.spacing(1),
    cursor: "pointer",
    alignItems: "center",
    "&:hover": {
      background: chroma(theme.palette.background.default).alpha(0.1).hex(),
    },
  },
  item: {
    padding: theme.spacing(2),
    fontSize: 16,
  },
  text: {
    textAlign: "start",
  },
  delete: {
    textAlign: "end",
    color: "#282c34",
  },
  menuSectionTitle: {
    textAlign: "start",
    fontSize: 18,
    fontWeight: theme.typography.fontWeightLight,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 480, // Set this based on device size
  },
}));

const BASIC_CALC = {
  path: "/basic",
  label: "basic cashflow",
};
const ANNUAL_CALC = {
  path: "/basic-annual",
  label: "basic annual cashflow",
};
const MORTGAGE_CALC = {
  path: "/mortgage",
  label: "mortgage calculator",
};
const MONTE_CARLO_CALC = {
  path: "/monte-carlo",
  label: "monte carlo simulation",
};
const PORTFOLIO_CALC = {
  path: "/portfolio",
  label: "portfolio calculator",
};
const calculators = [
  BASIC_CALC,
  ANNUAL_CALC,
  MORTGAGE_CALC,
  MONTE_CARLO_CALC,
  PORTFOLIO_CALC,
];

const CashflowSelectionGrid = ({
  title,
  description,
  onClick,
  onDelete,
}: any) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.menuSectionContainer} onClick={onClick}>
      <Grid item xs={4} className={classes.text}>
        {startCase(title || "untitled")}
      </Grid>
      <Grid item xs={onDelete ? 7 : 8} className={classes.text}>
        {description}
      </Grid>
      {onDelete ? (
        <Grid item xs={1} className={classes.delete} onClick={onDelete}>
          <CloseIcon style={{ verticalAlign: "middle" }} />
        </Grid>
      ) : null}
    </Grid>
  );
};

function useQueryParams() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function CalculatorsView() {
  const classes = useStyles();
  const location = useLocation();
  const params = useQueryParams();
  const { cashflows, ...cashflowResults } = useCashflows();
  const { portfolios, ...portfoliosResults } = usePortfolios();
  const { upsertPortfolio, ...upsertPortfolioResults } = useUpsertPortfolio();
  const { deletePortfolio, ...deletePortfolioResults } = useDeletePortfolio();
  const { upsertCashflow, ...upsertCashflowResults } = useUpsertCashflow();
  const { deleteCashflow, ...deleteCashflowResults } = useDeleteCashflow();
  const history = useHistory();
  const [isChartSelectOpen, setIsChartSelectOpen] = useState(false);
  const [cashflow, setCashflow] = useState<undefined | IAnnualCashflow>(
    undefined
  );
  const [portfolio, setPortfolio] = useState<undefined | IPortfolio>(undefined);
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const error =
    cashflowResults.error ||
    portfoliosResults.error ||
    upsertPortfolioResults.error ||
    deletePortfolioResults.error ||
    upsertCashflowResults.error ||
    deleteCashflowResults.error;
  const cashflowId = params.get("cashflowId");
  const portfolioId = params.get("portfolioId");
  const cashflowsWithoutPortfolios = useMemo(
    () =>
      filter(
        cashflows,
        (cashflow) => !cashflow.portfolios || !cashflow.portfolios.length
      ),
    [cashflows]
  );

  const handleSelectCalculator = (calculator: any) => {
    setIsChartSelectOpen(false);
    history.push(`/calculators${calculator.path}`);
  };
  const handleSelectCashflow = (cashflow: IAnnualCashflow) => {
    let calculator = ANNUAL_CALC;
    switch (cashflow.type) {
      case "basic": {
        calculator = BASIC_CALC;
        break;
      }
      case "annual": {
        calculator = ANNUAL_CALC;
        break;
      }
      case "mortgage": {
        calculator = MORTGAGE_CALC;
        break;
      }
      case "monte-carlo": {
        calculator = MONTE_CARLO_CALC;
        break;
      }
      default:
    }
    setCashflow(cashflow);
    history.push(`/calculators${calculator.path}?cashflowId=${cashflow.id}`);
    setIsChartSelectOpen(false);
  };
  const handleSelectPortfolio = (portfolio: IPortfolio) => {
    setPortfolio(portfolio);
    history.push(
      `/calculators${PORTFOLIO_CALC.path}?portfolioId=${portfolio.id}`
    );
    setIsChartSelectOpen(false);
  };
  const handleSavePortfolio = async (portfolio: IPortfolio) => {
    return upsertPortfolio(portfolio);
  };
  const handleDeletePortfolio = (portfolio: IPortfolio) => {
    deletePortfolio(portfolio);
  };
  const handleSaveCashflow = (cashflow: IAnnualCashflow | ICashflow) => {
    upsertCashflow(cashflow);
  };
  const handleDeleteCashflow = (
    cashflow: IAnnualCashflow | IMortgageCashflow
  ) => {
    deleteCashflow(cashflow);
  };

  useEffect(() => {
    if (upsertCashflowResults.cashflow) {
      setCashflow(upsertCashflowResults.cashflow);
      if (cashflowId !== upsertCashflowResults.cashflow.id && !portfolioId) {
        history.push({
          search: `?cashflowId=${upsertCashflowResults.cashflow.id}`,
        });
      }
    }
  }, [upsertCashflowResults.cashflow]);

  useEffect(() => {
    if (upsertPortfolioResults.portfolio) {
      setPortfolio(upsertPortfolioResults.portfolio);
      if (portfolioId !== upsertPortfolioResults.portfolio.id) {
        history.push({
          search: `?portfolioId=${upsertPortfolioResults.portfolio.id}`,
        });
      }
    }
  }, [upsertPortfolioResults.portfolio]);

  useEffect(() => {
    if (cashflowId && (!cashflow || cashflowId !== cashflow.id)) {
      const cashflow = find(cashflows, ["id", cashflowId]);
      setCashflow(cashflow);
    }
  }, [cashflowId, cashflow, cashflows]);

  useEffect(() => {
    if (portfolioId && (!portfolio || portfolioId !== portfolio.id)) {
      const portfolio = find(portfolios, ["id", portfolioId]);
      setPortfolio(portfolio);
    }
  }, [portfolioId, portfolio, portfolios]);

  useEffect(() => {
    if (portfolios) {
      const newPortfolio = find(portfolios, ["id", portfolioId]);
      if (!isEqual(portfolio, newPortfolio)) {
        setPortfolio(newPortfolio);
      }
    }
  }, [portfolios]);

  useEffect(() => {
    if (location.pathname === "/calculators") {
      if (cashflowId || portfolioId) {
        history.push({ search: undefined });
      }
      if (portfolio) {
        setPortfolio(undefined);
      }
      if (cashflow) {
        setCashflow(undefined);
      }
    }
  }, [location.pathname, cashflowId, portfolio, portfolioId, cashflow]);

  return (
    <React.Fragment>
      <ErrorCatcher error={error} />
      <Container maxWidth="lg" className={classes.container}>
        <Switch>
          <Route path="/calculators/basic">
            <BasicCashflowCalculator
              cashflow={cashflow}
              saveCashflow={handleSaveCashflow}
            />
          </Route>
          <Route path="/calculators/basic-annual">
            <BasicAnnualCashflowCalculator
              cashflow={cashflow}
              saveCashflow={handleSaveCashflow}
            />
          </Route>
          <Route path="/calculators/mortgage">
            <MortgageCashflowCalculator
              cashflow={cashflow}
              saveCashflow={handleSaveCashflow}
            />
          </Route>
          <Route path="/calculators/monte-carlo">
            <MonteCarloCashflowCalculator
              cashflow={cashflow}
              saveCashflow={handleSaveCashflow}
            />
          </Route>
          <Route path="/calculators/portfolio">
            <PortfolioBuilder
              portfolio={portfolio}
              deleteCashflow={handleDeleteCashflow}
              saveCashflow={handleSaveCashflow}
              savePortfolio={handleSavePortfolio}
            />
          </Route>
          <Route path="/calculators">
            <Container maxWidth="sm" className={classes.menuContainer}>
              <Grid container>
                {cashflowsWithoutPortfolios &&
                cashflowsWithoutPortfolios.length ? (
                  <>
                    <Grid item xs={12} className={classes.menuSectionTitle}>
                      Use existing cashflow
                    </Grid>
                    {map(cashflowsWithoutPortfolios, (cashflow) => (
                      <CashflowSelectionGrid
                        key={cashflow.id}
                        title={cashflow.title}
                        description={cashflow.description}
                        onClick={() => handleSelectCashflow(cashflow)}
                        onDelete={(evt: any) => {
                          evt.stopPropagation();
                          handleDeleteCashflow(cashflow);
                        }}
                      />
                    ))}
                  </>
                ) : null}
                {portfolios && portfolios.length ? (
                  <>
                    <Grid item xs={12} className={classes.menuSectionTitle}>
                      Use existing portfolio
                    </Grid>
                    {map(portfolios, (portfolio) => (
                      <CashflowSelectionGrid
                        key={portfolio.id}
                        title={portfolio.title}
                        description={portfolio.description}
                        onClick={() => handleSelectPortfolio(portfolio)}
                        onDelete={(evt: any) => {
                          evt.stopPropagation();
                          handleDeletePortfolio(portfolio);
                        }}
                      />
                    ))}
                  </>
                ) : null}
                <Grid item xs={12} className={classes.menuSectionTitle}>
                  Run a new calculation
                </Grid>
                <CashflowSelectionGrid
                  title="Basic Cashflow"
                  description="Create a new basic cashflow calculation"
                  onClick={() => handleSelectCalculator(BASIC_CALC)}
                />
                <CashflowSelectionGrid
                  title="Annual Cashflow"
                  description="Create a new basic cashflow calculation with an annual interest rate"
                  onClick={() => handleSelectCalculator(ANNUAL_CALC)}
                />
                <CashflowSelectionGrid
                  title="Mortgage Cashflow"
                  description="Create a new mortgage cashflow"
                  onClick={() => handleSelectCalculator(MORTGAGE_CALC)}
                />
                <CashflowSelectionGrid
                  title="Monte Carlo Simulation"
                  description="(Beta) Create a new monte carlo simulation"
                  onClick={() => handleSelectCalculator(MONTE_CARLO_CALC)}
                />
                <CashflowSelectionGrid
                  title="Portfolio"
                  description="Create a portfolio of multiple cashflows"
                  onClick={() => handleSelectCalculator(PORTFOLIO_CALC)}
                />
              </Grid>
            </Container>
          </Route>
        </Switch>
      </Container>
    </React.Fragment>
  );
}
