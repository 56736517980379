import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import { filter, keys, mapValues, omit } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useToast } from "../../hooks/toast";
import {
  calculateCashflowByAnnualInterestRate,
  ICalculateCashflowByAnnualInterestRateOptionsFull,
} from "../../lib/calculators";
import { IAnnualCashflow, PaymentFrequency } from "../../types/Cashflow";
import Select from "../Select";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    justifyContent: "space-around",
  },
  item: {
    padding: theme.spacing(2),
    fontSize: 16,
  },
  itemControl: {
    width: "100%",
  },
  fixedHeight: {
    height: 480, // Set this based on device size
  },
}));

export interface IAnnualizedCashflowFormProps {
  onChange?: (
    cashflow: ICalculateCashflowByAnnualInterestRateOptionsFull
  ) => void;
  onSubmit?: (cashflow: IAnnualCashflow) => void;
  showCompute?: boolean;
  initialValue?: IAnnualCashflow;
}

const NON_NUMERIC_PROPERTIES = [
  "description",
  "title",
  "portfolios",
  "userId",
  "user",
  "notes",
  "paymentPeriod",
  "startDate",
  "id",
  "parentId",
];

export const useComputeAnnualizedCashflow = () => {
  const [, setToast] = useToast();
  return useCallback(
    (options: ICalculateCashflowByAnnualInterestRateOptionsFull) => {
      try {
        const missingKeys: Array<string | number> = filter(
          keys(options),
          (key: keyof ICalculateCashflowByAnnualInterestRateOptionsFull) =>
            options[key] === null ||
            (options[key] as any) === "" ||
            (typeof options[key] === "number" && isNaN(options[key] as number))
        ) as any;
        if (missingKeys.length === 1) {
          const cashflow = calculateCashflowByAnnualInterestRate(
            mapValues(omit(options, missingKeys[0]), (val, key) =>
              NON_NUMERIC_PROPERTIES.includes(key) ? val : parseFloat(val)
            )
          );
          setToast({
            message: `Successfully calculated the ${missingKeys[0]}`,
            severity: "success",
          });
          return cashflow;
        } else if (missingKeys.length === 0) {
          const cashflow = calculateCashflowByAnnualInterestRate(
            mapValues(omit(options, "payment"), (val, key) =>
              NON_NUMERIC_PROPERTIES.includes(key)
                ? val
                : parseFloat(val as any)
            ) as any
          );
          if (cashflow.payment !== parseFloat(options.payment as any)) {
            setToast({
              message:
                "The current values are invalid. Please clear one and re-compute.",
              severity: "warning",
            });
          } else {
            setToast({
              message: `Nothing to do. The current values are all valid!`,
              severity: "success",
            });
          }
        } else {
          setToast({
            message: `Not enough info! Please fill in more of the inputs. All but one is needed but the following are missing ${missingKeys.join(
              ", "
            )}`,
            severity: "warning",
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    []
  );
};

export const AnnualizedCashflowForm = ({
  onChange,
  onSubmit,
  showCompute = false,
  initialValue,
}: IAnnualizedCashflowFormProps) => {
  const classes = useStyles();

  const [state, setState] = useState<Record<string, string | number>>({
    startDate: moment() as unknown as string,
    paymentPeriod: PaymentFrequency.MONTHLY,
    presentValue: 0,
    futureValue: "",
    numberOfPayments: 0,
    payment: 0,
    annualInterestRate: 0,
    ...(initialValue as any),
  });

  const handleChange = useCallback(
    (property, mapToState?: (value: string | number) => string | number) =>
      (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = evt.target.value;
        const newState: typeof state = omit(state, property);
        if (value !== "") {
          newState[property] = mapToState ? mapToState(value) : value;
        } else {
          // TODO Get rid of strings in the state where numbers should live
          newState[property] = "";
        }
        setState(newState);
      },
    [state]
  );

  useEffect(() => {
    onChange &&
      onChange(
        state as unknown as ICalculateCashflowByAnnualInterestRateOptionsFull
      );
  }, [state]);

  const calculateAnnualizedCashflow = useComputeAnnualizedCashflow();
  const handleClickCompute = useCallback(
    (evt: any) => {
      const computed = calculateAnnualizedCashflow(state as any);
      if (computed) {
        setState(computed as any);
        onSubmit && onSubmit(computed);
      }
    },
    [state, onSubmit]
  );

  return (
    <Grid container className={classes.container}>
      <Grid className={classes.item} item xs={12} sm={4}>
        <FormControl className={classes.itemControl}>
          <InputLabel htmlFor="standard-text">Title</InputLabel>
          <Input
            id="standard-text"
            value={state.title || "untitled"}
            onFocus={(event) => event.target.select()}
            onChange={handleChange("title")}
          />
        </FormControl>
      </Grid>
      <Grid className={classes.item} item xs={12} sm={8}>
        <FormControl className={classes.itemControl}>
          <InputLabel htmlFor="standard-text">Description</InputLabel>
          <Input
            id="standard-text"
            value={state.description}
            onFocus={(event) => event.target.select()}
            onChange={handleChange("description")}
          />
        </FormControl>
      </Grid>
      <FormControl className={classes.item}>
        <InputLabel htmlFor="standard-date">Start Date</InputLabel>
        <Input
          id="standard-date"
          value={state.startDate}
          onChange={handleChange("startDate")}
          onFocus={(event) => event.target.select()}
        />
      </FormControl>
      <FormControl className={classes.item}>
        <InputLabel htmlFor="standard-adornment-amount">
          Present Value
        </InputLabel>
        <Input
          id="standard-adornment-amount"
          value={state.presentValue}
          onChange={handleChange("presentValue")}
          onFocus={(event) => event.target.select()}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
        />
      </FormControl>
      <FormControl className={classes.item}>
        <InputLabel htmlFor="standard-adornment-amount">
          Future Value
        </InputLabel>
        <Input
          id="standard-adornment-amount"
          value={state.futureValue}
          onChange={handleChange("futureValue")}
          onFocus={(event) => event.target.select()}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
        />
      </FormControl>
      <FormControl className={classes.item}>
        <InputLabel htmlFor="standard-adornment-amount">Payment</InputLabel>
        <Input
          id="standard-adornment-amount"
          value={state.payment}
          onChange={handleChange("payment")}
          onFocus={(event) => event.target.select()}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
        />
      </FormControl>
      <FormControl className={classes.item}>
        <InputLabel htmlFor="standard-percent">Annual Interest Rate</InputLabel>
        <Input
          id="standard-percent"
          value={
            state.annualInterestRate === ""
              ? ""
              : Math.round((state.annualInterestRate as number) * 100 * 1e5) /
                1e5
          }
          onChange={handleChange("annualInterestRate", (v: any) => v / 100)}
          onFocus={(event) => event.target.select()}
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
        />
      </FormControl>
      <FormControl className={classes.item}>
        <InputLabel htmlFor="standard-number">Number of Payments</InputLabel>
        <Input
          id="standard-number"
          value={state.numberOfPayments}
          onFocus={(event) => event.target.select()}
          onChange={handleChange("numberOfPayments")}
        />
      </FormControl>
      <FormControl>
        <Select
          label="Payment Period"
          value={state.paymentPeriod as any}
          selections={[
            PaymentFrequency.WEEKLY,
            PaymentFrequency.BI_WEEKLY,
            PaymentFrequency.MONTHLY,
            PaymentFrequency.ANNUALLY,
          ]}
          onChange={(value) =>
            handleChange("paymentPeriod")({ target: { value } } as any)
          }
        />
      </FormControl>
      {showCompute ? (
        <Grid className={classes.item} item xs={6} sm={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickCompute}
          >
            Compute
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
};
