import {
  filter,
  find,
  get,
  map,
  pick,
  sortBy,
  uniqBy,
  zipObject,
} from "lodash";
import { useEffect, useMemo, useState } from "react";
import { usePlaidLink } from "react-plaid-link";
import { useCreateInstitution } from "../mutations/CreateInstitution";
import * as linkInstitutionMutations from "../mutations/LinkInstitution";
import { useRegenerateBalances } from "../mutations/RegenerateBalances";
import { useSyncInstitution } from "../mutations/SyncInstitution";
import { useAuthToken } from "../queries/GetAuthToken";
import { useInstitutions } from "../queries/GetInstitutions";
import { AccountType, IAccount } from "../types/Account";
import { IInstitution } from "../types/Institution";
import { IUser } from "../types/User";
import { useSyncStatus } from "./state";
import { useToast } from "./toast";
import { useUser } from "./user";

export interface IPlaidAccount {
  [key: string]: any;
}

export interface IMapToAccountParams {
  plaidAccount: IPlaidAccount;
  plaidLinkId: string;
  institution: IInstitution;
}

export const mapToAccount = ({
  plaidAccount: { id: plaidId, name, type, mask, subtype },
  plaidLinkId,
  institution,
}: IMapToAccountParams) => {
  const finalType =
    type === "credit"
      ? AccountType.Credit
      : ["investment", "brokerage"].includes(type)
      ? AccountType.Investment
      : type === "loan"
      ? AccountType.Debt
      : subtype === "checking"
      ? AccountType.Chequeings
      : AccountType.Savings;

  return {
    plaidId,
    name,
    type: finalType,
    number: mask,
    plaidLinkId,
    institution,
  } as Partial<IAccount>;
};

export interface IUseLinkInstitutionProps {
  onComplete?: (arg: any) => void;
  accessToken?: string;
}

export const useLinkInstitution = ({
  onComplete = () => void 0,
  accessToken,
}: IUseLinkInstitutionProps) => {
  const { institutions } = useInstitutions();
  const [user] = useUser();
  const { createInstitution, ...createInstitutionResults } =
    useCreateInstitution();
  const {
    getToken,
    token,
    loading,
    error: tokenError,
  } = useAuthToken({
    userId: (user && user.id) || "",
    accessToken,
  });
  const {
    linkInstitution,
    loading: linkLoading,
    error: linkError,
    link,
  } = linkInstitutionMutations.useLinkInstitution();
  const [{ code, metadata, institution }, setState] = useState({
    code: null,
    metadata: null,
    institution: null,
  } as any);
  const [otherError, setError] = useState<Error | null>(null);
  const config = useMemo(
    () => ({
      token: token || "",
      onSuccess: (code: string, metadata: any) => {
        const institution =
          find(institutions, [
            "plaidId",
            metadata.institution.institution_id,
          ]) || find(institutions, ["name", metadata.institution.name]);
        if (accessToken) {
          // Access token is passed in to re-link an existing account
          if (institution) {
            onComplete({
              institution,
              metadata,
            });
          } else {
            setError(
              new Error(
                `Expected an institution to be found for a relinked account but none were`
              )
            );
          }
        } else {
          setState({
            code,
            metadata,
            institution,
          });
        }
      },
    }),
    [token, institutions]
  );

  const { open, ready, error } = usePlaidLink(config);

  useEffect(() => {
    if (token && ready) {
      open();
    }
  }, [open, ready]);

  useEffect(() => {
    if (code) {
      if (institution) {
        linkInstitution({
          variables: {
            input: {
              code,
              institution: {
                id: institution.id,
              },
            },
          },
        });
      } else {
        createInstitution({
          variables: {
            institution: {
              name: metadata.institution.name,
              plaidId: metadata.institution.institution_id,
            },
          },
        });
      }
    }
  }, [code, institution, metadata]);

  useEffect(() => {
    if (createInstitutionResults.institution) {
      setState({
        code,
        metadata,
        institution: createInstitutionResults.institution,
      });
    }
  }, [createInstitutionResults.institution]);

  useEffect(() => {
    if (link) {
      onComplete({
        link,
        institution,
        metadata,
      });
    }
  }, [link, onComplete]);

  return {
    open: getToken,
    link,
    metadata,
    loading: loading || !ready || linkLoading,
    error: tokenError || error || linkError || otherError,
  };
};

export const useSyncInstitutions = () => {
  const [syncStatus, setSyncStatus] = useSyncStatus();
  const { syncInstitution, loading, error, success } = useSyncInstitution();
  const { regenerateBalances, ...regenerateBalancesResults } =
    useRegenerateBalances();
  const [user] = useUser();
  const [, setToast] = useToast();
  const [syncing, setSyncing] = useState(false);
  const syncInstitutions = async (args?: {
    user?: IUser;
    institution?: IInstitution;
  }) => {
    setSyncing(true);
    const usr = user || (args && args.user);
    if (!usr) {
      setToast({
        severity: "warning",
        message: `Cannot sync accounts yet. Please try again`,
      });
      return;
    }
    let institutions = [];
    if (args && args.institution) {
      institutions.push(args.institution);
    } else {
      const linksByInstitutionId = zipObject(
        map(usr.plaidLinks, "institution.id"),
        usr.plaidLinks
      );
      const accounts = filter(
        usr.accounts,
        (account) => linksByInstitutionId[account.institution.id]
      );
      institutions = sortBy(uniqBy(map(accounts, "institution"), "id"), "name");
    }
    let currentSyncStatus = {
      ...syncStatus,
    };
    for (const institution of institutions) {
      setToast({
        severity: "info",
        message: `Syncing ${institution.name}...`,
        autoHideDuration: null,
      });
      currentSyncStatus = {
        ...currentSyncStatus,
        [institution.id]: {
          isSyncing: true,
        },
      };
      setSyncStatus(currentSyncStatus);
      try {
        const res = await syncInstitution({
          variables: {
            input: { institution: pick(institution, "id") },
          },
        });
        const fromDate = get(res, ["data", "syncInstitution", "fromDate"]);
        setToast({
          severity: "info",
          message: `Generating balances for ${institution.name}`,
          autoHideDuration: null,
        });
        await regenerateBalances({
          variables: {
            institutionId: institution.id,
            fromDate,
          },
        });
        currentSyncStatus = {
          ...currentSyncStatus,
          [institution.id]: {
            isSyncing: false,
          },
        };
        setSyncStatus(currentSyncStatus);
      } catch (err) {
        setToast({
          severity: "error",
          message: `Encountered an error while syncing ${institution.name}`,
        });
        const isReLinkError =
          err.message.indexOf("ITEM_LOGIN_REQUIRED") > -1 ||
          err.message.indexOf("PENDING_EXPIRATION") > -1;
        currentSyncStatus = {
          ...currentSyncStatus,
          [institution.id]: {
            isSyncing: false,
            warning: isReLinkError
              ? `This instituion needs to be re-linked`
              : undefined,
            error: isReLinkError ? undefined : err,
          },
        };
        setSyncStatus(currentSyncStatus);
        console.warn(err);
        await new Promise((resolve) => {
          setTimeout(resolve, 1500);
        });
      }
    }
    setToast({
      severity: "success",
      message: `All accounts have been synced!`,
    });
    setSyncing(false);
  };

  return {
    syncInstitutions,
    loading: syncing,
    error,
  };
};
