import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { TRANSFER_FRAGMENT } from "../fragments/Transfer";
import { ITransfer } from "../types/Transfer";

export interface IGenerateTransfersInput {
  fromDate?: Date;
  regenerate?: boolean;
}

export const GENERATE_TRANSFERS = gql`
  ${TRANSFER_FRAGMENT}
  mutation GenerateTransfers($regenerate: Boolean, $fromDate: DateTime) {
    generateTransfers(regenerate: $regenerate, fromDate: $fromDate) {
      ...TransferFragment
    }
  }
`;

export const useGenerateTransfers = () => {
  const [generateTransfers, { called, loading, data, error }] = useMutation<{
    generateTransfers: ITransfer[];
  }>(GENERATE_TRANSFERS, { refetchQueries: ["GetTransactions"] });
  return {
    called,
    generateTransfers,
    loading,
    error,
    transfer: data && data.generateTransfers,
  };
};
