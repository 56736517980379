import {
  filter,
  flatten,
  isNumber,
  map,
  memoize,
  omit,
  range,
  reduce,
  sortBy,
  sumBy,
  unzip,
  zipWith,
} from "lodash";
import * as math from "mathjs";
import moment, { Moment } from "moment";
import { formatCurrency } from "../helpers";
import { AccountType, IAccount } from "../types/Account";
import {
  IAnnualCashflow,
  ICashflow,
  IMortgageCashflow,
  PaymentFrequency,
} from "../types/Cashflow";
import { IPortfolio } from "../types/Portfolio";
import { ITransaction, TransactionType } from "../types/Transaction";

export interface IAnnuity {
  payment: number;
  numberOfPayments?: number;
}

// Any unit of time may be used for the periods as the ratio is all that matters
export const getEquivalentCompoundedRate = (
  interestRate: number,
  originalCompoundingPeriod: number,
  newCompoundingPeriod: number
) => {
  return (
    Math.pow(
      1 + interestRate,
      newCompoundingPeriod / originalCompoundingPeriod
    ) - 1
  );
};

export type ICalculateCashflow = Partial<Omit<ICashflow, "startDate">>;

export interface ICalculateCashflowFull extends ICalculateCashflow {
  startDate: string | number | Moment | Date;
}

export type ICalculateCashflowByAnnualInterestRateOptions = Partial<
  Omit<IAnnualCashflow, "startDate" | "interestRate">
>;
export interface ICalculateCashflowByAnnualInterestRateOptionsFull
  extends ICalculateCashflowByAnnualInterestRateOptions {
  startDate: string | number | Moment | Date;
}

export interface ICalculateMortgageCashflow
  extends Omit<
    ICalculateCashflowByAnnualInterestRateOptions,
    "futureValue" | "numberOfPayments" | "presentValue"
  > {
  askingPrice?: number;
  years?: number;
  downDeposit?: number;
}

export interface ICalculateMortgageCashflowFull
  extends ICalculateMortgageCashflow {
  startDate: string | number | Moment | Date;
}

const round = (val: number, decimals = 2) =>
  Math.round(val * Math.pow(10, decimals)) / Math.pow(10, decimals);

export const getPaymentPeriodMS = (
  paymentPeriod?: PaymentFrequency | number
) => {
  if (isNumber(paymentPeriod)) {
    return paymentPeriod;
  } else {
    const ONE_YEAR = 365 * 24 * 60 * 60 * 1000;
    switch (paymentPeriod) {
      case PaymentFrequency.WEEKLY: {
        return ONE_YEAR / 52;
      }
      case PaymentFrequency.BI_WEEKLY: {
        return ONE_YEAR / 26;
      }
      case PaymentFrequency.ANNUALLY: {
        return ONE_YEAR;
      }
      case PaymentFrequency.MONTHLY:
      default: {
        return ONE_YEAR / 12;
      }
    }
  }
};

export function calculateCashflow(options: ICalculateCashflow): ICashflow;
export function calculateCashflow(
  options: ICalculateCashflowFull
): Required<ICashflow>;
export function calculateCashflow(
  options: ICalculateCashflow | ICalculateCashflowFull
) {
  const EXPECTED_PROPERTIES: Array<keyof ICalculateCashflow> = [
    "presentValue",
    "payment",
    "numberOfPayments",
    "interestRate",
    "futureValue",
  ];
  const missingCashflowProperties = filter(
    EXPECTED_PROPERTIES,
    (property) => !isNumber(options[property])
  );
  if (missingCashflowProperties.length > 1) {
    throw new Error(
      `Too many missing parameters to calculate the cashflow. Only one property can be omitted but all of these properties were: ${missingCashflowProperties.join(
        ", "
      )}`
    );
  }

  let {
    presentValue,
    futureValue,
    numberOfPayments,
    interestRate,
    payment,
    ...otherOptions
  } = options;
  if (missingCashflowProperties.length === 1) {
    const interestOnPresentValue = Math.pow(
      1 + interestRate!,
      numberOfPayments!
    );
    const interestOnPayments = sumBy(range(numberOfPayments!), (n) =>
      Math.pow(1 + interestRate!, n)
    );
    const futureValueOfPayments = payment! * interestOnPayments;

    switch (missingCashflowProperties[0]) {
      case "presentValue": {
        presentValue = round(
          (-futureValue! - futureValueOfPayments) / interestOnPresentValue
        );
        break;
      }
      case "payment": {
        payment = round(
          (-futureValue! - presentValue! * interestOnPresentValue) /
            interestOnPayments
        );
        break;
      }
      case "numberOfPayments": {
        if (payment === 0) {
          numberOfPayments = Math.round(
            Math.log(interestOnPresentValue!) / Math.log(1 + interestRate!)
          );
        } else {
          numberOfPayments = 1;
          let min = 0;
          let max: number | null = null;
          let delta = 1;
          while (Math.abs(delta) >= 1) {
            const cashflow = calculateCashflow({
              presentValue,
              interestRate,
              payment,
              numberOfPayments,
            });

            const error = futureValue! - cashflow.futureValue;
            const oldNumberOfPayments = numberOfPayments;
            if (presentValue! < 0 ? error < 0 : error > 0) {
              min = numberOfPayments;
            } else {
              max = numberOfPayments;
            }
            if (max === null) {
              numberOfPayments *= 2;
            } else {
              numberOfPayments = Math.ceil((max + min) / 2);
            }
            delta = numberOfPayments - oldNumberOfPayments;
          }
        }
        break;
      }
      case "interestRate": {
        if (payment === 0) {
          interestRate =
            Math.pow(-futureValue! / presentValue!, 1 / numberOfPayments!) - 1;
        } else {
          interestRate = 0.1;
          let min = 0;
          let max: number | null = null;
          let error = 1;
          while (Math.abs(error) >= 0.01) {
            const cashflow = calculateCashflow({
              presentValue,
              interestRate,
              payment,
              numberOfPayments,
            });

            error = futureValue! - cashflow.futureValue;
            if (error < 0) {
              max = interestRate;
            } else {
              min = interestRate;
            }
            if (max === null) {
              interestRate *= 2;
            } else {
              interestRate = (max + min) / 2;
            }
          }
        }
        interestRate = round(interestRate, 6);
        break;
      }
      case "futureValue": {
        futureValue = -round(
          presentValue! * interestOnPresentValue + futureValueOfPayments
        );
        break;
      }
    }
  }

  return {
    ...otherOptions,
    presentValue,
    futureValue,
    numberOfPayments,
    interestRate,
    payment,
  } as ICashflow;
}

export function calculateCashflowByAnnualInterestRate(
  options: ICalculateCashflowByAnnualInterestRateOptions
): IAnnualCashflow;
export function calculateCashflowByAnnualInterestRate(
  options: ICalculateCashflowByAnnualInterestRateOptionsFull
): Required<IAnnualCashflow>;
export function calculateCashflowByAnnualInterestRate({
  paymentPeriod,
  annualInterestRate,
  ...options
}:
  | ICalculateCashflowByAnnualInterestRateOptions
  | ICalculateCashflowByAnnualInterestRateOptionsFull) {
  let cashflow: ICashflow | IAnnualCashflow;

  // TODO Create a function out of this for reusability
  if (isNumber(annualInterestRate)) {
    let interestRate: number;
    if (isNumber(paymentPeriod)) {
      interestRate = getEquivalentCompoundedRate(
        annualInterestRate,
        getPaymentPeriodMS(PaymentFrequency.ANNUALLY),
        paymentPeriod
      );
    } else {
      switch (paymentPeriod) {
        case PaymentFrequency.WEEKLY: {
          interestRate = getEquivalentCompoundedRate(annualInterestRate, 52, 1);
          break;
        }
        case PaymentFrequency.BI_WEEKLY: {
          interestRate = getEquivalentCompoundedRate(annualInterestRate, 52, 2);
          break;
        }
        case PaymentFrequency.ANNUALLY: {
          interestRate = annualInterestRate;
          break;
        }
        default: {
          // Monthly
          interestRate = getEquivalentCompoundedRate(annualInterestRate, 12, 1);
          break;
        }
      }
    }

    cashflow = calculateCashflow({
      ...options,
      interestRate,
    });
  } else {
    cashflow = calculateCashflow(options);

    // TODO Create a function out of this for reusability
    if (isNumber(paymentPeriod)) {
      annualInterestRate = getEquivalentCompoundedRate(
        cashflow.interestRate,
        paymentPeriod,
        getPaymentPeriodMS(PaymentFrequency.ANNUALLY)
      );
    } else {
      switch (paymentPeriod) {
        case PaymentFrequency.WEEKLY: {
          annualInterestRate = getEquivalentCompoundedRate(
            cashflow.interestRate,
            1,
            52
          );
          break;
        }
        case PaymentFrequency.BI_WEEKLY: {
          annualInterestRate = getEquivalentCompoundedRate(
            cashflow.interestRate,
            2,
            52
          );
          break;
        }
        case PaymentFrequency.ANNUALLY: {
          annualInterestRate = cashflow.interestRate;
          break;
        }
        default: {
          // Monthly
          annualInterestRate = getEquivalentCompoundedRate(
            cashflow.interestRate,
            1,
            12
          );
          break;
        }
      }
    }
  }

  const result = {
    ...cashflow,
    paymentPeriod: paymentPeriod || PaymentFrequency.MONTHLY,
    annualInterestRate,
  };

  if (
    (options as ICalculateCashflowByAnnualInterestRateOptionsFull).startDate
  ) {
    return result as Required<IAnnualCashflow>;
  } else {
    return result as IAnnualCashflow;
  }
}

export function calculateMortgageCashflow(
  options: ICalculateMortgageCashflow
): IMortgageCashflow;
export function calculateMortgageCashflow(
  options: ICalculateMortgageCashflowFull
): Required<IMortgageCashflow>;
export function calculateMortgageCashflow({
  years,
  downDeposit,
  askingPrice,
  ...options
}: ICalculateMortgageCashflowFull | ICalculateMortgageCashflow) {
  let presentValue: number | undefined;
  let numberOfPayments: number | undefined;

  if (isNumber(askingPrice)) {
    if (isNumber(downDeposit)) {
      presentValue = askingPrice - downDeposit;
    } else {
      presentValue = askingPrice;
    }
  }
  if (isNumber(years)) {
    switch (options.paymentPeriod) {
      case PaymentFrequency.WEEKLY: {
        numberOfPayments = 52 * years;
        break;
      }
      case PaymentFrequency.BI_WEEKLY: {
        numberOfPayments = 26 * years;
        break;
      }
      case PaymentFrequency.ANNUALLY: {
        numberOfPayments = years;
        break;
      }
      default: {
        // Monthly
        numberOfPayments = 12 * years;
        break;
      }
    }
  }

  const cashflow = calculateCashflowByAnnualInterestRate({
    ...omit(options, ["futureValue", "numberOfPayments", "presentValue"]),
    futureValue: 0,
    numberOfPayments,
    presentValue,
  });

  if (!isNumber(askingPrice)) {
    if (isNumber(downDeposit)) {
      askingPrice = cashflow.presentValue + downDeposit;
    } else {
      askingPrice = cashflow.presentValue;
    }
  }

  if (!isNumber(years)) {
    switch (options.paymentPeriod) {
      case PaymentFrequency.WEEKLY: {
        years = cashflow.numberOfPayments / 52;
        break;
      }
      case PaymentFrequency.BI_WEEKLY: {
        years = cashflow.numberOfPayments / 26;
        break;
      }
      case PaymentFrequency.ANNUALLY: {
        years = cashflow.numberOfPayments;
        break;
      }
      default: {
        // Monthly
        years = cashflow.numberOfPayments / 12;
        break;
      }
    }
  }

  return {
    ...cashflow,
    downDeposit,
    years,
    askingPrice,
  };
}

export const getRunningBalance = (cashflow: ICashflow) => {
  return reduce(
    range(cashflow.numberOfPayments),
    (balances, n) => {
      const balance = round(
        balances[n] * (1 + cashflow.interestRate) + cashflow.payment
      );
      balances.push(balance);
      return balances;
    },
    [cashflow.presentValue]
  );
};

export const getInterestPayments = (cashflow: ICashflow, decimals = 2) => {
  const interestPayments = reduce(
    range(cashflow.numberOfPayments),
    ({ balances, interestPayments }, n) => {
      const interestPayment = round(
        balances[n] * cashflow.interestRate,
        decimals
      );
      const balance = round(
        balances[n] + cashflow.payment + interestPayment,
        decimals
      );
      balances.push(balance);
      interestPayments.push(interestPayment);
      return {
        balances,
        interestPayments,
      };
    },
    { balances: [cashflow.presentValue], interestPayments: [0] }
  ).interestPayments;

  // console.log(interestPayments);
  return interestPayments;
};

export const getSignedAmount = (
  amount: number,
  transactionType: TransactionType,
  isDebt = false
) => {
  const relativeAmount =
    transactionType === TransactionType.debit ? -amount : amount;
  if (isDebt) {
    return -relativeAmount;
  } else {
    return relativeAmount;
  }
};

export const getTransactionType = (amount: number, isDebt = false) => {
  if (isDebt) {
    return amount < 0 ? TransactionType.debit : TransactionType.credit;
  } else {
    return amount > 0 ? TransactionType.debit : TransactionType.credit;
  }
};

export const getTransactions = (
  cashflow: Required<ICashflow> | Array<Required<ICashflow>>
): ITransaction[] => {
  if (Array.isArray(cashflow)) {
    // TODO set account info for each cashflow
    return sortBy(flatten(map(cashflow, getTransactions)), "date");
  } else {
    const interestPayments = getInterestPayments(cashflow);
    const deposits = range(
      cashflow.payment,
      cashflow.numberOfPayments + 1 + cashflow.payment,
      0
    );
    deposits[0] = 0;

    const account = {
      type:
        cashflow.type === "investment"
          ? AccountType.Investment
          : AccountType.Debt,
    } as IAccount;

    // TODO Need a better way to manage presentValue between cashflows so that they do not show as transactions
    const firstTransaction: Partial<ITransaction> = {
      account,
      amount: Math.abs(cashflow.presentValue),
      type: getTransactionType(
        cashflow.presentValue,
        account.type !== AccountType.Investment
      ),
      description: `${
        cashflow.presentValue < 0 ? "Loaned" : "Deposited"
      } ${formatCurrency(cashflow.presentValue)}`,
      date: cashflow.startDate,
    };

    const paymentPeriod = getPaymentPeriodMS(cashflow.paymentPeriod);
    return map(
      reduce(
        deposits,
        (transactions, deposit, n) => {
          const date = moment(cashflow.startDate).add(
            paymentPeriod * n,
            "millisecond"
          );
          transactions.push({
            amount: Math.abs(deposit),
            type: getTransactionType(
              deposit,
              account.type !== AccountType.Investment
            ),
            description: `${
              deposit < 0 ? "Withdrawal" : "Deposit"
            } made in the amount of ${formatCurrency(deposit)}`,
            date,
          });
          transactions.push({
            amount: Math.abs(interestPayments[n]),
            type: getTransactionType(
              interestPayments[n],
              account.type !== AccountType.Investment
            ),
            description: `${
              interestPayments[n] < 0 ? "Paid" : "Earned"
            } ${formatCurrency(interestPayments[n])} in interest`,
            date,
          });
          return transactions;
        },
        [firstTransaction]
      ),
      (partialTransaction) => {
        return {
          ...partialTransaction,
          account,
          equivalentAmount: partialTransaction.amount,
        } as ITransaction;
      }
    );
  }
};

export const getNetPresentValueOfAnnuity = (
  { payment, numberOfPayments }: IAnnuity,
  discountRate: number,
  decimals = 2
) => {
  if (isNumber(numberOfPayments)) {
    return round(
      (payment * (1 - (1 - discountRate) ** (numberOfPayments + 1))) /
        discountRate -
        payment,
      decimals
    );
  } else {
    return round(payment / discountRate - payment, decimals);
  }
};

export const getNetPresentValue = (
  cashflow: ICashflow,
  discountRate: number,
  decimals = 2
) => {
  let discountRatio = 1;
  const presentValueOfInterest = sumBy(
    getInterestPayments(cashflow, decimals),
    (pmt: number) => {
      const presentValueOfPayment = pmt * discountRatio;
      discountRatio *= 1 - discountRate;
      return presentValueOfPayment;
    }
  );
  const presentValueOfPayments = getNetPresentValueOfAnnuity(
    cashflow,
    discountRate,
    decimals
  );
  // console.log('NPV', presentValueOfPayments, presentValueOfInterest, cashflow.presentValue);
  return round(
    cashflow.presentValue + presentValueOfInterest + presentValueOfPayments,
    decimals
  );
};

// This will return the value of the cashflow in units of dollars @ date (ie - if date = today then this returns the net present value of the cashflow)
export const calculateTimeValueOfCashflow = (
  cashflow: Required<ICashflow>,
  discountRate: number,
  date?: Moment | string | number | Date,
  decimals = 2
) => {
  const presentValue = getNetPresentValue(cashflow, discountRate, decimals);
  const dateDiff = moment(date).diff(cashflow.startDate, "millisecond");
  const paymentPeriod = getPaymentPeriodMS(cashflow.paymentPeriod);
  const interestRate = getEquivalentCompoundedRate(
    -discountRate,
    paymentPeriod,
    Math.abs(dateDiff)
  );
  // console.log('TVOM', getEquivalentCompoundedRate(getEquivalentCompoundedRate(-0.03, 12, 1), 1, 24), interestRate, dateDiff/(1000*60*60*24*365), moment(date).toISOString(), moment(cashflow.startDate).toISOString());

  const value = presentValue * (1 + interestRate);
  return round(value);
};

export const getPortfolioValue = (
  portfolio: IPortfolio,
  annuaDiscountRate: number,
  date?: Moment | string | number | Date
) => {
  return round(
    sumBy(portfolio.cashflows, (cashflow) =>
      calculateTimeValueOfCashflow(
        cashflow,
        getEquivalentCompoundedRate(
          annuaDiscountRate,
          getPaymentPeriodMS(PaymentFrequency.ANNUALLY),
          getPaymentPeriodMS(cashflow.paymentPeriod)
        ),
        date
      )
    ),
    2
  );
};

/**
 *
export const calculateCashflows = <T extends ICalculateCashflow, TResult extends ICashflow>(options: T[], calculate?: (opts: T) => TResult) => {
  const stats: ICashflow = {
    presentValue: 0,
    futureValue: 0,
    interestRate: 0,
    numberOfPayments: 0,
    payment: 0,
  };
  const cashflows = map(sortBy(options, 'startDate'), opts => {
    if (stats.startDate && moment(opts.startDate).isBefore(stats.startDate)) {
      // TODO
    }
    const paymentPeriod = getPaymentPeriodMS(opts.paymentPeriod);
    const cashflow = calculate ? calculate(opts) : calculateCashflow(opts);

    if (!stats.startDate || stats.startDate === cashflow.startDate) {
      stats.startDate = cashflow.startDate;
      stats.presentValue += cashflow.presentValue;
    }

    stats.futureValue += cashflow.futureValue;
    return {
      ...cashflow,
      paymentPeriod,
      endDate: moment(cashflow.startDate).add(paymentPeriod*cashflow.numberOfPayments),
    };
  });

  // TODO calculate the presentValue of all the cashflows
  // TODO calculate the futureValue of all the cashflows
  // TODO calculate the I/Y for all of the cashflows
  // TODO need to merge all of the cashflows
  return cashflows;
}
*/

/**
 *
export const concatenateCashflows = <T = ICashflow>(cashflows: T[]) => {
  const paymentPeriods = uniq(map(cashflows, 'paymentPeriod'));
  console.log(paymentPeriods);
  return map(
    cashflows,
    cashflow => ({
      ...cashflow,
    }),
  );
}
*/

/**
 *
export const calculateNetPresentValue = <T extends ICalculateCashflow, TResult extends ICashflow>(options: T[], calculate?: (opts: T) => TResult) => {
}
*/

export const sortPrices = ({
  dates,
  prices,
}: {
  dates: Array<moment.Moment | Date>;
  prices: number[];
}) => {
  const [newDates, newPrices] = (
    dates.length
      ? unzip(
          map(
            sortBy(
              zipWith(dates, prices, (date, price) => ({
                price,
                date: moment(date).toISOString(),
              })),
              "date"
            ),
            ({ price, date }) => [moment(date).toDate(), price]
          )
        )
      : [dates, prices]
  ) as [Array<Date>, Array<number>];

  return {
    dates: newDates,
    prices: newPrices,
  };
};

export const getTimesteps = ({ startDate, endDate, timestep }: any) =>
  moment(endDate).diff(startDate, "days") /
  moment().diff(moment().subtract(1, timestep), "days");

const erfinv = memoize((x: number) => {
  // maximum relative error = .00013
  const a = 0.147;
  //if (0 == x) { return 0 }
  const b = 2 / (Math.PI * a) + Math.log(1 - x ** 2) / 2;
  const sqrt1 = Math.sqrt(b ** 2 - Math.log(1 - x ** 2) / a);
  const sqrt2 = Math.sqrt(sqrt1 - b);
  return sqrt2 * Math.sign(x);
});

interface IMarginOfErrorProps {
  confidence: number;
  mean: number;
  std: number;
  samples: number;
}

const logNormalVariance = memoize(
  ({ std, mean }: Pick<IMarginOfErrorProps, "mean" | "std">) =>
    (Math.exp(std * std) - 1) * Math.exp(2 * mean - std * std)
);

const logNormalZScore = memoize(
  ({ confidence, mean, std }: Omit<IMarginOfErrorProps, "samples">) =>
    Math.exp(mean + math.SQRT2 * std * std * erfinv(2 * confidence - 1))
);

const zScores = {
  0.68: 0.99445788321,
  0.9: 1.644853626951,
  0.95: 1.95996398454,
  0.98: 2.326347874041,
  0.99: 2.575829303549,
  0.995: 2.807033768344,
  0.997: 2.967737925342,
  0.999: 3.290526731492,
  0.9999: 3.890591886413,
  0.99999: 4.417173413469,
  0.999999: 4.891638475699,
  0.9999999: 5.326723886384,
  0.99999999: 5.730728868236,
  0.999999999: 6.109410204869,
};

export const logNormalMarginOfError = ({
  confidence,
  mean,
  std,
  samples,
}: IMarginOfErrorProps) => {
  const variance = logNormalVariance({ mean, std });
  const standardError = Math.sqrt(variance / samples);
  /*
  return logNormalZScore({
    confidence,
    mean,
    std,
  })*Math.sqrt(variance/samples);
  */
  return zScores[confidence as keyof typeof zScores] * standardError;
};

export const getPriceStats = ({
  prices,
  dates,
  confidence = 0.95,
  timestep = "days",
}: {
  confidence?: number;
  prices: number[];
  dates: Array<Date>;
  timestep: "years" | "days";
}) => {
  const timesteps =
    prices.length > 1
      ? getTimesteps({
          endDate: dates[dates.length - 1],
          startDate: dates[0],
          timestep,
        })
      : 1;
  const logReturns = prices.length > 1 ? mapToLogReturns(prices) : [0];
  const meanLogReturn: number = math.sum(logReturns) / timesteps;
  const stdLogReturn: number =
    prices.length > 2
      ? Math.sqrt(
          math.mean(
            map(logReturns, (logReturn, idx) => {
              // Normalize the logReturn so that each value represents continuously compounded rate per timestep
              const timesteps = getTimesteps({
                endDate: dates[idx + 1],
                startDate: dates[idx],
                timestep,
              });
              return (logReturn / timesteps - meanLogReturn) ** 2;
            })
          )
        )
      : 1000; // This is here to make sure we have a bit of false std when we lack proper values;

  const medianReturn = Math.exp(meanLogReturn) - 1;
  const stdReturn = stdLogReturn; // This is an approximation and should likely be improved.
  const moeReturn = logNormalMarginOfError({
    confidence,
    mean: meanLogReturn,
    std: stdLogReturn,
    samples: prices.length - 1,
  });

  return {
    medianReturn,
    stdReturn,
    moeReturn,
    meanLogReturn,
    stdLogReturn,
  };
};

export const mapProjectedPricesToConfidenceBoundedPrices = ({
  projectedPrices,
  originalPrices,
  originalDates,
  samples,
  startDate = moment(),
  timestep = "years",
}: {
  projectedPrices: {
    runs: Array<{
      prices: number[];
    }>;
  };
  originalPrices: number[];
  samples: number;
  originalDates?: Array<moment.Moment | Date>;
  startDate?: moment.Moment | Date;
  timestep?: "years" | "days";
}) => {
  const dates = originalDates
    ? map(originalDates, (date) => moment(date).toDate())
    : map(originalPrices, (_, idx) =>
        moment(startDate).add(idx, timestep).toDate()
      );
  const lowPrices = [...originalPrices];
  const highPrices = [...originalPrices];
  const prices = [...originalPrices];
  const standardDeviationOfPrices = map(originalPrices, () => 0);

  for (
    let sample = originalPrices.length;
    sample < samples + originalPrices.length;
    sample++
  ) {
    const samplePrices: number[] = map(
      projectedPrices.runs,
      `prices.${sample}`
    );
    prices.push(math.round(math.mean(samplePrices), 2));
    lowPrices.push(math.min(samplePrices));
    highPrices.push(math.max(samplePrices));
    standardDeviationOfPrices.push(math.std(samplePrices));
    dates.push(
      moment(startDate)
        .add(sample - originalPrices.length + 1, timestep)
        .toDate()
    );
  }

  return {
    lowPrices,
    highPrices,
    prices,
    dates,
  };
};

export const mapToLogReturns = (prices: number[]) =>
  map(prices.slice(1), (price, idx) => Math.log(price / prices[idx]));
