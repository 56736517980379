import { isNumber } from "lodash";
import moment from "moment";
import React, { useMemo } from "react";
import Plot from "react-plotly.js";
import {
  getPriceStats,
  mapProjectedPricesToConfidenceBoundedPrices,
  sortPrices,
} from "../lib/calculators";
import { pricesAccumulator, simulateMonteCarlo } from "../lib/monteCarlo";
import { CurrencyType } from "../types";

interface IProjectionChartProps {
  originalPrices: number[];
  originalDates: Array<moment.Moment | Date>;
  projectTo: moment.Moment | Date;
  runs?: number;
  interestRate?: number;
  interestRateStd?: number;
  timestep?: "days" | "years";
  currency?: CurrencyType;
}
export const ProjectionChart = ({
  originalPrices,
  originalDates,
  projectTo,
  interestRate,
  interestRateStd,
  currency,
  runs = 500,
  timestep = "days",
}: IProjectionChartProps) => {
  const { dates: ogDates, prices: ogPrices } = useMemo(
    () =>
      sortPrices({
        prices: originalPrices,
        dates: originalDates,
      }),
    [originalDates, originalPrices]
  );

  const { lowPrices, highPrices, prices, dates } = useMemo(() => {
    const startDate = ogDates[ogDates.length - 1];
    const samples = moment(projectTo).diff(startDate, timestep);

    const originalResult = {
      prices: ogPrices,
    };

    const { meanLogReturn, stdLogReturn } = getPriceStats({
      dates: ogDates,
      prices: ogPrices,
      timestep,
    });
    interestRate = isNumber(interestRate) ? interestRate : meanLogReturn;
    interestRateStd = isNumber(interestRateStd)
      ? interestRateStd
      : stdLogReturn;

    const projectedPrices = simulateMonteCarlo({
      runs,
      samples,
      originalResult,
      process: pricesAccumulator,
      mean: interestRate!,
      std: interestRateStd,
    });

    return mapProjectedPricesToConfidenceBoundedPrices({
      projectedPrices,
      timestep,
      originalDates: ogDates,
      originalPrices: originalResult.prices,
      samples,
      startDate: moment(startDate),
    });
  }, [originalPrices, originalDates, projectTo, interestRate, interestRateStd]);

  return (
    <Plot
      layout={{
        paper_bgcolor: "rgb(255,255,255)",
        plot_bgcolor: "rgb(229,229,229)",
        xaxis: {
          gridcolor: "rgb(255,255,255)",
          showgrid: true,
          showline: false,
          autorange: false,
          range: [
            moment
              .max([moment().subtract(1, "year"), moment(dates[0])])
              .toDate(),
            moment(dates[dates.length - 1]).toDate(),
          ],
          showticklabels: true,
          tickcolor: "rgb(127,127,127)",
          ticks: "outside",
          zeroline: false,
        },
        yaxis: {
          title: `${currency || ""}`,
          gridcolor: "rgb(255,255,255)",
          showgrid: true,
          showline: false,
          showticklabels: true,
          tickcolor: "rgb(127,127,127)",
          ticks: "outside",
          tickformat: "$,",
          zeroline: false,
        },
      }}
      data={[
        {
          x: dates,
          y: lowPrices,
          line: { color: "transparent" },
          name: "Low Price",
          showlegend: false,
          type: "scatter" as any,
        },
        {
          x: dates,
          y: prices,
          fill: "tonexty",
          fillcolor: "rgba(0,176,246,0.2)",
          line: { color: "rgb(0,176,246)" },
          /*
        marker: {
          color: "rgb(0,176,246)",
          size: 6,
        },
        mode: "lines+markers",
         */
          mode: "lines",
          name: "Price",
          showlegend: false,
          type: "scatter" as any,
        },
        {
          x: dates,
          y: highPrices,
          fill: "tonexty",
          fillcolor: "rgba(0,176,246,0.2)",
          line: { color: "transparent" },
          name: "High Price",
          showlegend: false,
          type: "scatter" as any,
        },
      ]}
    />
  );
};
