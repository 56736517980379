import { Moment } from "moment";
import { IPortfolio } from "./Portfolio";

export enum PaymentFrequency {
  WEEKLY = "weekly",
  MONTHLY = "monthly",
  ANNUALLY = "annually",
  BI_WEEKLY = "bi-weekly",
}

export interface ICashflow {
  portfolios?: IPortfolio[];
  id?: string;
  title?: string;
  description?: string;
  type?:
    | "loan"
    | "investment"
    | "mortgage"
    | "annual"
    | "basic"
    | "monte-carlo";
  startDate?: string | number | Moment | Date;
  paymentPeriod?: PaymentFrequency | number;
  presentValue: number;
  payment: number;
  numberOfPayments: number;
  futureValue: number;
  interestRate: number;
}

export interface IAnnualCashflow extends ICashflow {
  annualInterestRate: number;
  paymentPeriod: PaymentFrequency;
}

export interface IMortgageCashflow extends IAnnualCashflow {
  askingPrice: number;
  years: number;
  downDeposit: number;
}

export interface IMonteCarloCashflow extends ICashflow {
  // TODO
}
