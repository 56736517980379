/* eslint-disable no-use-before-define */
import FormControl from "@material-ui/core/FormControl";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import * as React from "react";
import { ITransactionFieldMap } from "../mapper";

/**
import styled from 'styled-components';

import useAutocomplete from '@material-ui/lab/useAutocomplete';
import NoSsr from '@material-ui/core/NoSsr';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

const Label = styled('label')`
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
`;

const InputWrapper = styled('div')`
  width: 300px;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    border-color: #40a9ff;
  }

  &.focused {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    font-size: 14px;
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`;

const Tag = styled(({ label, onDelete, ...props }) => (
  <div {...props}>
    <span>{label}</span>
    <CloseIcon onClick={onDelete} />
  </div>
))`
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: #40a9ff;
    background-color: #e6f7ff;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`;

const Listbox = styled('ul')`
  width: 300px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: #fff;
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: #fafafa;
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li[data-focus='true'] {
    background-color: #e6f7ff;
    cursor: pointer;

    & svg {
      color: #000;
    }
  }
`;


  // TODO Parse equation to create defaultValue
  // TODO Add text between tags
  // TODO Add onChange handler with output equation
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: 'customized-hook-demo',
    defaultValue: [fields[0]],
    multiple: true,
    options: fields,
    getOptionLabel: (field) => field,
  });

  return (
    <NoSsr>
      <div>
        <div {...getRootProps()}>
          {title && <Label {...getInputLabelProps()}>{title}</Label>}
          <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''}>
            {map(value, (field, index: number) => (
              <Tag label={field} {...getTagProps({ index })} />
            ))}

            <input {...getInputProps()} />
          </InputWrapper>
        </div>
        {groupedOptions.length > 0 ? (
          <Listbox {...getListboxProps()}>
            {map(groupedOptions, (field, index) => (
              <li {...getOptionProps({ option: field, index })}>
                <span>{field}</span>
                <CheckIcon fontSize="small" />
              </li>
            ))}
          </Listbox>
        ) : null}
      </div>
    </NoSsr>
  );
}
   */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);

export interface IEquationProps {
  fields: string[];
  fieldMap: ITransactionFieldMap;
  onChange: (state: ITransactionFieldMap) => void;
  fieldType?:
    | "text"
    | "date"
    | "numeric"
    | "currency"
    | "boolean"
    | "datetime"
    | "time";
  title?: string;
}

export default function Equation({
  fields,
  fieldMap,
  fieldType,
  title,
  onChange,
}: IEquationProps) {
  const classes = useStyles();

  const handleChange =
    (key: keyof ITransactionFieldMap) =>
    (event: React.ChangeEvent<HTMLInputElement>) =>
      onChange({
        ...fieldMap,
        [key]: event.target.value,
      });

  return (
    <form className={classes.container}>
      <FormControl className={classes.formControl}>
        <TextField
          id="equation-input"
          type="text"
          value={fieldMap.equation}
          onChange={handleChange("equation")}
        />
        {fieldMap.defaultValue !== undefined && (
          <TextField
            id="default-input"
            label="Default"
            type={fieldType}
            value={fieldMap.defaultValue}
            onChange={handleChange("defaultValue")}
          />
        )}
      </FormControl>
    </form>
  );
}
