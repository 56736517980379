import { makeVar, useReactiveVar } from "@apollo/client";
import { filter, keys, map, sortBy, sortedIndexBy, uniq } from "lodash";
import moment from "moment";
import { useEffect, useMemo } from "react";
import { useExchangeRates } from "../queries/GetExchangeRates";
import { IExchangeRate } from "../types/ExchangeRate";

type IExchangeRateCache = Record<string, IExchangeRate[]>;
const exchangeRateVar = makeVar<IExchangeRateCache>({});
export const useExchangeRateCache = () => {
  const exchangeRateCache = useReactiveVar(exchangeRateVar);
  return [
    exchangeRateCache,
    (exchangeRateCache: IExchangeRateCache) =>
      exchangeRateVar(exchangeRateCache),
  ] as [IExchangeRateCache, (exchangeRateCache: IExchangeRateCache) => void];
};
export const getExchangeRates = (currency: string) => {
  const exchangeRateCache = exchangeRateVar();
  return exchangeRateCache[currency];
};

export interface IUseEquivalentAmountOptions<TItem, TResult = any> {
  getCurrency: (item: TItem) => string;
  getDate: (item: TItem) => number | string | Date | moment.Moment;
  getResult: (item: TItem, exchangeRate: IExchangeRate) => TResult;
  currency?: string;
}

export const useEquivalentAmount = <TItem, TResult>(
  items: TItem[] | undefined,
  {
    currency = "CAD",
    getCurrency,
    getDate,
    getResult,
  }: IUseEquivalentAmountOptions<TItem, TResult>
) => {
  const [exchangeRateCache, setExchangeRateCache] = useExchangeRateCache();
  const { getExchangeRates, exchangeRates, ...exchangeResults } =
    useExchangeRates({} as any, { lazy: true });

  const currenciesNeeded = useMemo(
    () =>
      filter(
        uniq(map(items, getCurrency)),
        (cur) => ![currency, ...keys(exchangeRateCache)].includes(cur)
      ),
    [items]
  );
  const currentCurrency = useMemo(
    () => currenciesNeeded.length && currenciesNeeded[0],
    [currenciesNeeded.join(":")]
  );
  const equivalentItems = useMemo(() => {
    if (exchangeRateCache) {
      return map(items, (item) => {
        const exchangeRates = exchangeRateCache[getCurrency(item)];
        if (exchangeRates) {
          const exchangeRateIndex =
            sortedIndexBy(
              exchangeRates,
              { date: getDate(item) } as IExchangeRate,
              (exchangeRate) => moment(exchangeRate.date)
            ) - 1;
          if (
            exchangeRateIndex > -1 &&
            exchangeRateIndex < exchangeRates.length
          ) {
            const exchangeRate = exchangeRates[exchangeRateIndex];
            return getResult(item, exchangeRate);
          }
        }
        return item;
      });
    } else {
      return items;
    }
  }, [items, exchangeRateCache]);

  useEffect(() => {
    if (
      currentCurrency &&
      (!exchangeRateCache || !exchangeRateCache[currentCurrency])
    ) {
      getExchangeRates({
        variables: {
          input: {
            toSymbol: currency,
            fromSymbol: currentCurrency,
            outputsize: "full",
          },
        },
      });
    }
  }, [currentCurrency, exchangeRateCache]);

  useEffect(() => {
    if (exchangeRates && currentCurrency) {
      setExchangeRateCache({
        ...exchangeRateCache,
        [currentCurrency]: sortBy(exchangeRates, "date"),
      });
    }
  }, [exchangeRates]);

  return {
    items: equivalentItems,
    loading: exchangeResults.loading,
    error: exchangeResults.error,
  };
};
