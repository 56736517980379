import { TextField } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      minWidth: 200,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);

export interface IFormProps<TData> {
  data: TData;
  onChange: (data: TData) => void;
}

export interface ILoginVariables {
  email: string;
  password: string;
}

export const LoginForm = ({ data, onChange }: IFormProps<ILoginVariables>) => {
  const classes = useStyles();
  const handleChange =
    (field: keyof ILoginVariables) =>
    (event: React.ChangeEvent<HTMLInputElement>) =>
      onChange({
        ...data,
        [field]: event.target.value,
      });

  return (
    <form className={classes.container}>
      <FormControl className={classes.formControl}>
        <TextField
          className={classes.textField}
          id="login-email-input"
          label="Email"
          type="email"
          value={data.email}
          onChange={handleChange("email")}
        />
        <TextField
          className={classes.textField}
          id="login-password-input"
          label="Password"
          type="password"
          value={data.password}
          onChange={handleChange("password")}
        />
      </FormControl>
    </form>
  );
};
