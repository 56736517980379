import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { print } from "graphql/language/printer";
import { useEffect } from "react";
import { TRANSACTION_FRAGMENT } from "../fragments/Transaction";
import { defragment } from "../lib/graphqlTools";
import { ITransaction } from "../types/Transaction";

export interface IDateRangeInput {
  from?: Date;
  to?: Date;
}

export interface IGetTransactionsInput {
  accountId?: string;
  accountIds?: string[];
  date?: IDateRangeInput;
}

export const GET_TRANSACTIONS = gql`
  ${defragment(`
    ${print(TRANSACTION_FRAGMENT)}
    query GetTransactions($input: GetTransactionsInput!) {
      transactions(input: $input) {
        ...TransactionFragment
      }
  }`)}
`;

export const useTransactions = (
  input: IGetTransactionsInput,
  { lazy = false }
) => {
  const [getTransactions, { called, loading, data, error }] = useLazyQuery<{
    transactions: ITransaction[];
  }>(GET_TRANSACTIONS, { variables: { input } });
  useEffect(() => {
    if (!lazy && !called) {
      getTransactions({
        variables: {
          input,
        },
      });
    }
  }, [lazy, called]);

  useEffect(() => {
    getTransactions({
      variables: {
        input,
      },
    });
  }, [input]);

  return {
    called,
    loading,
    error,
    getTransactions,
    transactions: data && data.transactions,
  };
};
