import { Decimal } from "decimal.js";
import palette from "google-palette";
import {
  filter,
  find,
  flatten,
  groupBy,
  keys,
  map,
  reduce,
  reverse,
  sortBy,
  sumBy,
  uniqBy,
} from "lodash";
import moment from "moment";
import { Index, TimeRange, TimeSeries } from "pondjs";
import React, { useMemo } from "react";
import { getEquivalentCashBalance } from "../helpers";
import { useTimerange } from "../hooks/state";
import { IAccount } from "../types/Account";
import { IBalance } from "../types/Balance";
import {
  ChartTitleWithDateRange,
  ITimeSeriesSelection,
  TimeSeriesChart,
} from "./Chart";
import { Progress } from "./Progress";

export type IAccountBalances = Pick<IAccount, "balances" | "name">;

export interface IBalanceChartProps {
  loading: boolean;
  accountBalances: IAccountBalances | IAccountBalances[];
  setSelectedTimeRange?: (timerange?: typeof TimeRange) => void;
  onAccountSelection?: (accountBalances: IAccountBalances | null) => void;
  onTitleClick?: () => void;
}

export interface IExtractPointsOptions {
  convertToCash?: boolean;
  extrapolate?: IExtrapolateBalanceOptions;
  period?: moment.unitOfTime.StartOf;
}

const extractPoints = (
  balances: IBalance[],
  { convertToCash = true, extrapolate, period }: IExtractPointsOptions = {}
) => {
  let refinedBalances = convertToCash
    ? convertBalancesToCash(balances)
    : balances;
  refinedBalances = period
    ? combineBalances(refinedBalances, period)
    : refinedBalances;
  refinedBalances = extrapolate
    ? extrapolateBalances(refinedBalances, extrapolate)
    : refinedBalances;
  return map(sortBy(refinedBalances, "date"), (balance) => [
    Index.getDailyIndexString(new Date(balance.date)),
    balance.balance,
  ]);
};

const convertBalancesToCash = (balances: IBalance[]) => {
  const balancesByDate = groupBy(balances, "date");
  return map(keys(balancesByDate).sort(), (date: string) => {
    const balancesOnDate = balancesByDate[date];
    return {
      date,
      // TODO Prefer isManual===true values when doing uniqBy
      balance: sumBy(
        uniqBy(reverse(sortBy(balancesOnDate, ["sortOrder", "id"])), "asset"),
        (balance) => getEquivalentCashBalance(balance)
      ),
    } as unknown as IBalance;
  });
};

const combineBalances = (
  balances: IBalance[],
  period: moment.unitOfTime.StartOf = "day"
) => {
  return sortBy(
    map(
      groupBy(balances, (balance) =>
        moment(balance.date).startOf(period).format("YYYY-MM-DD")
      ),
      (balancesInPeriod, date) => {
        return {
          date,
          balance: sumBy(
            uniqBy(
              reverse(sortBy(balancesInPeriod, ["sortOrder", "id"])),
              "asset"
            ),
            "balance"
          ),
        } as unknown as IBalance;
      }
    ),
    ["date"]
  );
};

export interface IExtrapolateBalanceOptions {
  startDate: Date | string | moment.Moment;
  endDate: Date | string | moment.Moment;
}

const extrapolateBalances = (
  balances: IBalance[],
  { startDate, endDate }: IExtrapolateBalanceOptions
) => {
  if (balances.length === 0) {
    return balances;
  }
  const sortedBalances = sortBy(balances, "date");
  const firstBalance = sortedBalances[0];
  const lastBalance = sortedBalances[sortedBalances.length - 1];
  if (moment(endDate).isAfter(lastBalance.date)) {
    sortedBalances.push({
      date: moment(endDate).toDate(),
      balance: lastBalance.balance,
    } as unknown as IBalance);
  }
  if (moment(startDate).isBefore(firstBalance.date)) {
    return [
      {
        date: moment(startDate).toDate(),
        balance: firstBalance.balance,
      } as unknown as IBalance,
      ...sortedBalances,
    ];
  } else {
    return sortedBalances;
  }
};

export default function BalanceChart({
  loading,
  accountBalances,
  onAccountSelection,
  onTitleClick,
}: IBalanceChartProps) {
  const [timerange, setTimerange] = useTimerange();
  const series = useMemo(() => {
    if (!timerange) {
      return [];
    }
    let accountBalancesArray: IAccountBalances[];
    if (!Array.isArray(accountBalances) || accountBalances.length < 2) {
      if (Array.isArray(accountBalances)) {
        accountBalancesArray = accountBalances;
      } else if (accountBalances) {
        accountBalancesArray = [accountBalances];
      } else {
        accountBalancesArray = [];
      }
    } else {
      accountBalancesArray = [...accountBalances];

      const allBalances = sortBy(
        reduce(
          accountBalances,
          (balances, account) =>
            balances.concat(
              map(convertBalancesToCash(account.balances), (balance) => ({
                ...balance,
                account: account as IAccount,
              }))
            ),
          [] as IBalance[]
        ),
        "date"
      );

      const lastBalances: { [accountName: string]: IBalance } = {};
      const combinedBalances = reduce(
        allBalances,
        (balances, balance) => {
          if (balances.length === 0) {
            balances.push({
              ...balance,
              balance: new Decimal(getEquivalentCashBalance(balance)),
            });
          } else {
            const previousBalance = balances[balances.length - 1];
            const lastBalanceFromSameAccount =
              lastBalances[balance.account.name];
            if (balance.date === previousBalance.date) {
              previousBalance.balance = previousBalance.balance.plus(
                getEquivalentCashBalance(balance)
              );
              if (lastBalanceFromSameAccount) {
                previousBalance.balance = previousBalance.balance.minus(
                  getEquivalentCashBalance(lastBalanceFromSameAccount)
                );
              }
            } else {
              balances.push({
                ...balance,
                isManual: false,
                balance: previousBalance.balance
                  .plus(getEquivalentCashBalance(balance))
                  .minus(
                    (lastBalanceFromSameAccount &&
                      getEquivalentCashBalance(lastBalanceFromSameAccount)) ||
                      0
                  ),
              });
            }
          }
          lastBalances[balance.account.name] = balance;
          return balances;
        },
        [] as Array<{ balance: Decimal } & Omit<IBalance, "balance">>
      );

      accountBalancesArray.push({
        name: "Net Balances",
        balances: map(combinedBalances, (balance) => ({
          ...balance,
          balance: balance.balance.toNumber(),
        })),
      });
    }

    const colors = palette("tol-rainbow", accountBalancesArray.length);

    return reduce(
      accountBalancesArray,
      (series, account, i) => {
        const convertToCash = account.name !== "Net Balances";
        const period = account.name === "Net Balances" ? "day" : undefined;
        const points = extractPoints(account.balances, {
          convertToCash,
          period,
        });
        if (points.length > 0) {
          const manualPoints = extractPoints(
            filter(account.balances, (balance) => balance.isManual),
            { convertToCash }
          );
          const lastBalances = flatten(
            map(groupBy(account.balances, "asset"), (balances) => {
              const lastBalance = sortBy(balances, ["date", "sortOrder", "id"])[
                balances.length - 1
              ];
              const endDate = timerange.end().toISOString();
              if (endDate > lastBalance.date) {
                return [
                  lastBalance,
                  {
                    ...lastBalance,
                    date: endDate,
                  },
                ];
              } else {
                return [lastBalance];
              }
            })
          );
          const extrapolatedPoints = extractPoints(lastBalances, {
            convertToCash,
          });

          series.push({
            label: account.name,
            color: `#${colors[i]}`,
            style: {
              normal: {
                stroke: `#${colors[i]}`,
                strokeWidth: 2,
              },
            },
            series: new TimeSeries({
              name: "balance_history",
              columns: ["index", "amount"],
              points,
            }),
            extrapolation: new TimeSeries({
              name: "extrapolated_balance",
              columns: ["index", "amount"],
              points: extrapolatedPoints,
            }),
            manualSeries: new TimeSeries({
              name: "manual_balance_history",
              columns: ["index", "amount"],
              points: manualPoints,
            }),
          });
        }
        return series;
      },
      [] as any[]
    );
  }, [accountBalances, timerange]);

  const handleSelectionChange = useMemo(
    () =>
      ({ series }: ITimeSeriesSelection) => {
        if (onAccountSelection) {
          onAccountSelection(
            (series && find(accountBalances, ["name", series.label])) || null
          );
        }
      },
    [accountBalances, onAccountSelection]
  );

  return (
    <React.Fragment>
      <ChartTitleWithDateRange
        timerange={timerange}
        onTitleClick={onTitleClick}
        onChange={setTimerange}
        title="Balances"
      />
      {(loading && <Progress />) || (
        <TimeSeriesChart
          timerange={timerange}
          series={series}
          yField="amount"
          yFormat="$.0f"
          emptySeriesMessage="No balances found for the given time period"
          onSelectionChange={handleSelectionChange}
        />
      )}
    </React.Fragment>
  );
}
