import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { ICategory } from "../types/Category";

export const GET_CATEGORIES = gql`
  query GetCategories {
    categories {
      category
    }
  }
`;

export const useCategories = () => {
  const { loading, data, error } =
    useQuery<{ categories: ICategory[] }>(GET_CATEGORIES);
  return {
    loading,
    error,
    categories: data && data.categories,
  };
};
