import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AddBox from "@material-ui/icons/AddBox";
import clsx from "clsx";
import {
  filter,
  find,
  isEqual,
  isNumber,
  map,
  mapValues,
  omit,
  pick,
  round,
  sortBy,
  startCase,
} from "lodash";
import { Column, MaterialTableProps } from "material-table";
import moment from "moment";
import { TimeRange } from "pondjs";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { formatCurrency, prettyDate } from "../../helpers";
import {
  calculateCashflow,
  calculateTimeValueOfCashflow,
  getEquivalentCompoundedRate,
  getPaymentPeriodMS,
  getTransactions,
} from "../../lib/calculators";
import {
  IAnnualCashflow,
  IMortgageCashflow,
  PaymentFrequency,
} from "../../types/Cashflow";
import { IPortfolio } from "../../types/Portfolio";
import { ITransaction } from "../../types/Transaction";
import CashflowChart from "../CashflowChart";
import CashflowDialog from "../dialogs/CashflowDialog";
import DialogSelect from "../dialogs/DialogSelect";
import { AnnualizedCashflowForm } from "../forms/AnnualizedCashflowForm";
import { MortgageForm } from "../forms/MortgageForm";
import { ProjectionChart } from "../ProjectionChart";
import { Table } from "../Table";
import Toast, { IToastProps } from "../Toast";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    justifyContent: "space-around",
  },
  item: {
    padding: theme.spacing(2),
    fontSize: 16,
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 480, // Set this based on device size
  },
  chartContainer: {
    paddingTop: theme.spacing(4),
  },
}));

export interface ICashflowDetailsProps {
  cashflow: IAnnualCashflow | IMortgageCashflow;
}

const isMortgage = (cashflow: any): cashflow is IMortgageCashflow =>
  cashflow && cashflow.askingPrice !== void 0;

const CashflowDetails = ({ cashflow }: ICashflowDetailsProps) => {
  switch (cashflow.type) {
    case "mortgage": {
      const { askingPrice, downDeposit, years } = cashflow as IMortgageCashflow;
      return (
        <Grid container style={{ padding: 10 }} spacing={2}>
          <Grid item xs={12} sm={2} md={1} container alignItems="center">
            <Typography color="primary" style={{ fontWeight: "bold" }}>
              Mortgage
            </Typography>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography color="primary" style={{ textAlign: "right" }}>
              Asking Price
            </Typography>
            <div style={{ textAlign: "right" }}>
              {formatCurrency(askingPrice)}
            </div>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography color="primary" style={{ textAlign: "right" }}>
              Down Deposit
            </Typography>
            <div style={{ textAlign: "right" }}>
              {formatCurrency(downDeposit)}
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Typography color="primary" style={{ textAlign: "right" }}>
              Years
            </Typography>
            <div style={{ textAlign: "right" }}>{years}</div>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography color="primary">Notes</Typography>
            <div>{(cashflow as any).notes}</div>
          </Grid>
        </Grid>
      );
    }
    case "loan": {
      return (
        <Grid container style={{ padding: 10 }} spacing={2}>
          <Grid item xs={12} sm={2} md={1} container alignItems="center">
            <Typography color="primary" style={{ fontWeight: "bold" }}>
              Loan
            </Typography>
          </Grid>
          <Grid item xs={12} md={11}>
            <Typography color="primary">Notes</Typography>
            <div>{(cashflow as any).notes}</div>
          </Grid>
        </Grid>
      );
    }
    case "investment": {
      return (
        <Grid container style={{ padding: 10 }} spacing={2}>
          <Grid item xs={12} sm={2} md={1} container alignItems="center">
            <Typography color="primary" style={{ fontWeight: "bold" }}>
              Investment
            </Typography>
          </Grid>
          <Grid item xs={12} md={11}>
            <Typography color="primary">Notes</Typography>
            <div>{(cashflow as any).notes}</div>
          </Grid>
        </Grid>
      );
    }
    default: {
      return (
        <Grid container style={{ padding: 10 }} spacing={2}>
          <Grid item xs={12} sm={2} md={1} container alignItems="center">
            <Typography color="primary" style={{ fontWeight: "bold" }}>
              Annualized Cashflow
            </Typography>
          </Grid>
          <Grid item xs={12} md={11}>
            <Typography color="primary">Notes</Typography>
            <div>{(cashflow as any).notes}</div>
          </Grid>
        </Grid>
      );
    }
  }
};

const DEFAULT_CASHFLOW = {
  title: "untitled",
  description: "",
  presentValue: 0,
  futureValue: "",
  numberOfPayments: 0,
  payment: 0,
  interestRate: 0,
};

export interface IBasicCalculatorProps {
  cashflow?: IAnnualCashflow;
  saveCashflow: (cashflow: IAnnualCashflow) => void;
}
export const BasicCashflowCalculator = ({
  cashflow,
  saveCashflow,
}: IBasicCalculatorProps) => {
  const classes = useStyles();

  const [toast, setToast] = useState<IToastProps>({
    severity: "success",
  });

  const [state, setState] = useState<Record<string, string | number | any>>({
    ...DEFAULT_CASHFLOW,
    ...cashflow,
  });

  useEffect(() => {
    setState({
      ...DEFAULT_CASHFLOW,
      ...cashflow,
    });
  }, [cashflow]);

  const handleClickCompute = (evt: any) => {
    try {
      const relevantFields = [
        "futureValue",
        "interestRate",
        "numberOfPayments",
        "payment",
        "presentValue",
      ];
      const missingKeys = filter(
        relevantFields,
        (key) => state[key] === null || state[key] === ""
      );
      if (missingKeys.length === 1) {
        const cashflow = calculateCashflow(
          mapValues(
            omit(pick(state, relevantFields), missingKeys[0]),
            (val, key) => parseFloat(val) / (key === "interestRate" ? 100 : 1)
          )
        );
        const newCashflow = {
          ...state,
          ...cashflow,
          interestRate: round(cashflow.interestRate * 100, 4),
        } as any;
        setState(newCashflow);
        setToast({
          message: `Successfully calculated the ${missingKeys[0]}`,
          severity: "success",
        });
        saveCashflow({
          type: "basic",
          startDate: new Date(),
          paymentPeriod: PaymentFrequency.MONTHLY,
          ...newCashflow,
        });
      } else if (missingKeys.length === 0) {
        const cashflow = calculateCashflow(
          mapValues(
            omit(state, "futureValue"),
            (val, key) =>
              parseFloat(val as any) / (key === "interestRate" ? 100 : 1)
          )
        );
        if (cashflow.futureValue !== parseFloat(state.futureValue as any)) {
          setToast({
            message:
              "The current values are invalid. Please clear one and re-compute.",
            severity: "warning",
          });
        } else {
          setToast({
            message: `Nothing to do. The current values are all valid!`,
            severity: "success",
          });
        }
      } else {
        setToast({
          message:
            "Not enough info! Please fill in more of the inputs. All but one is needed.",
          severity: "warning",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = useCallback(
    (property) => (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
      setState({
        ...state,
        [property]: evt.target.value,
      });
    },
    [state]
  );

  return (
    <Paper className={classes.paper}>
      <Toast {...toast} />
      <Grid container className={classes.container}>
        <FormControl className={classes.item}>
          <InputLabel htmlFor="standard-text">Title</InputLabel>
          <Input
            id="standard-text"
            value={state.title || "untitled"}
            onFocus={(event) => event.target.select()}
            onChange={handleChange("title")}
          />
        </FormControl>
        <FormControl className={classes.item}>
          <InputLabel htmlFor="standard-adornment-amount">
            Present Value
          </InputLabel>
          <Input
            id="standard-adornment-amount"
            value={state.presentValue}
            onChange={handleChange("presentValue")}
            onFocus={(event) => event.target.select()}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          />
        </FormControl>
        <FormControl className={classes.item}>
          <InputLabel htmlFor="standard-adornment-amount">
            Future Value
          </InputLabel>
          <Input
            id="standard-adornment-amount"
            value={state.futureValue}
            onChange={handleChange("futureValue")}
            onFocus={(event) => event.target.select()}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          />
        </FormControl>
        <FormControl className={classes.item}>
          <InputLabel htmlFor="standard-adornment-amount">Payment</InputLabel>
          <Input
            id="standard-adornment-amount"
            value={state.payment}
            onChange={handleChange("payment")}
            onFocus={(event) => event.target.select()}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          />
        </FormControl>
        <FormControl className={classes.item}>
          <InputLabel htmlFor="standard-percent">Interst Rate</InputLabel>
          <Input
            id="standard-percent"
            value={state.interestRate}
            onChange={handleChange("interestRate")}
            onFocus={(event) => event.target.select()}
            endAdornment={<InputAdornment position="end">%</InputAdornment>}
          />
        </FormControl>
        <FormControl className={classes.item}>
          <InputLabel htmlFor="standard-number">Number of Payments</InputLabel>
          <Input
            id="standard-number"
            value={state.numberOfPayments}
            onFocus={(event) => event.target.select()}
            onChange={handleChange("numberOfPayments")}
          />
        </FormControl>
        <Grid item xs={12} sm={8}>
          <FormControl className={classes.item}>
            <InputLabel htmlFor="standard-text">Description</InputLabel>
            <Input
              id="standard-text"
              value={state.description}
              onFocus={(event) => event.target.select()}
              onChange={handleChange("description")}
            />
          </FormControl>
        </Grid>
        <Grid className={classes.item} item xs={6} sm={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickCompute}
          >
            Compute
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export const BasicAnnualCashflowCalculator = ({
  cashflow,
  saveCashflow,
}: IBasicCalculatorProps) => {
  const classes = useStyles();
  const handleSubmit = (cashflow: IAnnualCashflow) => {
    saveCashflow({
      type: "annual",
      ...cashflow,
    });
  };
  return (
    <Paper className={classes.paper}>
      <AnnualizedCashflowForm
        showCompute
        initialValue={cashflow}
        onSubmit={handleSubmit}
      />
    </Paper>
  );
};

export const MortgageCashflowCalculator = ({
  cashflow,
  saveCashflow,
}: IBasicCalculatorProps) => {
  const classes = useStyles();
  const handleSubmit = (newCashflow: IAnnualCashflow) => {
    saveCashflow({
      type: "mortgage",
      ...cashflow,
      ...newCashflow,
    });
  };
  return (
    <Paper className={classes.paper}>
      <MortgageForm
        showCompute
        initialValue={cashflow as IMortgageCashflow}
        onSubmit={handleSubmit}
      />
    </Paper>
  );
};

export const MonteCarloCashflowCalculator = ({
  cashflow,
  saveCashflow,
}: IBasicCalculatorProps) => {
  const classes = useStyles();

  const [toast, setToast] = useState<IToastProps>({
    severity: "success",
  });

  const [state, setState] = useState<Record<string, string | number | any>>({
    ...DEFAULT_CASHFLOW,
    interestRateStd: 0,
    title: "",
    ...cashflow,
  });

  useEffect(() => {
    setState({
      ...DEFAULT_CASHFLOW,
      interestRateStd: 0,
      title: "",
      ...cashflow,
    });
  }, [cashflow]);

  const handleClickCompute = (evt: any) => {
    try {
      const relevantFields = [
        "interestRate",
        "interestRateStd",
        "numberOfPayments",
        "presentValue",
      ];
      const missingKeys = filter(
        relevantFields,
        (key) => state[key] === null || state[key] === ""
      );
      if (missingKeys.length === 0) {
        const cashflow = {
          ...state,
          // futureValue: prices[prices.length - 1],
        };
        saveCashflow(cashflow as IAnnualCashflow);
        /*
        if (cashflow.futureValue !== parseFloat(state.futureValue as any)) {
          setToast({
            message:
              "The current values are invalid. Please clear one and re-compute.",
            severity: "warning",
          });
        } else {
          setToast({
            message: `Nothing to do. The current values are all valid!`,
            severity: "success",
          });
        }
         */
      } else {
        setToast({
          message:
            "Not enough info! Please fill in more of the inputs. All but one is needed.",
          severity: "warning",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = useCallback(
    (property) => (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
      setState({
        ...state,
        [property]: evt.target.value,
      });
    },
    [state]
  );

  // TODO change input of interest rate to be a simple Annual ROI and convert it into a continuous rate for internal use
  // TODO cleanup other input fields to be user friendly

  return (
    <Paper className={classes.paper}>
      <Toast {...toast} />
      (Beta) Monte Carlo Simulator
      <Grid container className={classes.container}>
        <FormControl className={classes.item}>
          <InputLabel htmlFor="standard-text">Title</InputLabel>
          <Input
            id="standard-text"
            value={state.title || "untitled"}
            onFocus={(event) => event.target.select()}
            onChange={handleChange("title")}
          />
        </FormControl>
        <FormControl className={classes.item}>
          <InputLabel htmlFor="standard-adornment-amount">
            Present Value
          </InputLabel>
          <Input
            id="standard-adornment-amount"
            value={state.presentValue}
            onChange={handleChange("presentValue")}
            onFocus={(event) => event.target.select()}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          />
        </FormControl>
        <FormControl className={classes.item}>
          <InputLabel htmlFor="standard-percent">
            Average Interest Rate
          </InputLabel>
          <Input
            id="standard-percent"
            value={state.interestRate}
            onChange={handleChange("interestRate")}
            onFocus={(event) => event.target.select()}
            endAdornment={<InputAdornment position="end">%</InputAdornment>}
          />
        </FormControl>
        <FormControl className={classes.item}>
          <InputLabel htmlFor="standard-adornment-amount">
            Standard Deviation
          </InputLabel>
          <Input
            id="standard-adornment-amount"
            value={state.interestRateStd}
            onChange={handleChange("interestRateStd")}
            onFocus={(event) => event.target.select()}
            endAdornment={<InputAdornment position="end">%</InputAdornment>}
          />
        </FormControl>
        <FormControl className={classes.item}>
          <InputLabel htmlFor="standard-number">Number of Payments</InputLabel>
          <Input
            id="standard-number"
            value={state.numberOfPayments}
            onFocus={(event) => event.target.select()}
            onChange={handleChange("numberOfPayments")}
          />
        </FormControl>
        <Grid item xs={12} sm={8}>
          <FormControl className={classes.item}>
            <InputLabel htmlFor="standard-text">Description</InputLabel>
            <Input
              id="standard-text"
              value={state.description}
              onFocus={(event) => event.target.select()}
              onChange={handleChange("description")}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <ProjectionChart
            originalPrices={[parseFloat(state.presentValue)]}
            originalDates={[moment()]}
            projectTo={moment().add(parseInt(state.numberOfPayments), "years")}
            interestRate={parseFloat(state.interestRate) / 100}
            interestRateStd={parseFloat(state.interestRateStd) / 100}
          />
        </Grid>
        <Grid className={classes.item} item xs={6} sm={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickCompute}
          >
            Compute
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

const cashflowTypes = [
  {
    label: "Investment",
    type: "investment",
  },
  {
    label: "Loan",
    type: "loan",
  },
  {
    label: "Mortgage",
    type: "mortgage",
  },
  {
    label: "Annuity",
    type: "annuity",
    disabled: true,
  },
  {
    label: "Asset",
    type: "asset",
    disabled: true,
  },
  {
    label: "Liability",
    type: "liability",
    disabled: true,
  },
];

const parentId = uuidv4();

const DEFAULT_PORTFOLIO: IPortfolio = {
  title: "",
  description: "",
  cashflows: [],
};

export const PortfolioBuilder = ({
  portfolio,
  deleteCashflow,
  saveCashflow,
  savePortfolio,
}: {
  portfolio?: IPortfolio;
  deleteCashflow: (cashflow: IAnnualCashflow | IMortgageCashflow) => void;
  saveCashflow: (cashflow: IAnnualCashflow | IMortgageCashflow) => void;
  savePortfolio: (portfolio: IPortfolio) => Promise<IPortfolio>;
}) => {
  const classes = useStyles();
  const [timerange, setTimerange] = useState<typeof TimeRange | null>(null);
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [toast, setToast] = useState<IToastProps>({
    severity: "success",
  });
  const [state, setState] = useState(portfolio || DEFAULT_PORTFOLIO);
  const [loading, setLoading] = useState(false);
  const [cashflowType, setCashflowType] = useState<any>(null);
  const tomorrow = useMemo(() => moment().startOf("day").add(1, "day"), []);
  const transactions = useMemo(() => {
    setLoading(true);
    const transactions = getTransactions(
      state.cashflows as Array<Required<IAnnualCashflow>>
    ) as ITransaction[];
    setLoading(false);
    return transactions;
  }, [state]);

  useEffect(() => {
    setState(portfolio || DEFAULT_PORTFOLIO);
  }, [portfolio]);
  useEffect(() => {
    const dates = sortBy(map(transactions, "date"), (date) =>
      moment(date).toISOString()
    );
    if (dates.length > 0) {
      let startDate: any = dates[0];
      let endDate: any = dates[dates.length - 1];
      if (endDate && startDate) {
        startDate = moment(startDate);
        endDate = moment(endDate);
        if (moment(startDate).isBefore(endDate)) {
          setTimerange(new TimeRange(startDate, endDate));
        }
      }
    }
  }, [transactions]);

  const editable: MaterialTableProps<Required<IAnnualCashflow>>["editable"] = {
    onRowDelete: async (cashflow: Required<IAnnualCashflow>) => {
      setState({
        ...state,
        cashflows: filter(state.cashflows, (cf) => cf !== cashflow),
      });
      deleteCashflow(cashflow);
    },
  };

  const [isCashflowModalOpen, setIsCashflowModalOpen] = useState(false);
  const openCashflowModal = useCallback(() => {
    setIsCashflowModalOpen(true);
  }, []);
  const closeCashflowModal = useCallback(() => {
    setIsCashflowModalOpen(false);
  }, []);

  const actions: MaterialTableProps<Required<IAnnualCashflow>>["actions"] = [
    {
      icon: () => <AddBox />,
      tooltip: "Add Cashflow",
      isFreeAction: true,
      onClick: openCashflowModal,
    },
  ];

  const [inflationRate, setInflationRate] = useState(0.02);

  const columns: Column<IAnnualCashflow>[] = [
    {
      field: "startDate",
      title: "Start Date",
      type: "date",
      ...(true && ({ width: 130 } as object)),
      render: (cashflow) =>
        cashflow.startDate && prettyDate(cashflow.startDate),
    },
    {
      // field: 'netPresentValue',
      title: "Net Present Value",
      render: (cashflow) =>
        formatCurrency(
          calculateTimeValueOfCashflow(
            cashflow as any,
            getEquivalentCompoundedRate(
              inflationRate,
              getPaymentPeriodMS(PaymentFrequency.ANNUALLY),
              getPaymentPeriodMS(cashflow.paymentPeriod)
            ),
            tomorrow
          )
        ),
      type: "currency",
    },
    {
      field: "presentValue",
      title: "Present Value",
      render: (cashflow) => formatCurrency(cashflow.presentValue),
      type: "currency",
    },
    {
      field: "payment",
      title: "Payment",
      render: (cashflow) => formatCurrency(cashflow.payment),
      type: "currency",
    },
    {
      field: "paymentPeriod",
      title: "Payment Period",
      render: (cashflow) =>
        isNumber(cashflow.paymentPeriod)
          ? `${
              Math.round((cashflow.paymentPeriod / (60 * 60 * 1000)) * 10) / 10
            } hr`
          : cashflow.paymentPeriod,
      type: "currency",
    },
    {
      field: "numberOfPayments",
      title: "Number of Payments",
      type: "numeric",
    },
    {
      field: "interestRate",
      title: "Interest Rate /period",
      render: (cashflow) =>
        `${Math.round(cashflow.interestRate * 10000) / 100}%`,
      type: "numeric",
    },
    {
      field: "annualInterestRate",
      title: "Interest Rate /yr",
      render: (cashflow) =>
        `${Math.round(cashflow.annualInterestRate * 10000) / 100}%`,
      type: "numeric",
    },
    {
      field: "futureValue",
      title: "Future Value",
      render: (cashflow) => formatCurrency(cashflow.futureValue),
    },
  ];

  const handleSelectCashflowType = useCallback((cashflowType: any) => {
    setCashflowType(cashflowType);
    closeCashflowModal();
  }, []);

  const handleSavePortfolio = () => {
    if (!isEqual(state, portfolio)) {
      savePortfolio(state);
    }
  };
  const handleChangeTitle = (
    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const tail = evt.target.value.match(/[ \t]+$/);
    const newPortfolio = {
      ...state,
      title: startCase(evt.target.value) + (tail ? tail[0] : ""),
    };
    setState(newPortfolio);
  };

  const handleUpsertCashflow = useCallback(
    async (newCashflow: Required<IAnnualCashflow>) => {
      const cashflows = [...state.cashflows];
      const cfIndex = cashflows.findIndex(
        (cashflow: any) => cashflow.id === (newCashflow as any).id
      );
      if (cfIndex === -1) {
        cashflows.push({
          ...newCashflow,
          id: uuidv4(),
          parentId: uuidv4(),
        });
      } else {
        cashflows[cfIndex] = {
          ...cashflows[cfIndex],
          ...newCashflow,
        };
      }

      setState({ ...state, cashflows });
      let portfolioId = state.id;
      if (!portfolioId) {
        const portfolio = await savePortfolio(state);
        portfolioId = portfolio.id;
      }
      saveCashflow({
        ...newCashflow,
        portfolios: [
          {
            id: portfolioId,
          } as IPortfolio,
        ],
      });
      handleCloseCashflowDialog();
    },
    [state]
  );

  const handleCloseCashflowDialog = useMemo(
    () => () => {
      setCashflowType(null);
      setCashflowToEdit(undefined);
    },
    []
  );

  const [cashflowToEdit, setCashflowToEdit] = useState<
    Required<IAnnualCashflow> | undefined
  >();

  return (
    <React.Fragment>
      <DialogSelect
        isOpen={isCashflowModalOpen}
        title="Select a Cashflow Type"
        label="Cashflow"
        noneTitle="...Pick a cashflow"
        selections={cashflowTypes}
        displayField="label"
        onSubmit={handleSelectCashflowType}
        onClose={() => closeCashflowModal()}
      />
      <CashflowDialog
        isOpen={!!cashflowType || !!cashflowToEdit}
        initialValue={cashflowToEdit}
        type={
          (cashflowType && cashflowType.type) ||
          (cashflowToEdit && (cashflowToEdit as any).type) ||
          ""
        }
        onSubmit={handleUpsertCashflow}
        onClose={handleCloseCashflowDialog}
      />
      <Toast {...toast} />
      <Container maxWidth="xl" className={classes.chartContainer}>
        <Paper style={{ overflow: "visible" }} className={fixedHeightPaper}>
          <CashflowChart
            maxDate={timerange && timerange.end()}
            timerange={timerange}
            setTimerange={setTimerange}
            transactions={transactions}
            loading={loading}
          />
        </Paper>
      </Container>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          {/* Cashflows */}
          <Grid item xs={12}>
            <Table
              title={
                <Input
                  id="standard-text"
                  value={state.title || "Untitled Portfolio"}
                  disableUnderline
                  fullWidth
                  style={{
                    fontWeight: 600,
                    fontSize: 18,
                  }}
                  onFocus={(event) => event.target.select()}
                  onBlur={handleSavePortfolio}
                  onChange={handleChangeTitle}
                />
              }
              columns={columns}
              data={state.cashflows}
              localization={{
                body: {
                  emptyDataSourceMessage: "Add a cashflow to get started!",
                  editRow: {
                    deleteText:
                      "⚠️  Are you sure you want to delete this cashflow?",
                  },
                },
              }}
              options={{
                emptyRowsWhenPaging: false,
                search: false,
                addRowPosition: "first",
                padding: "dense",
                pageSize: 3,
                pageSizeOptions: [3, 5, 10],
              }}
              editable={editable}
              actions={actions}
              detailPanel={(cashflow) => {
                return <CashflowDetails cashflow={cashflow} />;
              }}
              onRowClick={(event, rowData, togglePanel) => {
                const cashflow = find(state.cashflows, ["id", rowData.id]);
                console.log({
                  cashflow,
                  rowData,
                });
                if (cashflow) {
                  setCashflowToEdit((cashflow as any) || rowData);
                }
              }}
              // parentChildData={(row, rows) => rows.find(a => a.id === row.parentId && a !== row )}
            />
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};
