import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  filter,
  find,
  forEach,
  groupBy,
  map,
  pick,
  sortBy,
  values,
} from "lodash";
import React, { useMemo, useState } from "react";
import { formatCurrency, getBalanceStyle, prettyDate } from "../../helpers";
import { useDeleteTransactions } from "../../mutations/DeleteTransactions";
import { useDuplicateTransactions } from "../../queries/GetDuplicateTransactions";
import { IAccount } from "../../types/Account";
import { ITransaction, TransactionType } from "../../types/Transaction";
import { Progress } from "../Progress";

export interface IDeDuplicateTransactionDialogProps {
  isOpen: boolean;
  accounts?: IAccount[];
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  left: {
    textAlign: "start",
  },
  right: {
    textAlign: "end",
  },
  checkbox: {
    padding: 0,
  },
}));

export interface IDeDuplicateTransactionsProps {
  transactions: ITransaction[];
  selectedTransactionsById: {
    [transactionId: string]: boolean;
  };
  setSelectedTransactionsById: (selectedTransactionsById: {
    [transactionId: string]: boolean;
  }) => void;
}

const selectOriginalTransaction = (duplicates: ITransaction[]) => {
  return (
    find(duplicates, (transaction) => !!transaction.plaidTransactionId) ||
    duplicates[0]
  );
};

const DUPLICATE_TRANSACTION_FIELDS = ["date", "amount", "type", "description"];
const DuplicateTransactions = ({
  transactions,
  selectedTransactionsById,
  setSelectedTransactionsById,
}: IDeDuplicateTransactionsProps) => {
  const classes = useStyles();

  const groupedDuplicates = useMemo(() => {
    const selectedTransactions: any = {};
    const groupedDuplicates = map(
      groupBy(
        sortBy(transactions, ["accountId", ...DUPLICATE_TRANSACTION_FIELDS]),
        "accountId"
      ),
      (accountTransactions) => {
        const duplicates = {
          account: accountTransactions[0].account,
          duplicates: groupBy(accountTransactions, (transaction) =>
            values(pick(transaction, DUPLICATE_TRANSACTION_FIELDS)).join("-")
          ),
        };
        forEach(duplicates.duplicates, (transactions) => {
          const originalTransaction = selectOriginalTransaction(transactions);
          forEach(transactions, (transaction) => {
            if (transaction.id !== originalTransaction.id) {
              selectedTransactions[transaction.id] = true;
            }
          });
        });
        return duplicates;
      }
    );

    setSelectedTransactionsById(selectedTransactions);
    return groupedDuplicates;
  }, [transactions]);

  return (
    <Box>
      {map(groupedDuplicates, ({ account, duplicates }) => {
        return (
          <Box key={account.id}>
            <Typography variant="h6" color="primary" className={classes.left}>
              {account.name}
            </Typography>
            <Box>
              <Grid container>
                <Grid item xs={1}></Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle2">Date</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="subtitle2">Description</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="subtitle2" className={classes.right}>
                    Amount
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="subtitle2" className={classes.right}>
                    Count
                  </Typography>
                </Grid>
              </Grid>
              {map(duplicates, (transactions, key) => {
                const amount =
                  transactions[0].type === TransactionType.credit
                    ? transactions[0].amount
                    : -transactions[0].amount;
                const isSelected = !!find(
                  transactions,
                  (transaction) => selectedTransactionsById[transaction.id]
                );
                return (
                  <Grid key={key} container>
                    <Grid item xs={1}>
                      <Checkbox
                        classes={{ root: classes.checkbox }}
                        size="small"
                        checked={isSelected}
                        onChange={(evt) => {
                          evt.stopPropagation();
                          const selectedTransactions = {
                            ...selectedTransactionsById,
                          };
                          if (isSelected) {
                            forEach(transactions, (transaction) => {
                              selectedTransactions[transaction.id] = false;
                            });
                          } else {
                            const originalTransaction =
                              selectOriginalTransaction(transactions);
                            forEach(transactions, (transaction) => {
                              if (transaction.id !== originalTransaction.id) {
                                selectedTransactions[transaction.id] = true;
                              }
                            });
                          }
                          setSelectedTransactionsById(selectedTransactions);
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Typography>
                        {prettyDate(transactions[0].date)}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography>{transactions[0].description}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        style={getBalanceStyle(amount)}
                        className={classes.right}
                      >
                        {formatCurrency(amount)}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography className={classes.right}>
                        {transactions.length}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default function DeDuplicateTransactionDialog({
  accounts,
  isOpen,
  onClose,
}: IDeDuplicateTransactionDialogProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [selectedTransactionsById, setSelectedTransactionsById] = useState<any>(
    {}
  );
  const { getDuplicates, duplicates, loading, error } =
    useDuplicateTransactions({}, { isLazy: true });
  const {
    deleteTransactions,
    loading: deleting,
    error: deleteError,
  } = useDeleteTransactions();

  const fetchDuplicates = () => {
    getDuplicates({
      variables: {
        input: {
          accountIds: accounts ? map(accounts, "id") : undefined,
        },
      },
    });
  };

  const handleSubmit = () => {
    const transactionsToRemove = filter(
      duplicates,
      (transaction) => selectedTransactionsById[transaction.id]
    );
    console.log(
      `Deleting ${transactionsToRemove.length} out of the original ${
        duplicates && duplicates.length
      } transactions`
    );
    console.log("TODO delete all selected duplicates");
    deleteTransactions({
      variables: {
        ids: map(transactionsToRemove, "id"),
      },
    });
    onClose();
  };

  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={!!(error || deleteError)}
        autoHideDuration={2500}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={
          <span id="message-id">
            {(error && error.message) || (deleteError && deleteError.message)}
          </span>
        }
      />
      <Dialog
        fullScreen={fullScreen}
        disableBackdropClick
        disableEscapeKeyDown
        open={isOpen}
        onClose={onClose}
        onEnter={fetchDuplicates}
      >
        <DialogTitle>Duplicate Transactions</DialogTitle>
        <DialogContent>
          {loading || !duplicates ? (
            <Progress />
          ) : (
            <DuplicateTransactions
              selectedTransactionsById={selectedTransactionsById}
              setSelectedTransactionsById={setSelectedTransactionsById}
              transactions={duplicates}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary">
            Deduplicate
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

// DeDuplicateTransactionDialog.whyDidYouRender = true;
