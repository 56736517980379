import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { TRANSACTION_BASE_FRAGMENT } from "../fragments/Transaction";
import { ITransfer } from "../types/Transfer";

export interface IDeleteTransferInput {
  id?: string;
}

export const DELETE_TRANSFERS = gql`
  ${TRANSACTION_BASE_FRAGMENT}
  mutation DeleteTransfer($id: ID!) {
    deleteTransfer(id: $id) {
      id
      incomingTransaction {
        ...TransactionBaseFragment
      }
      outgoingTransaction {
        ...TransactionBaseFragment
      }
    }
  }
`;

export const useDeleteTransfer = () => {
  const [deleteTransfer, { called, loading, data, error }] = useMutation<
    {
      deleteTransfer: ITransfer;
    },
    IDeleteTransferInput
  >(DELETE_TRANSFERS, {
    refetchQueries: ["GetTransactions"],
  });

  return {
    called,
    deleteTransfer,
    loading,
    error,
    transfer: data && data.deleteTransfer && data.deleteTransfer,
  };
};
