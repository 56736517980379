import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

export const DELETE_TRANSACTIONS = gql`
  mutation DeleteTransactions($ids: [ID!]!) {
    deleteTransactions(ids: $ids) {
      count
    }
  }
`;

export const useDeleteTransactions = () => {
  const [deleteTransactions, { called, loading, data, error }] = useMutation<{
    deleteTransactions: { id: string };
  }>(DELETE_TRANSACTIONS, {
    refetchQueries: ["GetTransactions"],
  });

  return {
    called,
    deleteTransactions,
    loading,
    error,
    transaction: data && data.deleteTransactions,
  };
};
