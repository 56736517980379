import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

export interface IStockQuote {
  date: string;
  open: number;
  high: number;
  low: number;
  close: number;
  volume: number;
}

export interface IGetStockQuoteResponse {
  alphaVantage: {
    stockQuote: {
      stockQuotes: IStockQuote[];
    };
  };
}

export const GET_STOCK_QUOTES = gql`
  query GetStockQuotes($input: AVGetStockQuoteInput!) {
    alphaVantage {
      stockQuote(input: $input) {
        stockQuotes {
          date
          open
          high
          low
          close
          volume
        }
      }
    }
  }
`;

export const useStockQuotes = ({ symbol }: { symbol?: string }) => {
  const { loading, data, error, called } = useQuery<IGetStockQuoteResponse>(
    GET_STOCK_QUOTES,
    {
      variables: {
        input: {
          symbol,
          resolution: "daily",
          outputsize: "full",
        },
      },
      skip: !symbol,
      fetchPolicy: "cache-and-network",
    }
  );
  return {
    loading,
    error,
    stockQuotes: (data && data.alphaVantage.stockQuote.stockQuotes) || [],
  };
};
