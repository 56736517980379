import { each, max, maxBy, reduce, without } from "lodash";
import moment from "moment";
import { IAccount } from "./types/Account";
import { IBalance } from "./types/Balance";
import { ITransaction, TransactionType } from "./types/Transaction";

export const getAmountStyle = (transaction: ITransaction) => ({
  color: transaction.type === "debit" ? "red" : "green",
});
export const getBalanceStyle = (
  balance: IBalance | number,
  isDebt?: boolean
) => ({
  color:
    (typeof balance === "number" ? balance : balance.balance) < 0
      ? "red"
      : "green",
});

export const currencyToNumber = (
  amount: string | number | undefined | null
) => {
  if (amount === null || amount === undefined) {
    return amount;
  } else if (typeof amount === "number") {
    return amount;
  } else {
    return parseFloat(amount.replace("$", "").trim());
  }
};

export const prettyDate = (rawDate: Date | string | number | moment.Moment) => {
  const date = moment(rawDate);
  if (!moment().isSame(date, "year")) {
    return date.format("MMM D, YYYY");
  }
  return date.format("MMMM D");
};
export const formatDate = (date: Date): string =>
  new Date(date).toISOString().slice(0, 10);
export const formatCurrency = (amount: any) =>
  isNaN(amount)
    ? amount
    : new Intl.NumberFormat("en-CA", {
        style: "currency",
        currency: "CAD",
      }).format(amount);
export const formatCurrencyShort = (amount: number) => {
  if (amount > 1e9) {
    return `$${Math.round(amount / 1e7) / 100}B`;
  } else if (amount > 1e6) {
    return `$${Math.round(amount / 1e4) / 100}M`;
  } else if (amount > 1e3) {
    return `$${Math.round(amount / 10) / 100}K`;
  } else {
    return `$${Math.round(amount)}`;
  }
};

export const isAccount = (obj: any): obj is IAccount =>
  obj && obj.__typename === "Account";

export const getEquivalentCashBalance = (balance: IBalance) => {
  return balance.assetPrice
    ? balance.balance * (balance.equivalentAssetPrice || balance.assetPrice)
    : balance.equivalentBalance || balance.balance;
};

export const addCashBalance = (
  totalBalance: IBalance,
  balances: IBalance[]
) => {
  each(balances, (balance) => {
    totalBalance.balance += getEquivalentCashBalance(balance);
    totalBalance.date = moment
      .min(moment(totalBalance.date), moment(balance.date))
      .toDate();
  });

  return totalBalance;
};

// Used to add raw balances for any given asset. DO NOT call with balances of different assets. For that use addCashBalances
export const addBalances = (balances: IBalance[]) => {
  if (!balances || balances.length === 0) {
    return null;
  } else if (balances.length === 1) {
    return balances[0];
  } else {
    const lastBalance = maxBy(balances, (balance) =>
      moment(balance.date).toISOString()
    )!;
    const initialBalance = {
      ...lastBalance,
    } as unknown as IBalance;
    return reduce(
      without(balances, lastBalance),
      (totalBalance, balance) => {
        if (balance.asset !== totalBalance.asset) {
          throw new Error(
            `Cannot add balances of different assets!\nExpected ${totalBalance.asset} but received ${balance.asset}.\nConsider using addCashBalance`
          );
        }
        totalBalance.balance += balance.balance;
        // totalBalance.date = moment.min(moment(totalBalance.date), moment(balance.date)).toDate()

        return totalBalance;
      },
      initialBalance
    );
  }
};

export const subtractBalances = (
  firstBalance: IBalance,
  secondBalance: IBalance
) => {
  return {
    balance: firstBalance.balance - secondBalance.balance,
    date: moment
      .min(moment(firstBalance.date), moment(secondBalance.date))
      .toDate(),
  } as IBalance;
};

export const getTotalBalance = (
  accountsOrTransactions: IAccount[] | ITransaction[] | undefined
) => {
  const defaultBalance = {
    date: new Date(),
    balance: 0,
  } as IBalance;

  if (!accountsOrTransactions) {
    return defaultBalance;
  } else if (isAccount(accountsOrTransactions[0])) {
    return reduce(
      accountsOrTransactions as IAccount[],
      (totalBalance, account) => {
        return addCashBalance(totalBalance, account.currentBalances);
      },
      defaultBalance
    );
  } else {
    return reduce(
      accountsOrTransactions as ITransaction[],
      (balance, transaction) => {
        let amount = transaction.equivalentAmount || transaction.amount;
        if (transaction.account.isAsset) {
          if (transaction.type === TransactionType.credit) {
            amount = -amount;
          }
        } else {
          if (transaction.type === TransactionType.debit) {
            amount = -amount;
          }
        }
        return {
          date: max([balance.date, transaction.date]) as Date,
          balance: balance.balance + amount,
        } as IBalance;
      },
      defaultBalance
    );
  }
};

export const commonFormatters = {
  date: formatDate,
  currency: formatCurrency,
};

export const hasTransfer = (transaction: ITransaction) => {
  const { incomingTransfers, outgoingTransfers } = transaction;
  return (
    (incomingTransfers && incomingTransfers.length) ||
    (outgoingTransfers && outgoingTransfers.length)
  );
};

export const getAccountName = (account: IAccount) => {
  return (
    account.nickname ||
    `${account.name}${
      account.number && account.name.indexOf(account.number) === -1
        ? ` - ${account.number}`
        : ""
    }`
  );
};
