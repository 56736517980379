import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { print } from "graphql/language/printer";
import { PORTFOLIO_FRAGMENT } from "../fragments/Portfolio";
import { defragment } from "../lib/graphqlTools";
import { IPortfolio } from "../types/Portfolio";

export interface IGetPortfolioResponse {
  portfolios: IPortfolio[];
}

export const GET_PORTFOLIOS = gql`
  ${defragment(`
    ${print(PORTFOLIO_FRAGMENT)}
    query GetPortfolios {
      portfolios {
        ...PortfolioFragment
      }
    }
  `)}
`;

export const usePortfolios = ({ skip = false } = {}) => {
  const { loading, data, error, called } = useQuery<IGetPortfolioResponse>(
    GET_PORTFOLIOS,
    { skip }
  );
  return {
    loading,
    error,
    portfolios: data && data.portfolios,
  };
};
