import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { USER_FRAGMENT } from "../fragments/User";
import { useToken } from "../hooks/authentication";
import { useUser } from "../hooks/user";
import { IUser } from "../types/User";

export const LOGIN = gql`
  ${USER_FRAGMENT}
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        ...UserFragment
      }
    }
  }
`;

export const useLogin = () => {
  const [token, setToken] = useToken();
  const [, setUser] = useUser();
  const [login, { called, loading, data, error }] = useMutation<{
    login: {
      token: string;
      user: IUser;
    };
  }>(LOGIN);

  const handleLogin: typeof login = async (args) => {
    try {
      const res = await login(args);
      const { data } = res;
      if (data && data.login.token !== token) {
        setUser(data.login.user);
        setToken(data.login.token);
      }
      return res;
    } catch (err) {
      if (token) {
        setToken();
      }
      throw err;
    }
  };

  return {
    called,
    login: handleLogin,
    loading,
    error,
    token: data && data.login.token,
    user: data && data.login.user,
  };
};
