// tslint:disable:ordered-imports
import "./wydr";

import { ApolloProvider } from "@apollo/react-hooks";
import { ThemeProvider } from "@material-ui/core/styles";
/* eslint-disable import/first */
import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import "./App.css";
import client from "./client";
import AuthenticationBoundary from "./components/AuthenticationBoundary";
import Dashboard from "./components/views/Dashboard";
import theme from "./theme";

if (process.env.NODE_ENV !== "production") {
  require("dotenv").config();
}

const App: React.FC = () => (
  <ThemeProvider theme={theme}>
    <ApolloProvider client={client}>
      <AuthenticationBoundary>
        <div className="App">
          <header className="App-header">
            <Router>
              <QueryParamProvider ReactRouterRoute={Route}>
                <Dashboard />
              </QueryParamProvider>
            </Router>
          </header>
        </div>
      </AuthenticationBoundary>
    </ApolloProvider>
  </ThemeProvider>
);

export default App;
