import { omit } from "lodash";
import {
  ICreateTransactionInput,
  useCreateTransactions,
} from "./CreateTransactions";

export interface ICreateTransactionVariables {
  variables: {
    transaction: ICreateTransactionInput;
  };
}

export const useCreateTransaction = () => {
  const result = useCreateTransactions();
  return {
    ...omit(result, ["createTransactions", "transactions"]),
    createTransaction: ({
      variables: { transaction },
    }: ICreateTransactionVariables) =>
      result.createTransactions({
        variables: {
          transactions: [transaction],
        },
      }),
    transaction: result.transactions && result.transactions.pop(),
  };
};
