import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { print } from "graphql/language/printer";
import { useEffect } from "react";
import { TRANSACTION_FRAGMENT } from "../fragments/Transaction";
import { defragment } from "../lib/graphqlTools";
import { ITransaction } from "../types/Transaction";

export interface IGetDuplicateTransactionsInput {
  accountIds?: string[];
}

export const GET_DUPLICATE_TRANSACTIONS = gql`
  ${defragment(`
    ${print(TRANSACTION_FRAGMENT)}
    query GetDuplicateTransactions($input: GetDuplicateTransactionsInput!) {
      duplicateTransactions(input: $input) {
        ...TransactionFragment
      }
  }`)}
`;

export const useDuplicateTransactions = (
  input?: IGetDuplicateTransactionsInput,
  { isLazy = false } = {}
) => {
  const [getDuplicates, { called, loading, data, error }] = useLazyQuery<{
    duplicateTransactions: ITransaction[];
  }>(GET_DUPLICATE_TRANSACTIONS, { variables: { input } });
  useEffect(() => {
    if (!isLazy && !called) {
      getDuplicates({
        variables: {
          input,
        },
      });
    }
  }, [isLazy, called]);

  return {
    called,
    loading,
    error,
    getDuplicates,
    duplicates: data && data.duplicateTransactions,
  };
};
