import { cloneDeep } from "lodash";
import random from "random";

export interface IMonteCarloAccumulatorProps<TResult = unknown> {
  lastResult: TResult;
  input: number;
}

interface ISimulateMonteCarloProps<TResult = unknown> {
  process: (input: IMonteCarloAccumulatorProps<TResult>) => TResult;
  runs: number;
  samples: number;
  mean: number;
  std: number;
  originalResult: TResult;
}

export const simulateMonteCarlo = <TResult = unknown>({
  process,
  runs,
  samples,
  mean,
  std,
  originalResult,
}: ISimulateMonteCarloProps<TResult>) => {
  const normal = random.normal(mean, std);
  const results: {
    runs: TResult[];
  } = {
    runs: [],
  };
  for (let run = 0; run < runs; run++) {
    let result: TResult = cloneDeep(originalResult);
    for (let sample = 0; sample < samples; sample++) {
      result = process({
        input: normal(),
        lastResult: result,
      });
    }
    results.runs.push(result);
  }

  return results;
};

export const compoundReturnAccumulator = ({
  lastResult: compoundReturn,
  input: logReturn,
}: IMonteCarloAccumulatorProps<number>) => (compoundReturn += logReturn);

export const priceAccumulator = ({
  lastResult: lastPrice,
  input: logReturn,
}: IMonteCarloAccumulatorProps<number>) =>
  Math.round(lastPrice * Math.exp(logReturn) * 100) / 100;

export const pricesAccumulator = ({
  lastResult: { prices },
  input: logReturn,
}: IMonteCarloAccumulatorProps<{
  prices: number[];
}>) => {
  prices.push(
    Math.round(prices[prices.length - 1] * Math.exp(logReturn) * 100) / 100
  );
  return {
    prices,
  };
};
