import { gql } from "apollo-boost";
import { CASHFLOW_FRAGMENT } from "./Cashflow";

export const PORTFOLIO_FRAGMENT = gql`
  ${CASHFLOW_FRAGMENT}
  fragment PortfolioFragment on Portfolio {
    id
    title
    description
    cashflows {
      ...CashflowFragment
    }
  }
`;
