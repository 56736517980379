/* eslint-disable no-script-url */

import { makeStyles, Tooltip } from "@material-ui/core";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import CallSplitIcon from "@material-ui/icons/CallSplit";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import LabelOutlinedIcon from "@material-ui/icons/LabelOutlined";
import RefreshIcon from "@material-ui/icons/Refresh";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import chroma from "chroma-js";
import {
  each,
  filter,
  find,
  get,
  lowerCase,
  map,
  mapKeys,
  mapValues,
  maxBy,
  omit,
  pick,
  reduce,
  sortBy,
  sumBy,
  uniqBy,
  upperFirst,
} from "lodash";
import { Column, MaterialTableProps, MTableBody } from "material-table";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHoverDirty, useTimeoutFn } from "react-use";
import {
  formatCurrency,
  getAccountName,
  getAmountStyle,
  getBalanceStyle,
  getEquivalentCashBalance,
  getTotalBalance,
  hasTransfer,
  prettyDate,
} from "../helpers";
import { useAccounts } from "../hooks/accounts";
import { useSelectedAccounts } from "../hooks/state";
import { useCreateTransaction } from "../mutations/CreateTransaction";
import { useDeleteTransaction } from "../mutations/DeleteTransaction";
import { useDeleteTransfer } from "../mutations/DeleteTransfer";
import { useLabelTransaction } from "../mutations/LabelTransaction";
import { useLabelTransactions } from "../mutations/LabelTransactions";
import { useRegenerateBalances } from "../mutations/RegenerateBalances";
import { useUpdateTransaction } from "../mutations/UpdateTransaction";
import { useUpsertTransfer } from "../mutations/UpsertTransfer";
import { useCategories } from "../queries/GetCategories";
import theme from "../theme";
import { AccountType, IAccount } from "../types/Account";
import { AssetPriceType, IBalance } from "../types/Balance";
import {
  IAssetLabel,
  ILabel,
  ITransaction,
  TransactionType,
} from "../types/Transaction";
import { ITransfer } from "../types/Transfer";
import { Balance } from "./Balance";
import DeDuplicateTransactionDialog from "./dialogs/DeDuplicateTransactionDialog";
import DialogSelect from "./dialogs/DialogSelect";
import TransferDialog from "./dialogs/TransferDialog";
import Select from "./Select";
import { StatusIcon } from "./StatusIcon";
import { Table } from "./Table";
import Toast, { IToastProps } from "./Toast";

export interface ITableOptions {
  sorting?: boolean;
  paging?: boolean;
  editable?: boolean;
  search?: boolean;
}

export interface ITransactionsProps {
  style?: any;
  transactions: ITransaction[];
  account?: IAccount;
  title?: string;
  options?: ITableOptions;
}

export interface IUseTransactionsTableColumnsArgs {
  isInvestment?: boolean;
  onClick?: (args: {
    transaction: ITransaction;
    field: keyof ITransaction;
  }) => void;
}

export const getManualBalance = (transaction: ITransaction, asset?: string) =>
  find(
    transaction.balances,
    (balance) => balance.isManual && balance.asset === (asset || null)
  );
export const getBalance = (transaction: ITransaction, asset?: string) =>
  getManualBalance(transaction, asset) ||
  maxBy(filter(transaction.balances, ["asset", asset || null]), "date") ||
  ({
    asset,
    balance: 0,
    date: moment(),
  } as unknown as IBalance);

const useStyles = makeStyles((theme) => ({
  balanceContainer: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "flex-end",
    alignContent: "center",
  },
  balanceIcon: {
    marginRight: 5,
    color: theme.palette.primary.main,
  },
  icon: {
    color: theme.palette.primary.main,
  },
  transferTooltipTitle: {
    paddingBottom: 6,
    fontSize: 13,
  },
  transferTooltipContainer: {
    gap: 10,
    padding: 10,
  },
}));

export interface IHighlight {
  start: number;
  end: number;
  label?: string;
  style?: Record<string, any>;
}

export interface IHighlightedText {
  text: string;
  highlights: IHighlight[];
}

export const HighlightedText = ({ text, highlights }: IHighlightedText) => {
  const sortedHighlights = sortBy(highlights, "start");
  return (
    <span>
      {map(
        reduce(
          sortedHighlights,
          (segments, highlight, idx) => {
            const lastSegment = segments.length
              ? segments[segments.length - 1]
              : { end: -1 };
            if (highlight.start > lastSegment.end + 1) {
              segments.push({
                start: lastSegment.end + 1,
                end: highlight.start - 1,
              });
            }
            segments.push(highlight);
            if (
              idx === highlights.length - 1 &&
              highlight.end < text.length - 1
            ) {
              segments.push({
                start: highlight.end + 1,
                end: text.length - 1,
              });
            }
            return segments;
          },
          !sortedHighlights.length || sortedHighlights[0].start > 0
            ? [
                {
                  start: 0,
                  end: sortedHighlights.length
                    ? sortedHighlights[0].start - 1
                    : text.length,
                },
              ]
            : ([] as IHighlight[])
        ),
        (segment) => {
          const key = `${segment.start}:${segment.end}`;
          const component = (
            <span key={key} style={segment.style}>
              {text.slice(segment.start, segment.end + 1)}
            </span>
          );
          if (segment.label) {
            return (
              <Tooltip key={key} title={segment.label}>
                {component}
              </Tooltip>
            );
          } else {
            return component;
          }
        }
      )}
    </span>
  );
};

export interface ITransactionDescriptionProps {
  transaction: ITransaction;
}

/**
 * Delays the results of useHoverDirty when going from hovered to unhovered.
 * The isHovered: true state will always happen immediately
 */
export const useDelayedHover = (
  hoverRef: React.RefObject<Element>,
  delay = 0
) => {
  const hovered = useHoverDirty(hoverRef);
  const [isHovered, setIsHovered] = useState(hovered);
  const [isHoverDelayReady, cancelHoverDelay, resetHoverDelay] = useTimeoutFn(
    () => setIsHovered(hovered),
    delay
  );

  useEffect(() => {
    if (hovered) {
      cancelHoverDelay();
      if (!isHovered) {
        setIsHovered(hovered);
      }
    } else {
      resetHoverDelay();
    }
  }, [hovered]);

  useEffect(
    () => () => {
      if (isHoverDelayReady() !== null) {
        cancelHoverDelay();
      }
    },
    []
  );

  return isHovered;
};

/**
const colors = {
  "Red Salsa":"f94144",
  "Orange Red":"f3722c",
  "Yellow Orange Color Wheel":"f8961e",
  "Maize Crayola":"f9c74f",
  "Pistachio":"90be6d",
  "Zomp":"43aa8b",
  "Queen Blue":"577590"
}
 */

const ColorOfLabel = {
  currency: "#90BE6D",
  ticker: "#577590",
  quantity: "#F8961E",
  cost: "#F94144",
};

const DisplayNameOfLabel = {
  currency: "Currency",
  ticker: "Symbol",
  quantity: "Quantity",
  cost: "Cost",
};

const labelToHighlight = (label: ILabel) => {
  const backgroundColor =
    ColorOfLabel[label.label as keyof typeof ColorOfLabel] || "#43aa8b";
  const color = chroma(backgroundColor);

  return {
    ...pick(label, ["start", "end", "label"]),
    label:
      DisplayNameOfLabel[label.label as keyof typeof DisplayNameOfLabel] ||
      upperFirst(label.label),
    end: label.end - 1,
    style: {
      padding: 3,
      paddingRight: 3,
      borderRadius: 3,
      border: "0px solid",
      color:
        chroma.contrast(backgroundColor, theme.palette.text.secondary) >= 4.5
          ? theme.palette.text.secondary
          : "#FFFFFF",
      backgroundColor,
    },
  };
};

const getHighlightGroups = (transaction: ITransaction) =>
  map(get(transaction, ["labels", "description"]), (label) => {
    const highlights = [labelToHighlight(label)];
    each(
      ["currency", "quantity", "cost"] as Array<keyof IAssetLabel>,
      (property) => {
        if (label[property]) {
          highlights.push(
            labelToHighlight({
              ...(label[property] as object),
              label: property,
            } as ILabel)
          );
        }
      }
    );

    return highlights;
  });

const LABELED_CATEGORIES = ["Buy", "Sell", "Investments"];

const isPriceInRange = (transaction: ITransaction) =>
  get(transaction, "labels.description[0].isPriceInRange");

export const TransactionDescription = ({
  transaction: tnx,
}: ITransactionDescriptionProps) => {
  const hoverRef = useRef(null);
  const isHovered = useDelayedHover(hoverRef, 0);
  const transactionResults = useLabelTransaction();
  const transaction = {
    ...tnx,
    ...transactionResults.transaction,
  };
  const labelTransaction = useCallback(
    () =>
      transactionResults
        .labelTransaction({
          variables: {
            input: {
              ...pick(transaction, ["id", "description", "date"]),
              includeMispricedMatches: true,
            },
          },
        })
        .catch(console.warn),
    [transaction.id, transaction.description, transaction.date]
  );

  const [highlightGroups, setHighlightGroups] = useState(
    getHighlightGroups(transaction)
  );
  useEffect(() => {
    setHighlightGroups(getHighlightGroups(transaction));
  }, [transaction.labels]);

  const isLabelable = LABELED_CATEGORIES.includes(transaction.category);

  return (
    <div ref={hoverRef} style={{ display: "flex" }}>
      <div>
        {isHovered && highlightGroups.length > 0 ? (
          <HighlightedText
            text={transaction.description}
            highlights={highlightGroups.length ? highlightGroups[0] : []}
          />
        ) : (
          transaction.description
        )}
      </div>
      <div style={{ flexGrow: 1, flexShrink: 0, textAlign: "right" }}>
        {isLabelable && !transactionResults.loading && isHovered ? (
          <RefreshIcon
            onClick={() => labelTransaction()}
            style={{ fontSize: 14 }}
          />
        ) : null}
        <StatusIcon
          size={14}
          warning={
            highlightGroups.length === 0
              ? isTransactionLabelable(transaction)
                ? "No labels were found"
                : undefined
              : !isPriceInRange(transaction)
              ? "Unable to verify symbol or price"
              : undefined
          }
          loading={transactionResults.loading}
          complete={
            isPriceInRange(transaction)
              ? "Price of Symbol Verified!"
              : undefined
          }
          error={transactionResults.error}
        />
      </div>
    </div>
  );
};

export const AssetPriceTypeTooltip: Partial<Record<AssetPriceType, string>> = {
  last: "Price is outdated",
};

export const useTransactionsTableColumns = ({
  isInvestment,
  onClick,
}: IUseTransactionsTableColumnsArgs = {}) => {
  const classes = useStyles();
  const { accounts } = useAccounts();
  const [selectedAccounts] = useSelectedAccounts();
  const { categories } = useCategories();
  const { updateTransaction } = useUpdateTransaction();

  const categoryNames = map(categories, "category");

  const toggleTransactionType = (type: TransactionType) =>
    type === TransactionType.debit
      ? TransactionType.credit
      : TransactionType.debit;
  const handleClick = useCallback(
    ({ transaction, field }) =>
      (event: any) => {
        if (onClick) {
          event.stopPropagation();
          onClick({
            field,
            transaction,
          });
        }
      },
    []
  );

  const getColumns = () => {
    const columns: Column<ITransaction>[] = [
      {
        field: "date",
        title: "Date",
        type: "date",
        ...(true && ({ width: 130 } as object)),
        render: (transaction) => prettyDate(transaction.date),
      },
      {
        field: "account",
        title: "Account",
        editable: "onAdd",
        headerStyle: {
          maxWidth: 150,
        },
        cellStyle: {
          maxWidth: 150,
          flex: 1,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        customSort: (t1, t2) =>
          lowerCase(t1.account.name) < lowerCase(t2.account.name) ? -1 : 1,
        customFilterAndSearch: (
          accountIds: string[],
          transaction: ITransaction
        ) => {
          return (
            accountIds.length === 0 ||
            accountIds.indexOf(transaction.account.id) > -1
          );
        },
        defaultFilter: map(selectedAccounts, "id"),
        lookup: mapValues(mapKeys(accounts, "id"), getAccountName),
        render: (transaction) =>
          transaction.account && getAccountName(transaction.account),
        editComponent: (props) => (
          <Select
            value={props.value}
            selections={accounts || []}
            getDisplayName={getAccountName}
            onChange={props.onChange}
          />
        ),
      },
      {
        field: "description",
        render: (transaction) => (
          <TransactionDescription transaction={transaction} />
        ),
        // ...(true && ({ width: 300 } as object)),
        headerStyle: {
          minWidth: 300,
        },
        cellStyle: {
          minWidth: 300,
        },
        title: "Description",
      },
      {
        field: "category",
        editComponent: (props) => (
          <Select
            value={props.value}
            selections={categoryNames}
            onChange={props.onChange}
          />
        ),
        headerStyle: {
          maxWidth: 150,
        },
        cellStyle: {
          maxWidth: 150,
          flex: 1,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        title: "Category",
      },
    ];

    if (isInvestment) {
      columns.push({
        field: "investment",
        title: "Symbol",
      });
      columns.push({
        field: "units",
        title: "Quantity",
        type: "numeric",
      });
      columns.push({
        field: "price",
        title: "Price",
        type: "currency",
      });
      columns.push({
        field: "amount",
        title: "Value",
        cellStyle: (_, transaction) =>
          transaction && getAmountStyle(transaction),
        render: (transaction) => formatCurrency(transaction.amount),
        type: "currency",
      });
      columns.push({
        field: "currency",
        title: "Currency",
      });
    } else {
      columns.push({
        field: "amount",
        title: "Amount",
        cellStyle: (_, transaction) =>
          transaction && getAmountStyle(transaction),
        render: (transaction) => (
          <div className={classes.balanceContainer}>
            {hasTransfer(transaction) ? (
              <Tooltip
                title={
                  <div>
                    {transaction.incomingTransfers &&
                    transaction.incomingTransfers.length ? (
                      <div className={classes.transferTooltipContainer}>
                        <div className={classes.transferTooltipTitle}>
                          Received from:
                        </div>
                        <div>
                          {map(transaction.incomingTransfers, (transfer) => (
                            <div key={transfer.id}>
                              {prettyDate(transfer.incomingTransaction.date)} -{" "}
                              {transfer.incomingTransaction.description}
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : null}
                    {transaction.outgoingTransfers &&
                    transaction.outgoingTransfers.length ? (
                      <div className={classes.transferTooltipContainer}>
                        <div className={classes.transferTooltipTitle}>
                          Sent to:
                        </div>
                        {map(transaction.outgoingTransfers, (transfer) => (
                          <div key={transfer.id}>
                            {prettyDate(transfer.outgoingTransaction.date)} -{" "}
                            {transfer.outgoingTransaction.description}
                          </div>
                        ))}
                      </div>
                    ) : null}
                  </div>
                }
              >
                {transaction.incomingTransfers?.length ? (
                  transaction.outgoingTransfers?.length ? (
                    <SyncAltIcon color="action" />
                  ) : (
                    <ArrowRightAltIcon
                      color="action"
                      style={{
                        transform: "rotate(180deg)",
                      }}
                    />
                  )
                ) : (
                  <ArrowRightAltIcon color="action" />
                )}
              </Tooltip>
            ) : null}
            <div onClick={handleClick({ transaction, field: "amount" })}>
              {formatCurrency(
                transaction.equivalentAmount || transaction.amount
              )}
            </div>
          </div>
        ),
        type: "numeric",
      });
      columns.push({
        field: "balance",
        title: "Balance",
        cellStyle: (_, transaction) =>
          transaction && getBalanceStyle(getBalance(transaction)),
        render: (transaction) => {
          const balance = getBalance(transaction);
          const problematicBalances = filter(
            transaction.balances,
            (balance) => balance.assetPriceType === AssetPriceType.last
          );
          return (
            <div className={classes.balanceContainer}>
              {problematicBalances.length > 0 ? (
                <Tooltip
                  title={map(problematicBalances, (balance) => (
                    <p>
                      {balance.asset} :{" "}
                      {AssetPriceTypeTooltip[balance.assetPriceType]};
                    </p>
                  ))}
                >
                  <InfoOutlinedIcon
                    style={{ color: "orange", margin: "auto", fontSize: 14 }}
                  />
                </Tooltip>
              ) : null}
              <Tooltip
                title={
                  <span>
                    {map(sortBy(transaction.balances, "asset"), (balance) => (
                      <span>
                        <p>
                          {`${
                            balance.asset || transaction.account.currency
                          } : ${formatCurrency(
                            balance.assetPrice
                              ? balance.assetPrice * balance.balance
                              : balance.balance
                          )}`}
                          {balance.asset ? ` (${balance.balance})` : null}
                          {[AssetPriceType.last].includes(
                            balance.assetPriceType
                          ) ? (
                            <>
                              <span> </span>
                              <InfoOutlinedIcon
                                style={{ margin: "auto", fontSize: 14 }}
                              />
                            </>
                          ) : null}
                          {balance.isManual ? (
                            <HowToRegIcon className={classes.balanceIcon} />
                          ) : null}
                        </p>
                      </span>
                    ))}
                  </span>
                }
              >
                <span>
                  {formatCurrency(
                    sumBy(transaction.balances, getEquivalentCashBalance)
                  )}
                </span>
              </Tooltip>
            </div>
          );
        },
        type: "numeric",
      });
    }

    return columns;
  };

  const [columns, setColumns] = useState(getColumns());

  useEffect(() => {
    setColumns(getColumns());
  }, [
    isInvestment,
    map(accounts, (act) => `${act.id}-${act.name}`).join(":"),
    map(selectedAccounts, (act) => `${act.id}-${act.name}`).join(":"),
  ]);

  return columns;
};

const isTransactionLabelable = (transaction: ITransaction) => {
  return LABELED_CATEGORIES.includes(transaction.category);
};

export const Transactions = ({
  style,
  options,
  transactions,
  account,
  title,
}: ITransactionsProps) => {
  const classes = useStyles();
  const { createTransaction } = useCreateTransaction();
  const { deleteTransaction } = useDeleteTransaction();
  const { updateTransaction } = useUpdateTransaction();
  const { upsertTransfer } = useUpsertTransfer();
  const { deleteTransfer } = useDeleteTransfer();
  const { labelTransactions, ...labeledTransactionResults } =
    useLabelTransactions();
  const { accounts } = useAccounts();
  const [selectedAccounts, setSelectedAccounts] = useSelectedAccounts();
  const { regenerateBalances, ...regenerateBalancesResults } =
    useRegenerateBalances();
  const [initialTransfer, setInitialTransfer] =
    useState<Partial<ITransfer> | null>(null);
  const handleClick = ({
    transaction,
    field,
  }: {
    transaction: ITransaction;
    field: keyof ITransaction;
  }) => {
    const transfers = [
      ...transaction.incomingTransfers,
      ...transaction.outgoingTransfers,
    ];
    if (transfers.length) {
      setInitialTransfer(transfers[0]);
      return;
    }
    if (transaction.type === "debit") {
      setInitialTransfer({
        incomingTransaction: transaction,
      });
    } else {
      setInitialTransfer({
        outgoingTransaction: transaction,
      });
    }
  };
  const handleCloseTransferDialog = () => {
    setInitialTransfer(null);
  };
  const handleSubmitTransfer = (transfer: ITransfer) => {
    handleCloseTransferDialog();
    upsertTransfer(transfer as ITransfer);
  };
  const handleDeleteTransfer = (transfer: ITransfer) => {
    handleCloseTransferDialog();
    if (transfer.id) {
      deleteTransfer({
        variables: {
          id: transfer.id,
        },
      });
    }
  };
  const columns = useTransactionsTableColumns({
    isInvestment: account && account.type === AccountType.Investment,
    onClick: handleClick,
  });

  const editable: MaterialTableProps<ITransaction>["editable"] =
    !options || options.editable
      ? {
          onRowAdd: async (
            transaction: ITransaction & { balance?: string }
          ) => {
            const variables: any = {
              transaction: {
                ...transaction,
                type:
                  transaction.amount > 0
                    ? TransactionType.credit
                    : TransactionType.debit,
                amount: Math.abs(transaction.amount),
                account: {
                  id: transaction.account.id,
                },
              },
            };
            if (transaction.balance) {
              variables.transaction.balances = [
                {
                  date: moment(transaction.date).startOf("day").toDate(),
                  balance: parseFloat(transaction.balance),
                  isManual: true,
                },
              ];
            }
            await createTransaction({ variables });
          },
          onRowUpdate: async (
            newTransaction: ITransaction & { balance?: string },
            oldTransaction: ITransaction | undefined
          ) => {
            const variables: any = {
              transaction: {
                ...omit(
                  newTransaction,
                  "account",
                  "type",
                  "__typename",
                  "balance"
                ),
                date: moment(newTransaction.date).startOf("day"),
                amount: Math.abs(newTransaction.amount),
              },
            };

            if (newTransaction.balance) {
              variables.transaction.balances = [
                {
                  ...omit(
                    oldTransaction
                      ? getBalance(oldTransaction)
                      : {
                          date: moment(newTransaction.date).startOf("day"),
                          account: {
                            id: newTransaction.account.id,
                          },
                        },
                    ["__typename"]
                  ),
                  balance: parseFloat(newTransaction.balance),
                  account: {
                    id: newTransaction.account.id,
                  },
                  isManual: true,
                },
              ];
            }
            await updateTransaction({ variables });
          },
          onRowDelete: async (transaction: ITransaction) => {
            deleteTransaction({
              variables: {
                id: transaction.id,
              },
            });
          },
        }
      : undefined;

  const totalBalance = getTotalBalance(transactions);

  const handleLabelTransactions = useCallback(() => {
    if (!labeledTransactionResults.loading) {
      const transactionsThatNeedLabels = map(
        filter(
          transactions,
          (transaction) =>
            isTransactionLabelable(transaction) &&
            !get(transaction, "labels.description[0].isPriceInRange")
        ),
        (transaction) => ({
          ...pick(transaction, ["id", "date"]),
          description:
            transaction.originalDescription || transaction.description,
        })
      );

      setToast({
        message: `Labelling ${transactionsThatNeedLabels.length} transactions...`,
        severity: "info",
        progress: true,
        autoHideDuration: null,
      });
      labelTransactions({
        variables: {
          input: {
            transactions: transactionsThatNeedLabels,
            includeMispricedMatches: true,
          },
        },
      })
        .then(() => {
          setToast({
            message: `Successfully labelled ${transactionsThatNeedLabels.length} transactions`,
            severity: "success",
          });
        })
        .catch((err) => {
          console.error(err);
          setToast({
            message: `Something went wrong while labelling ${transactionsThatNeedLabels.length} transactions.\nPlease try again`,
            severity: "warning",
          });
        });
    }
  }, [
    map(transactions, "id").sort().join(":"),
    labeledTransactionResults.loading,
  ]);

  const visibleAccounts = useMemo(
    () => uniqBy(map(transactions, "account"), "id"),
    [transactions]
  );
  const handleRegenerateBalances = () => {
    setIsRegenerateBalancesAccountSelectOpen(true);
  };

  const [toast, setToast] = useState<IToastProps>({
    severity: "success",
  });

  const [
    isRegenerateBalancesAccountSelectOpen,
    setIsRegenerateBalancesAccountSelectOpen,
  ] = useState(false);

  const handleSubmitRegenerateBalances = (account: IAccount) => {
    setIsRegenerateBalancesAccountSelectOpen(false);
    if (!regenerateBalancesResults.loading) {
      const selectedAccounts = account ? [account] : visibleAccounts;
      Promise.mapSeries([account], (account, idx) => {
        const progressString = `(${idx + 1}/${selectedAccounts.length})`;
        setToast({
          message: `${progressString} Regenerating balances for ${getAccountName(
            account
          )}...`,
          severity: "info",
          progress: true,
          autoHideDuration: null,
        });
        return regenerateBalances({
          variables: {
            accountId: account.id,
          },
          refetchQueries: ["GetTransactions"],
        })
          .then(() =>
            setToast({
              message: `${progressString} Regenerated balances for ${getAccountName(
                account
              )}`,
              severity: "success",
            })
          )
          .catch((err) => {
            console.error(err);
            setToast({
              message: `${progressString} Failed to regenerate balances for ${getAccountName(
                account
              )}`,
              severity: "warning",
            });
          })
          .finally(() => {
            return new Promise((resolve) =>
              setTimeout(() => {
                resolve();
              }, 1000)
            );
          });
      });
    }
  };

  const handleFilterChange = (field: string, value: any) => {
    switch (field) {
      case "account": {
        setSelectedAccounts(
          filter(
            map(value, (accountId) => find(accounts, ["id", accountId])),
            (account) => !!account
          ) as IAccount[]
        );
      }
    }
  };

  const [isDuplicatesModalOpen, setIsDuplicatesModalOpen] = useState(false);
  const handleFindDuplicates = () => setIsDuplicatesModalOpen(true);
  const handleCloseDuplicates = useCallback(
    () => setIsDuplicatesModalOpen(false),
    [isDuplicatesModalOpen]
  );

  return (
    <>
      <DeDuplicateTransactionDialog
        isOpen={isDuplicatesModalOpen}
        onClose={handleCloseDuplicates}
      />
      <DialogSelect
        isOpen={isRegenerateBalancesAccountSelectOpen}
        title="Regenerate Balances"
        label="Account"
        noneTitle="All Accounts"
        selections={visibleAccounts}
        displayField="name"
        onSubmit={handleSubmitRegenerateBalances}
        onClose={() => setIsRegenerateBalancesAccountSelectOpen(false)}
      />
      {initialTransfer ? (
        <TransferDialog
          initialTransfer={initialTransfer}
          isOpen={true}
          onClose={handleCloseTransferDialog}
          onSubmit={handleSubmitTransfer}
          onDelete={handleDeleteTransfer}
        />
      ) : null}
      <Toast {...toast} />
      <Table
        style={style}
        title={
          <Balance title={title || "Transactions"} balance={totalBalance} />
        }
        columns={columns}
        data={transactions}
        extraActions={[
          {
            icon: () => {
              return (
                <>
                  <StatusIcon {...labeledTransactionResults} size={14} />
                  {!labeledTransactionResults.loading ? (
                    <LabelOutlinedIcon />
                  ) : null}
                </>
              );
            },
            tooltip: "Label Transactions",
            onClick: handleLabelTransactions,
          },
          {
            icon: () => {
              return (
                <>
                  <StatusIcon {...regenerateBalancesResults} size={14} />
                  {!regenerateBalancesResults.loading ? <RefreshIcon /> : null}
                </>
              );
            },
            tooltip: "Generate Balances",
            onClick: handleRegenerateBalances,
          },
          {
            icon: () => <CallSplitIcon />,
            tooltip: "Find Duplicates",
            onClick: handleFindDuplicates,
          },
        ]}
        localization={{
          body: {
            emptyDataSourceMessage: "No Transactions found",
            editRow: {
              deleteText:
                "⚠️  Are you sure you want to delete this transaction?",
            },
          },
        }}
        options={{
          ...options,
          search: false,
          addRowPosition: "first",
          padding: "dense",
          pageSize: 10,
          pageSizeOptions: [10, 25, 50, 100],
          emptyRowsWhenPaging: false,
        }}
        editable={editable}
        components={{
          Body: (bodyProps) => (
            <MTableBody
              {...bodyProps}
              onFilterChanged={(columnId: number, value: any) => {
                bodyProps.onFilterChanged(columnId, value);
                handleFilterChange(columns[columnId].field!, value);
              }}
            />
          ),
        }}
      />
    </>
  );
};

// Transactions.whyDidYouRender = true;

export default Transactions;
