import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Snackbar from "@material-ui/core/Snackbar";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";
import {
  ICreateAccountInput,
  useCreateAccount,
} from "../../mutations/CreateAccount";
import { ICreateBalanceInput } from "../../mutations/CreateBalance";
import { useInstitutions } from "../../queries/GetInstitutions";
import { IAccount } from "../../types/Account";
import { AddBankAccountForm } from "../forms/AccountForm";
import { AddBalanceForm } from "../forms/BalanceForm";

export interface IAddBankAccountsDialogProps {
  isOpen: boolean;
  initialAccount?: Partial<ICreateAccountInput>;
  onSubmit: (account: IAccount) => void;
  onClose: () => void;
}

export default function AddBankAccountsDialog(
  props: IAddBankAccountsDialogProps
) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [accountInput, setAccountInput] = React.useState<
    Partial<ICreateAccountInput>
  >(props.initialAccount || {});
  const [balance, setBalance] = React.useState<
    Omit<ICreateBalanceInput, "account">
  >({
    balance: 0,
    date: new Date(),
  });

  const institutionResult = useInstitutions();
  const { createAccount, error, loading, called, account } = useCreateAccount();

  const institutions = institutionResult.institutions || [];

  const handleSubmit = async () => {
    try {
      const { data } = await createAccount({
        variables: {
          account: {
            ...accountInput,
            balances: [balance],
          } as ICreateAccountInput,
        },
      });
      if (data && data.createAccount) {
        props.onSubmit(data.createAccount);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={!!error}
        autoHideDuration={2500}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{error && error.message}</span>}
      />
      <Dialog
        fullScreen={fullScreen}
        disableBackdropClick
        disableEscapeKeyDown
        open={props.isOpen}
        onClose={props.onClose}
      >
        <DialogTitle>Add BankAccounts</DialogTitle>
        <DialogContent>
          <AddBankAccountForm
            initialAccount={props.initialAccount}
            institutions={institutions || []}
            onChange={setAccountInput}
          />
          <AddBalanceForm initialBalance={balance} onChange={setBalance} />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
