import DateFnsUtils from "@date-io/date-fns";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { omit } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { ICalculateCashflowByAnnualInterestRateOptionsFull } from "../../lib/calculators";
import { IAnnualCashflow, PaymentFrequency } from "../../types/Cashflow";
import Select from "../Select";
import { useComputeAnnualizedCashflow } from "./AnnualizedCashflowForm";

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    justifyContent: "space-between",
  },
  item: {
    padding: theme.spacing(2),
    fontSize: 16,
  },
  itemControl: {
    width: "100%",
  },
  fixedHeight: {
    height: 480, // Set this based on device size
  },
}));

export interface ILoanFormProps {
  onChange?: (
    cashflow: ICalculateCashflowByAnnualInterestRateOptionsFull
  ) => void;
  onSubmit?: (cashflow: IAnnualCashflow) => void;
  showCompute?: boolean;
  initialValue?: IAnnualCashflow;
  type?: "loan" | "investment";
}

export const LoanForm = ({
  initialValue,
  onChange,
  onSubmit,
  type = "loan",
  showCompute = false,
}: ILoanFormProps) => {
  const classes = useStyles();

  const [state, setState] = useState<Record<string, string | number>>({
    startDate: moment() as unknown as string,
    paymentPeriod: PaymentFrequency.MONTHLY,
    presentValue: 0,
    futureValue: "",
    numberOfPayments: 0,
    payment: 0,
    annualInterestRate: 0,
    type,
    ...(omit(initialValue, ["interestRate"]) as any),
  });

  const handleChange = useCallback(
    (property, mapToState?: (value: string | number) => string | number) =>
      (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = evt.target.value;
        const newState: typeof state = omit(state, property);
        if (value !== "") {
          newState[property] = mapToState ? mapToState(value) : value;
        } else {
          // TODO Get rid of strings in the state where numbers should live
          newState[property] = "";
        }
        setState(newState);
      },
    [state]
  );

  useEffect(() => {
    onChange &&
      onChange({
        ...state,
      } as unknown as ICalculateCashflowByAnnualInterestRateOptionsFull);
  }, [state]);

  const calculateAnnualizedCashflow = useComputeAnnualizedCashflow();
  const handleClickCompute = useCallback(
    (evt: any) => {
      const computed = calculateAnnualizedCashflow({
        ...state,
      } as any);
      if (computed) {
        setState(computed as any);
        onSubmit && onSubmit(computed);
      }
    },
    [state]
  );

  const handleChangeStartDate = useCallback(
    (startDate: string | null | Date) => {
      setState({
        ...state,
        startDate: startDate as string,
      });
    },
    [state]
  );

  return (
    <Grid container className={classes.container}>
      <Grid className={classes.item} item xs={12} sm={4}>
        <FormControl className={classes.itemControl}>
          <InputLabel htmlFor="standard-text">Title</InputLabel>
          <Input
            id="standard-text"
            value={state.title || "untitled"}
            onFocus={(event) => event.target.select()}
            onChange={handleChange("title")}
          />
        </FormControl>
      </Grid>
      <Grid className={classes.item} item xs={12} sm={8}>
        <FormControl className={classes.itemControl}>
          <InputLabel htmlFor="standard-text">Description</InputLabel>
          <Input
            id="standard-text"
            value={state.description}
            onFocus={(event) => event.target.select()}
            onChange={handleChange("description")}
          />
        </FormControl>
      </Grid>
      <Grid className={classes.item} item xs={6} sm={4}>
        <FormControl className={classes.itemControl}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="none"
              id="start-date-picker"
              label="Start Date"
              value={state.startDate}
              onChange={handleChangeStartDate}
              onFocus={(event) => event.target.select()}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </FormControl>
      </Grid>
      <Grid className={classes.item} item xs={6} sm={4}>
        <TextField
          style={{ width: "100%" }}
          label={type === "loan" ? "Loan Amount" : "Initial Investment"}
          value={
            state.presentValue === ""
              ? ""
              : type === "loan"
              ? state.presentValue
              : -state.presentValue
          }
          onChange={handleChange("presentValue", (v) =>
            type === "loan" ? v : -v
          )}
          onFocus={(event) => event.target.select()}
          name="presentValue"
          id="standard-adornment-amount"
          InputProps={{
            inputComponent: NumberFormatCustom as any,
          }}
        />
      </Grid>
      <Grid className={classes.item} item xs={6} sm={4}>
        <TextField
          className={classes.itemControl}
          style={{ width: "100%" }}
          label="Future Balance"
          value={state.futureValue}
          onChange={handleChange("futureValue")}
          onFocus={(event) => event.target.select()}
          name="futureValue"
          id="standard-adornment-amount"
          InputProps={{
            inputComponent: NumberFormatCustom as any,
          }}
        />
      </Grid>
      <Grid className={classes.item} item xs={6} sm={4}>
        <TextField
          className={classes.itemControl}
          style={{ width: "100%" }}
          label={type === "loan" ? "Payment" : "Buy/Sell Amount"}
          value={state.payment === "" ? "" : -state.payment}
          onChange={handleChange("payment", (v) => -v)}
          onFocus={(event) => event.target.select()}
          name="payment"
          id="standard-adornment-amount"
          InputProps={{
            inputComponent: NumberFormatCustom as any,
          }}
        />
      </Grid>
      <Grid className={classes.item} item xs={6} sm={4}>
        <FormControl className={classes.itemControl}>
          <InputLabel htmlFor="standard-number">Number of Payments</InputLabel>
          <Input
            id="standard-number"
            value={state.numberOfPayments}
            onFocus={(event) => event.target.select()}
            onChange={handleChange("numberOfPayments")}
          />
        </FormControl>
      </Grid>
      <Grid className={classes.item} item xs={6} sm={4}>
        <FormControl className={classes.itemControl}>
          <Select
            label="Payment Period"
            value={state.paymentPeriod as any}
            selections={[
              PaymentFrequency.WEEKLY,
              PaymentFrequency.BI_WEEKLY,
              PaymentFrequency.MONTHLY,
              PaymentFrequency.ANNUALLY,
            ]}
            onChange={(value) =>
              handleChange("paymentPeriod")({ target: { value } } as any)
            }
          />
        </FormControl>
      </Grid>
      <Grid className={classes.item} item xs={12} sm={8}>
        <FormControl className={classes.itemControl}>
          <InputLabel htmlFor="standard-text">Notes</InputLabel>
          <Input
            id="standard-text"
            value={state.notes}
            onFocus={(event) => event.target.select()}
            onChange={handleChange("notes")}
          />
        </FormControl>
      </Grid>
      <Grid className={classes.item} item xs={6} sm={4}>
        <FormControl className={classes.itemControl}>
          <InputLabel htmlFor="standard-percent">
            Annual Interest Rate
          </InputLabel>
          <Input
            id="standard-percent"
            value={
              state.annualInterestRate === ""
                ? ""
                : Math.round((state.annualInterestRate as number) * 100 * 1e5) /
                  1e5
            }
            onChange={handleChange("annualInterestRate", (v: any) => v / 100)}
            onFocus={(event) => event.target.select()}
            endAdornment={<InputAdornment position="end">%</InputAdornment>}
          />
        </FormControl>
      </Grid>
      {showCompute ? (
        <Grid className={classes.item} item xs={6} sm={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickCompute}
          >
            Compute
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
};
