import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { filter, flatten, groupBy, map, sortBy } from "lodash";
import React, { useMemo } from "react";
import { useAccounts } from "../../hooks/accounts";
import { search } from "../../hooks/search";
import { useSearchText } from "../../hooks/state";
import ErrorCatcher from "../ErrorCatcher";
import { Holdings, IHolding } from "../Holdings";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

export default function InvestmentsView() {
  const classes = useStyles();
  const { loading, accounts, error } = useAccounts();
  const [searchText] = useSearchText();

  const holdings = useMemo(() => {
    const balances = filter(
      sortBy(flatten(map(accounts, "currentBalances")), "date"),
      (balance) => balance.balance !== 0
    );
    const balancesByAsset = groupBy(
      balances,
      (balance) => balance.asset || balance.account.currency
    );
    return map(balancesByAsset, (assetBalances, asset) => {
      let type = "ETF";
      if (asset === assetBalances[0].account.currency) {
        type = "Currency";
      }
      // TODO type === 'stock'
      return {
        type,
        description: "TODO",
        symbol: asset,
        balances: assetBalances,
      } as IHolding;
    });
  }, [accounts]);

  const searchedHoldings = useMemo(
    () =>
      holdings && searchText
        ? search(holdings, searchText, {
            threshold: 0.3,
            keys: ["type", "symbol", "description", "balances.account.name"],
          })
        : holdings,
    [holdings, searchText]
  );

  return (
    <React.Fragment>
      <ErrorCatcher error={error || error} />
      <Container maxWidth="md">
        <Grid container spacing={2} className={classes.container}>
          <Grid item xs={12}>
            <Holdings holdings={searchedHoldings} />
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
