import { gql } from "apollo-boost";

export const BALANCE_FRAGMENT = gql`
  fragment BalanceFragment on Balance {
    id
    date
    balance
    asset
    assetPrice
    assetPriceType
    sortOrder
    isManual
    account {
      id
      currency
    }
  }
`;
