import { useTheme } from "@material-ui/core/styles";
import React from "react";
import * as errorBoundary from "react-error-boundary";
import Toast from "./Toast";

export const ErrorView = ({ error }: any) => {
  const theme = useTheme();
  return (
    <div
      style={{
        color: theme.palette.error.light,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div>
        <div style={{ marginBottom: 0 }}>
          Oops looks like something went wrong!
        </div>
        <div
          style={{
            color: theme.palette.grey.A200,
            fontSize: 14,
            margin: 10,
            textAlign: "start",
          }}
        >
          {error.message}
        </div>
      </div>
    </div>
  );
};

export const ErrorBoundary = ({ children }: any) => {
  return (
    <errorBoundary.ErrorBoundary FallbackComponent={ErrorView}>
      {children}
    </errorBoundary.ErrorBoundary>
  );
};

const ErrorCatcher = ({ error }: { error?: Error }) => (
  <Toast message={error && error.message} severity="error" />
);

export default ErrorCatcher;
