import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { print } from "graphql/language/printer";
import { useEffect } from "react";
import { USER_FRAGMENT } from "../fragments/User";
import { useUser as useUserState } from "../hooks/user";
import { defragment } from "../lib/graphqlTools";
import { IUser } from "../types/User";

export interface IGetUserResponse {
  me: IUser;
}

export const GET_USER = gql`
  ${defragment(`
    ${print(USER_FRAGMENT)}
    query GetUser {
      me {
        ...UserFragment
      }
    }
  `)}
`;

export const useUser = ({ isLazy = true } = {}) => {
  const [, setUser] = useUserState();
  const [getUser, { loading, data, error, called }] =
    useLazyQuery<IGetUserResponse>(GET_USER, {
      fetchPolicy: "cache-and-network",
    });
  useEffect(() => {
    if (!isLazy && !called) {
      getUser();
    }
  }, []);
  useEffect(() => {
    if (data) {
      setUser(data.me);
    }
  }, [data && data.me]);
  return {
    getUser,
    loading,
    error,
    user: data && data.me,
  };
};
