import "antd/dist/antd.css";
import Bluebird from "bluebird";
import React from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import ReactDOM from "react-dom";
// import './disableErrorPopup.css';   // Uncomment this line to disable error popups during dev
import App from "./App";
import "./index.css";
import "./react_dates_overrides.css";
import * as serviceWorker from "./serviceWorker";

// tslint:disable-next-line
global.Promise = Bluebird;

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
