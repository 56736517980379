import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { IIDInput } from "./CreateTransactions";

export type IDeleteTransactionInput = IIDInput;

export const DELETE_TRANSACTION = gql`
  mutation DeleteTransaction($id: ID!) {
    deleteTransaction(id: $id) {
      id
    }
  }
`;

export const useDeleteTransaction = () => {
  const [deleteTransaction, { called, loading, data, error }] = useMutation<{
    deleteTransaction: { id: string };
  }>(DELETE_TRANSACTION, {
    refetchQueries: ["GetTransactions"],
  });

  return {
    called,
    deleteTransaction,
    loading,
    error,
    transaction: data && data.deleteTransaction && data.deleteTransaction,
  };
};
