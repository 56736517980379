import { flatten, map, mapKeys, uniqBy } from "lodash";
import { useEffect, useMemo } from "react";
import client from "../client";
import { ACCOUNT_FRAGMENT } from "../fragments/Account";
import {
  GET_ACCOUNTS,
  useAccounts as useAccountsQuery,
} from "../queries/GetAccounts";
import { useEquivalentBalances } from "./balances";

export const getAccountCache = ({ accountId }: { accountId: string }) => {
  return client.readFragment({
    fragment: ACCOUNT_FRAGMENT,
    fragmentName: "AccountFragment",
    id: `Account:${accountId}`,
  });
};

export const useAccounts = ({ currency = "CAD" } = {}) => {
  const { accounts, ...results } = useAccountsQuery();
  const balances = useMemo(
    () => uniqBy(flatten(map(accounts, "currentBalances")), "id"),
    [accounts]
  );
  const { equivalentBalances, loading, error } = useEquivalentBalances(
    balances,
    currency
  );

  const equivalentAccounts = useMemo(() => {
    const balancesById = mapKeys(equivalentBalances, "id");
    return map(accounts, (account) => ({
      ...account,
      currentBalances: map(
        account && account.currentBalances,
        (balance) => balancesById[balance.id] || balance
      ),
    }));
  }, [equivalentBalances]);

  useEffect(() => {
    client.writeQuery({
      query: GET_ACCOUNTS,
      data: {
        accounts: equivalentAccounts,
      },
    });
  }, [equivalentAccounts]);

  return {
    accounts: equivalentAccounts,
    loading: results.loading || loading,
    error: results.error || error,
  };
};
