/* eslint-disable no-script-url */

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import EditIcon from "@material-ui/icons/Edit";
import Fuse from "fuse.js";
import {
  findIndex,
  flatten,
  keys,
  map,
  remove,
  sortBy,
  zipObject,
} from "lodash";
import { Column } from "material-table";
import React from "react";
import { commonFormatters, getAmountStyle } from "../helpers";
import { ITransactionFieldMap, mapTransactionField } from "../mapper";
import { ICreateTransactionInput } from "../mutations/CreateTransactions";
import { AccountType, IAccount } from "../types/Account";
import { ITransaction, TransactionType } from "../types/Transaction";
import Equation from "./Equation";
import { IRawTransaction } from "./RawTransactions";
import { useTransactionsTableColumns } from "./Transactions";

export interface ISampleTransactionsProps {
  account?: IAccount;
  isEditable?: boolean;
  transactionFieldMaps: ITransactionFieldMap[];
  rawTransactions: IRawTransaction[];
  onTransactionFieldMapsChange?: (
    transactionFieldMaps: ITransactionFieldMap[]
  ) => void;
}

const columnStyles = {
  amount: (amount: number) =>
    getAmountStyle({
      amount: Math.abs(amount),
      type: amount >= 0 ? TransactionType.credit : TransactionType.debit,
    } as ITransaction),
};

const aliases: {
  [field in keyof ITransaction]?: string[];
} = {
  investment: ["symbol"],
  units: ["quantity"],
  amount: ["value"],
};

const guessFieldSource = ({
  potentialSources,
  field,
}: {
  potentialSources: string[];
  field: keyof ITransaction;
}) => {
  const fuse = new Fuse(potentialSources, {
    includeScore: true,
  });
  const fieldAliases = aliases[field] ? [field, ...aliases[field]] : [field];
  const results = sortBy(
    flatten(map(fieldAliases, (alias) => fuse.search(alias))),
    "score"
  );
  if (results.length > 0) {
    return results[0].item;
  }
  return field;
};

export default function SampleTransactions({
  account,
  rawTransactions,
  isEditable,
  transactionFieldMaps,
  onTransactionFieldMapsChange,
}: ISampleTransactionsProps) {
  const [editingField, setEditingField] = React.useState<
    Column<ITransaction> | undefined
  >();

  const columns = useTransactionsTableColumns({
    isInvestment: account && account.type === AccountType.Investment,
  });

  if (account && account.id) {
    remove(columns, (col) => col.field === "account");
  }

  const columnsByField = zipObject(map(columns, "field") as string[], columns);
  const sampleSize = Math.min(rawTransactions.length, 5);
  // let sampleTransactions = sampleSize > 0 ? mapRawTransactions(transactionFieldMaps, rawTransactions.slice(0, sampleSize)) : []

  // const addSelectedAccountToTransactions = (transactions: ICreateTransactionInput[]) => map(transactions, t => ({ ...t, account: t.account || account }))
  // sampleTransactions = addSelectedAccountToTransactions(sampleTransactions)

  const fieldMapIndex = zipObject(
    map(transactionFieldMaps, "field"),
    transactionFieldMaps
  );

  const handleTransactionFieldMapChange = (
    fieldMaps: ITransactionFieldMap[]
  ) => {
    if (onTransactionFieldMapsChange) {
      onTransactionFieldMapsChange(fieldMaps);
    }
  };
  const handleEditClick = (col: Column<ITransaction>) => () => {
    if (editingField && col.field === editingField.field) {
      setEditingField(undefined);
    } else {
      setEditingField(col);
    }
  };
  const handleFieldMapChange = (fieldMap: ITransactionFieldMap) => {
    const field =
      editingField && (editingField.field as keyof ICreateTransactionInput);
    if (field) {
      let index = findIndex(transactionFieldMaps, ["field", field]);
      if (index === -1) {
        handleTransactionFieldMapChange([...transactionFieldMaps, fieldMap]);
      } else {
        const newFieldMaps = [...transactionFieldMaps];
        newFieldMaps[index] = fieldMap;
        handleTransactionFieldMapChange(newFieldMaps);
      }
    }
  };

  const potentialSources = keys(rawTransactions[0]);
  const field = editingField && (editingField.field as keyof ITransaction);
  const getDefaultValue = () => {
    if (field) {
      if (columnsByField[field]) {
        switch (columnsByField[field].type) {
          case "numeric": {
            return 0;
          }
          case "currency": {
            return 0;
          }
        }
      }
    }
  };

  const getFieldMap = (field?: keyof ITransaction) => {
    const fieldMap = field && fieldMapIndex[field];
    if (fieldMap) {
      return fieldMap;
    }

    const newFieldMap = {
      field,
      equation: field && guessFieldSource({ potentialSources, field }),
      defaultValue: getDefaultValue(),
    } as ITransactionFieldMap;

    handleFieldMapChange(newFieldMap);

    return newFieldMap;
  };

  const fieldMap = getFieldMap(field);

  return (
    <React.Fragment>
      <Table size="small">
        <TableHead>
          <TableRow>
            {map(columns, (col) => (
              <TableCell style={{ fontWeight: "bold" }} key={col.field}>
                <Grid container direction="column">
                  {(isEditable && (
                    <Button
                      endIcon={<EditIcon />}
                      onClick={handleEditClick(col)}
                    >
                      {col.title}
                    </Button>
                  )) ||
                    col.title}
                  {field === col.field && (
                    <Equation
                      fields={potentialSources}
                      title={col.field}
                      fieldMap={fieldMap}
                      fieldType={col.type}
                      onChange={handleFieldMapChange}
                    />
                  )}
                </Grid>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {map(rawTransactions, (rawTransaction, idx) => (
            <TableRow key={idx}>
              {map(columns, (col) => {
                let value: string | number;
                try {
                  const field = col.field as keyof ICreateTransactionInput;
                  const fMap = getFieldMap(field);
                  let fieldValue =
                    fMap && mapTransactionField(rawTransaction, fMap);
                  const type = col.type as keyof typeof commonFormatters;
                  if (
                    type &&
                    commonFormatters[type] &&
                    fieldValue !== undefined
                  ) {
                    value = commonFormatters[type](fieldValue as any);
                  } else {
                    value = fieldValue as string | number;
                  }
                  const customStyle = columnStyles[
                    col.field as keyof typeof columnStyles
                  ] as any;
                  const style = customStyle
                    ? customStyle(value)
                    : col.cellStyle
                    ? typeof col.cellStyle === "function"
                      ? col.cellStyle([], {
                          [field]: value,
                        } as unknown as ITransaction)
                      : col.cellStyle
                    : {};
                  return (
                    <TableCell key={col.field} style={style}>
                      {col.render
                        ? col.render(
                            { [field]: value } as unknown as ITransaction,
                            "row"
                          )
                        : value}
                    </TableCell>
                  );
                } catch (e) {
                  console.warn(e);
                  const fMap = getFieldMap(
                    col.field as keyof ICreateTransactionInput
                  );
                  return (
                    <TableCell key={col.field} style={{ color: "red" }}>
                      {e.message}
                      {fMap.fix && (
                        <IconButton
                          onClick={(evt) =>
                            fMap.fix && fMap.fix(rawTransaction, e)
                          }
                        >
                          <AccountBalanceIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
