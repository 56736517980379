import FormControl from "@material-ui/core/FormControl";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { pick, values } from "lodash";
import React from "react";
import { useMount } from "react-use";
import { search } from "../../hooks/search";
import { ICreateAccountInput } from "../../mutations/CreateAccount";
import { IIDInput } from "../../mutations/CreateTransactions";
import { AccountType } from "../../types/Account";
import { IInstitution } from "../../types/Institution";
import Select from "../Select";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);

export interface IAddBankAccountFormProps {
  institutions: IInstitution[];
  onChange: (account: Partial<ICreateAccountInput>) => void;
  initialAccount?: Partial<ICreateAccountInput>;
}

export enum InputType {
  TEXT_OR_NUMERIC,
  BOOLEAN,
  SELECT,
}

const getDefaultInput = (
  initialAccount: Partial<ICreateAccountInput> | undefined,
  institutions: IInstitution[]
) => {
  let institution;
  let type = AccountType.Chequeings;
  if (initialAccount && initialAccount.name) {
    institution = search(institutions, initialAccount.name, {
      keys: ["name"],
    }).pop();
    type =
      search(values(AccountType), initialAccount.name).pop() ||
      AccountType.Chequeings;
  } else {
    if (institutions.length > 0) {
      institution = institutions[0];
    }
  }

  return {
    institution,
    name: "",
    number: "",
    type,
    ...initialAccount,
  };
};

export const AddBankAccountForm = ({
  initialAccount,
  institutions,
  onChange,
}: IAddBankAccountFormProps) => {
  const classes = useStyles();

  const [account, setAccount] = React.useState<Partial<ICreateAccountInput>>(
    getDefaultInput(initialAccount, institutions)
  );

  useMount(() => {
    const newAccount = getDefaultInput(initialAccount, institutions);
    setAccount(newAccount);
    onChange({
      ...newAccount,
      institution: pick(newAccount.institution, "id") as IIDInput,
    });
  });

  const handleChange =
    (
      field: keyof ICreateAccountInput,
      inputType = InputType.TEXT_OR_NUMERIC,
      map?: (value: any) => any
    ) =>
    (event: any) => {
      const newAccount: Partial<ICreateAccountInput> = { ...account };
      let value: any;
      switch (inputType) {
        case InputType.TEXT_OR_NUMERIC:
          value = event.target.value;
          break;
        case InputType.BOOLEAN:
          value = event.target.checked;
          break;
        case InputType.SELECT:
          value = event;
          break;
        default:
          throw new Error("Unknown inputType: " + inputType);
      }
      if (map) {
        value = map(value);
      }
      newAccount[field] = value;
      setAccount(newAccount);
      onChange({
        ...newAccount,
        institution: pick(newAccount.institution, "id") as IIDInput,
      });
    };

  const accountTypes = values(AccountType);

  /**
        <FormControlLabel
          control={<Switch
            checked={account.isAsset}
            onChange={handleChange('isAsset', InputType.BOOLEAN)}
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />}
          label="Asset"
        />
        */
  return (
    <form className={classes.container}>
      <FormControl className={classes.formControl}>
        <TextField
          id="account-name-input"
          label="Account Name"
          className={classes.textField}
          value={account.name}
          onChange={handleChange("name")}
        />
        <Select
          label="Institution"
          value={account.institution as IInstitution}
          displayField="name"
          selections={institutions}
          onChange={handleChange("institution", InputType.SELECT)}
        />
        <TextField
          id="account-number-input"
          label="Account Number"
          className={classes.textField}
          value={account.number}
          onChange={handleChange("number")}
          margin="normal"
        />
        <Select
          label="Account Type"
          value={account.type}
          selections={accountTypes}
          onChange={handleChange("type", InputType.SELECT)}
        />
      </FormControl>
    </form>
  );
};
