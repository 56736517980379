import Fuse from "fuse.js";
import { map } from "lodash";

export const search = <TItem = any>(
  items: TItem[],
  search: string,
  options = {}
) => {
  options = {
    threshold: 0.8,
    ...options,
  };
  return map(new Fuse(items, options).search(search), "item");
};
