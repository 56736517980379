import { gql } from "apollo-boost";
import { BALANCE_FRAGMENT } from "./Balance";
import { TRANSFER_FRAGMENT } from "./Transfer";

export const TRANSACTION_BASE_FRAGMENT = gql`
  fragment TransactionBaseFragment on Transaction {
    id
    plaidTransactionId
    amount
    description
    originalDescription
    investment
    units
    price
    type
    date
    category
  }
`;
export const TEXT_LABEL_FRAGMENT = gql`
  fragment TextLabelFragment on TextLabelType {
    label
    start
    end
    value
  }
`;

export const NUMBER_LABEL_FRAGMENT = gql`
  fragment NumberLabelFragment on NumberLabelType {
    label
    start
    end
    value
  }
`;

export const QUOTE_FRAGMENT = gql`
  fragment QuoteFragment on QuoteType {
    date
    open
    close
    high
    low
    volume
  }
`;

export const SYMBOL_FRAGMENT = gql`
  fragment SymbolFragment on SymbolType {
    symbol
    name
    type
    marketOpen
    marketClose
    timezone
    currency
    matchScore
  }
`;

export const EXCHANGE_RATE_FRAGMENT = gql`
  fragment ExchangeRateFragment on ExchangeRateType {
    date
    open
    close
    high
    low
  }
`;

export const ASSET_LABEL_FRAGMENT = gql`
  ${SYMBOL_FRAGMENT}
  ${QUOTE_FRAGMENT}
  ${NUMBER_LABEL_FRAGMENT}
  ${TEXT_LABEL_FRAGMENT}
  ${EXCHANGE_RATE_FRAGMENT}
  fragment AssetLabelFragment on AssetLabelType {
    label
    start
    end
    value
    symbol {
      ...SymbolFragment
    }
    quote {
      ...QuoteFragment
    }
    properQuote {
      ...QuoteFragment
    }
    exchangeRate {
      ...ExchangeRateFragment
    }
    quantity {
      ...NumberLabelFragment
    }
    isPriceInRange
    price
    cost {
      ...NumberLabelFragment
    }
    currency {
      ...TextLabelFragment
    }
  }
`;

export const TRANSACTION_LABELS_FRAGMENT = gql`
  ${ASSET_LABEL_FRAGMENT}
  fragment TransactionLabelsFragment on Transaction {
    labels {
      description {
        ...AssetLabelFragment
      }
    }
  }
`;

export const TRANSACTION_BALANCES_FRAGMENT = gql`
  fragment TransactionBalancesFragment on Transaction {
    balances {
      id
      balance
      date
      isManual
      asset
    }
  }
`;

export const TRANSACTION_RELATIONS_FRAGMENT = gql`
  ${TRANSFER_FRAGMENT}
  ${BALANCE_FRAGMENT}
  fragment TransactionRelationsFragment on Transaction {
    id
    account {
      id
      name
      type
      currency
    }
    balances {
      ...BalanceFragment
    }
    outgoingTransfers {
      ...TransferFragment
    }
    incomingTransfers {
      ...TransferFragment
    }
  }
`;
/*
    balances {
      id
      balance
      date
      isManual
      asset
      assetPrice
      assetPriceType
    }
*/

export const TRANSACTION_FRAGMENT = gql`
  ${TRANSACTION_BASE_FRAGMENT}
  ${TRANSACTION_RELATIONS_FRAGMENT}
  ${TRANSACTION_LABELS_FRAGMENT}
  fragment TransactionFragment on Transaction {
    id
    ...TransactionBaseFragment
    ...TransactionRelationsFragment
    ...TransactionLabelsFragment
  }
`;

export const TRANSACTION_WITH_LABELS_FRAGMENT = TRANSACTION_FRAGMENT;
/*
export const TRANSACTION_WITH_LABELS_FRAGMENT = gql`
  ${TRANSACTION_FRAGMENT}
  ${TRANSACTION_LABELS_FRAGMENT}
  fragment TransactionWithLabelsFragment on Transaction {
    id
    ...TransactionFragment
    ...TransactionLabelsFragment
  }
`
*/
