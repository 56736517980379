import { CurrencyType } from ".";
import { ITransactionFieldMap } from "../mapper";
import { IBalance } from "./Balance";
import { IInstitution } from "./Institution";
import { IUser } from "./User";

export enum AccountType {
  Chequeings = "Chequeings",
  Savings = "Savings",
  Debt = "Debt",
  Credit = "Credit",
  Investment = "Investment",
}

export interface IAsset {
  symbol: string;
  isUnverified: boolean;
}

export interface IAccount {
  assets?: IAsset[];
  plaidId?: string;
  isActive: boolean;
  id: string;
  accountHolder: IUser;
  institution: IInstitution;
  nickname: string;
  name: string;
  isAsset: boolean;
  number: string;
  type: AccountType;
  csvHasHeader: boolean;
  csvTransactionFieldMaps: ITransactionFieldMap[];
  balances: IBalance[];
  currency: CurrencyType;
  currentBalances: IBalance[];
}
