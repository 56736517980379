import { createGlobalState } from "react-use";
import * as auth from "../lib/auth";
import { useUser } from "./user";

const useGlobalToken = createGlobalState<string | null>(auth.getToken());

export const useToken = () => {
  const [user, setUser] = useUser();
  const [token, setState] = useGlobalToken();
  const clearToken = () => {
    auth.clearToken();
    setState(null);
  };

  const setToken = (token?: string) => {
    if (!token) {
      clearToken();
      setUser(null);
    } else {
      auth.setToken(token);
      setState(token);
    }
  };

  return [token, setToken] as [string | null, (token?: string | null) => void];
};
