import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import DateRangeIcon from "@material-ui/icons/DateRange";
import RefreshIcon from "@material-ui/icons/Refresh";
import { filter, map, max, min, reduce, reverse, sortBy } from "lodash";
import moment from "moment";
import { TimeRange, TimeSeries } from "pondjs";
import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { DateRangePicker, isInclusivelyBeforeDay } from "react-dates";
import {
  ChartContainer,
  ChartRow,
  Charts,
  LineChart,
  Resizable,
  ScatterChart,
  YAxis,
} from "react-timeseries-charts";
import { useUnmount, useWindowSize } from "react-use";
import { formatCurrency } from "../helpers";
import theme from "../theme";
import Title from "./Title";

type ITimeRange = typeof TimeRange;

enum Range {
  WEEK = "WEEK",
  MONTH = "MONTH",
  QUARTER = "QUARTER",
  YEAR = "YEAR",
}

export interface IChartTitleWithDateDange {
  timerange: ITimeRange;
  onChange: (timerange: ITimeRange) => void;
  title: string;
  maxDate?: moment.Moment;
  onRefresh?: () => void;
  onTitleClick?: () => void;
}

const useStyles = makeStyles((theme) => ({
  hideWhenSmall: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  hideWhenMedium: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
}));

const DTPicker = ({
  timerange,
  onChange,
  maxDate = moment(),
  orientation = "horizontal",
  numberOfMonths = 2,
}: {
  timerange: ITimeRange | null;
  onChange: (timerange: ITimeRange) => void;
  orientation: "vertical" | "horizontal";
  numberOfMonths: 1 | 2 | 3;
  maxDate?: moment.Moment;
}) => {
  const [focusedInput, setFocusedInput] = React.useState<
    "startDate" | "endDate" | null
  >(null);
  useUnmount(() => {
    setFocusedInput(null);
  });
  const handleDatesChange = ({
    startDate,
    endDate,
  }: {
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
  }) => {
    let newTimerange: typeof TimeRange;
    if (startDate && endDate) {
      newTimerange = new TimeRange(startDate, endDate);
    } else if (startDate) {
      newTimerange = new TimeRange(startDate.toDate(), timerange.end());
    } else if (endDate) {
      newTimerange = new TimeRange(timerange.begin(), endDate.toDate());
    } else {
      console.warn("Unexpected path taken");
      return;
    }

    if (!newTimerange.equals(timerange)) {
      onChange(newTimerange);
    }
  };
  return (
    <DateRangePicker
      startDate={moment(timerange && timerange.begin())} //from date
      startDateId="start_date_id"
      endDate={moment(timerange && timerange.end())} //to Date
      endDateId="end_date_id"
      focusedInput={focusedInput}
      orientation={orientation}
      numberOfMonths={numberOfMonths}
      isOutsideRange={(day) => isInclusivelyBeforeDay(maxDate, day)}
      onFocusChange={setFocusedInput}
      onDatesChange={handleDatesChange}
    />
  );
};

const getTimerangeFromRange = (range: Range) => {
  const today = moment().startOf("day").toDate();
  let fromDate = moment(today).toDate();
  switch (range) {
    case Range.WEEK:
      fromDate.setDate(today.getDate() - today.getDay());
      break;
    case Range.MONTH:
      fromDate.setDate(1);
      break;
    case Range.QUARTER:
      fromDate.setMonth(Math.floor(fromDate.getMonth() / 4) * 4);
      fromDate.setDate(1);
      break;
    // case Range.YEAR:
    default:
      fromDate.setMonth(0);
      fromDate.setDate(1);
      break;
  }

  return new TimeRange(fromDate, today);
};

const DTTitle = ({
  title,
  onTitleClick,
  onRefresh,
}: {
  title: string;
  onTitleClick?: () => void;
  onRefresh?: () => void;
}) => (
  <Title>
    {(onTitleClick && (
      <Button onClick={onTitleClick} color="primary">
        {title}
      </Button>
    )) ||
      title}
    {onRefresh && (
      <IconButton onClick={onRefresh} color="primary">
        <RefreshIcon />
      </IconButton>
    )}
  </Title>
);

enum WindowSize {
  xSmall = "xs",
  small = "s",
  large = "l",
  medium = "m",
}

const DTButtons = ({
  size,
  range,
  onRangeChange,
  toggleDatePickerVisibility,
}: {
  size: WindowSize;
  range?: Range;
  onRangeChange: (range: Range) => () => void;
  toggleDatePickerVisibility: () => void;
}) => {
  const buttons: {
    handleClick: (e?: any) => void;
    style?: any;
    label?: string;
    icon?: ReactElement;
  }[] = [
    {
      handleClick: onRangeChange(Range.WEEK),
      style:
        range === Range.WEEK
          ? { color: "white", background: theme.palette.primary.main }
          : {},
      label:
        size === WindowSize.small
          ? "wk"
          : size === WindowSize.xSmall
          ? "w"
          : "week",
    },
    {
      handleClick: onRangeChange(Range.MONTH),
      style:
        range === Range.MONTH
          ? { color: "white", background: theme.palette.primary.main }
          : {},
      label:
        size === WindowSize.small
          ? "mth"
          : size === WindowSize.xSmall
          ? "m"
          : "month",
    },
    {
      handleClick: onRangeChange(Range.QUARTER),
      style:
        range === Range.QUARTER
          ? { color: "white", background: theme.palette.primary.main }
          : {},
      label:
        size === WindowSize.small
          ? "qtr"
          : size === WindowSize.xSmall
          ? "q"
          : "quarter",
    },
    {
      handleClick: onRangeChange(Range.YEAR),
      style:
        range === Range.YEAR
          ? { color: "white", background: theme.palette.primary.main }
          : {},
      label: size === WindowSize.xSmall ? "y" : "YTD",
    },
  ];

  if ([WindowSize.small, WindowSize.xSmall].includes(size)) {
    buttons.push({
      handleClick: toggleDatePickerVisibility,
      label: size === WindowSize.xSmall ? undefined : "custom",
      icon: size === WindowSize.xSmall ? <DateRangeIcon /> : undefined,
    });
  }

  return (
    <ButtonGroup color="primary" aria-label="primary button group" size="small">
      {map(buttons, (button) => {
        return (
          <Button
            style={button.style}
            onClick={button.handleClick}
            key={button.label}
          >
            {button.icon}
            {button.label}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

export function ChartTitleWithDateRange({
  title,
  timerange,
  maxDate,
  onChange,
  onTitleClick,
  onRefresh,
}: IChartTitleWithDateDange) {
  const classes = useStyles();
  const windowSize = useWindowSize();
  const size = useMemo(() => {
    const materialPixelSize = {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    };

    if (windowSize.width >= materialPixelSize.lg) {
      return WindowSize.large;
    } else if (windowSize.width < materialPixelSize.sm) {
      return WindowSize.xSmall;
    } else if (windowSize.width < materialPixelSize.md) {
      return WindowSize.small;
    } else {
      return WindowSize.medium;
    }
  }, []);
  const numberOfMonths = useMemo(
    () =>
      [WindowSize.small, WindowSize.xSmall].includes(size)
        ? 1
        : size === WindowSize.large
        ? 3
        : 2,
    [size]
  );
  const orientation = useMemo(
    () => (WindowSize.xSmall === size ? "vertical" : "horizontal"),
    [size]
  );
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const toggleDatePickerVisibility = () =>
    setIsDatePickerVisible(!isDatePickerVisible);
  const handleRangeChange = useCallback(
    (range: Range) => () => {
      onChange(getTimerangeFromRange(range));
    },
    [onChange]
  );

  const range = useMemo(() => {
    if (timerange) {
      for (const range of [
        Range.MONTH,
        Range.QUARTER,
        Range.WEEK,
        Range.YEAR,
      ]) {
        if (timerange.equals(getTimerangeFromRange(range))) {
          return range;
        }
      }
    }
  }, [timerange]);

  return (
    <Grid container justify="space-between">
      <Grid item className={classes.hideWhenMedium}>
        <DTPicker
          maxDate={maxDate}
          timerange={timerange}
          onChange={onChange}
          orientation={orientation}
          numberOfMonths={numberOfMonths}
        />
      </Grid>
      <Grid item>
        <DTTitle
          title={title}
          onTitleClick={onTitleClick}
          onRefresh={onRefresh}
        />
      </Grid>
      <Grid item>
        <DTButtons
          size={size}
          range={range}
          toggleDatePickerVisibility={toggleDatePickerVisibility}
          onRangeChange={handleRangeChange}
        />
      </Grid>
      <Grid
        item
        style={
          !isDatePickerVisible
            ? { display: "none" }
            : {
                position: "absolute",
                top: 100,
              }
        }
      >
        <DTPicker
          maxDate={maxDate}
          timerange={timerange}
          onChange={onChange}
          orientation={orientation}
          numberOfMonths={numberOfMonths}
        />
      </Grid>
    </Grid>
  );
}

export interface ISeries {
  label?: string;
  color?: string;
  style?: any;
  series: typeof TimeSeries;
  extrapolation?: typeof TimeSeries;
  manualSeries: typeof TimeSeries;
}
export interface ITimeSeriesSelection {
  series: ISeries | null;
  column: string | null;
}

export interface ITimeSeriesChart {
  timerange: ITimeRange;
  series: ISeries[];
  yField: string;
  yFormat?: string;
  emptySeriesMessage?: string;
  onSelectionChange?: (selection: ITimeSeriesSelection) => void;
}

// It would be good to actually measure this
const TRACKER_MARGIN = 4;
const TRACKER_FONT_WIDTH = 8;
const TRACKER_FONT_HEIGHT = 15;

export function TimeSeriesChart({
  timerange,
  series,
  yField,
  yFormat,
  emptySeriesMessage,
  onSelectionChange,
}: ITimeSeriesChart) {
  const [trackerData, setTrackerData] = React.useState<any>({});
  // TODO initialize selectionState using the selectedAccounts
  const [selectionState, setSelection] = React.useState<ITimeSeriesSelection>({
    column: null,
    series: null,
  });
  const { column: selection, series: selectedSeries } = selectionState;
  const [{ highlight, highlightSeries }, setHighlight] = React.useState<{
    highlight: string | null;
    highlightSeries: any | null;
  }>({
    highlight: null,
    highlightSeries: null,
  });
  const handleTrackerChange = useCallback(
    (t: any, scale: (...args: any[]) => any) => {
      const trackerInfoValues = t
        ? reverse(
            sortBy(
              reduce(
                series,
                (values, s) => {
                  if (
                    !selectedSeries ||
                    !highlightSeries ||
                    [highlightSeries, selectedSeries].includes(s)
                  ) {
                    const data = s.series.at(s.series.bisect(t));
                    if (data) {
                      const rawValue = data.data().get("amount");
                      if (rawValue) {
                        const value = formatCurrency(rawValue);
                        values.push({
                          label: s.label,
                          value,
                          rawValue,
                        });
                      }
                    }
                  }
                  return values;
                },
                [] as Array<{ rawValue: number; label?: string; value: string }>
              ),
              "rawValue"
            )
          )
        : [];

      const trackerInfoWidth =
        (max(
          map(
            trackerInfoValues,
            (s) => (s.label ? s.label.length : 0) + `${s.value}`.length
          )
        ) || 0) *
          TRACKER_FONT_WIDTH +
        TRACKER_MARGIN * 2;
      const trackerInfoHeight =
        (trackerInfoValues ? trackerInfoValues.length : 0) *
          TRACKER_FONT_HEIGHT +
        TRACKER_MARGIN * 2;
      setTrackerData({
        tracker: t,
        trackerInfoValues,
        trackerInfoHeight,
        trackerInfoWidth,
        trackerX: t && scale(t),
      });
    },
    [series, selectedSeries, highlightSeries]
  );

  const handleHighlightChange = useMemo(
    () => (highlightSeries: any) => (highlight: string) => {
      setHighlight({
        highlightSeries,
        highlight,
      });
    },
    []
  );

  const handleSetSelection = useMemo(
    () => (state: typeof selectionState) => {
      setSelection(state);
      if (onSelectionChange) {
        onSelectionChange(state);
      }
    },
    [onSelectionChange]
  );

  const handleSelectionChange = useCallback(
    (selectedSeries: any) => (selection: string) => {
      if (selectedSeries === selectionState.series) {
        handleSetSelection({
          series: null,
          column: null,
        });
      } else {
        handleSetSelection({
          series: selectedSeries,
          column: selection,
        });
      }
    },
    [selectionState, handleSetSelection]
  );

  const { minValue, maxValue } = useMemo(() => {
    let minValue = 0;
    let maxValue = 0;
    if (series.length) {
      minValue = min(map(series, (s) => s.series.min(yField)));
      maxValue = max(map(series, (s) => s.series.max(yField)));
    }
    const padding = max([10, (maxValue - minValue) * 0.05]);
    if (padding) {
      minValue -= padding;
      maxValue += padding;
    }
    return {
      minValue,
      maxValue,
    };
  }, [series]);

  if (series.length === 0) {
    return (
      <Grid
        container
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        <Title>{emptySeriesMessage || `No Data Found`}</Title>
      </Grid>
    );
  }

  return (
    <Resizable>
      <ChartContainer
        timeRange={timerange}
        trackerPosition={trackerData.tracker}
        onTrackerChanged={handleTrackerChange}
        onBackgroundClick={() =>
          handleSetSelection({
            column: null,
            series: null,
          })
        }
      >
        <ChartRow
          trackerInfoValues={trackerData.trackerInfoValues}
          trackerInfoHeight={trackerData.trackerInfoHeight}
          trackerInfoWidth={trackerData.trackerInfoWidth}
          height="380"
        >
          <Charts>
            {map(
              filter(series, (s) => s.manualSeries.size() > 0),
              (s, idx) => (
                <ScatterChart
                  key={s.label}
                  axis={yField}
                  columns={[yField]}
                  style={{
                    [yField]: {
                      normal: {
                        fill: s.color,
                      },
                      ...s.style,
                    },
                  }}
                  series={s.manualSeries}
                />
              )
            )}
            {map(
              filter(series, (s) => s.series.size() > 0),
              (s, idx) => (
                <LineChart
                  key={idx}
                  axis={yField}
                  columns={[yField]}
                  style={{
                    [yField]: {
                      normal: {
                        stroke: s.color,
                      },
                      selected: {
                        stroke: s.color,
                        strokeWidth: 3,
                      },
                      highlighted: {
                        stroke: s.color,
                        strokeWidth: 2,
                      },
                      muted: {
                        stroke: s.color,
                        opacity: 0.5,
                      },
                      ...s.style,
                    },
                  }}
                  interpolation="curveStepAfter"
                  selection={
                    selection &&
                    (s === selectedSeries ? selection : "NOT A COLUMN")
                  }
                  onSelectionChange={handleSelectionChange(s)}
                  highlight={s === highlightSeries ? highlight : null}
                  onHighlightChange={handleHighlightChange(s)}
                  series={s.series}
                />
              )
            )}
            {map(
              filter(
                series,
                (s) => s.extrapolation && s.extrapolation.size() > 0
              ),
              (s, idx) => (
                <LineChart
                  key={`${idx}:Extrapolate`}
                  axis={yField}
                  columns={[yField]}
                  style={{
                    [yField]: {
                      selected: {
                        stroke: s.color,
                        strokeDasharray: "10 5",
                        strokeWidth: 3,
                      },
                      highlighted: {
                        stroke: s.color,
                        strokeDasharray: "10 5",
                        strokeWidth: 2,
                      },
                      muted: {
                        stroke: s.color,
                        strokeDasharray: "10 5",
                        opacity: 0.5,
                      },
                      ...s.style,
                      normal: {
                        stroke: s.color,
                        strokeWidth: 1,
                        ...(s.style && s.style.normal),
                        strokeDasharray: "10 5",
                      },
                    },
                  }}
                  interpolation="curveStepAfter"
                  selection={
                    selection &&
                    (s === selectedSeries ? selection : "NOT A COLUMN")
                  }
                  onSelectionChange={handleSelectionChange(s)}
                  highlight={s === highlightSeries ? highlight : null}
                  onHighlightChange={handleHighlightChange(s)}
                  series={s.extrapolation}
                />
              )
            )}
          </Charts>
          <YAxis
            id={yField}
            format={yFormat}
            width="70"
            min={minValue}
            max={maxValue}
            type="linear"
          />
        </ChartRow>
      </ChartContainer>
    </Resizable>
  );
}
