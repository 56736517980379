import * as React from "react";
import { useEffect, useState } from "react";
import { useToken } from "../hooks/authentication";
import { useSyncInstitutions } from "../hooks/institution";
import { useUser } from "../hooks/user";
import { getTokenExpiryDateMS, isTokenExpired } from "../lib/auth";
import { useUser as useUserQuery } from "../queries/GetUser";
import { IUser } from "../types/User";
import LoginDialog from "./dialogs/LoginDialog";

const AuthenticationBoundary = ({ children }: any) => {
  const [expirationTimer, setExpirationTimer] = useState<any>();
  const [token, setToken] = useToken();
  const { getUser } = useUserQuery();
  const [user] = useUser();
  const { syncInstitutions } = useSyncInstitutions();
  let [isOpen, setIsOpen] = useState(!token);
  useEffect(() => {
    if (token) {
      if (isTokenExpired(token)) {
        setToken();
      } else {
        startExpirationTimer(token);
        if (!user) {
          getUser();
        }
      }
    } else {
      setIsOpen(true);
    }
  }, [token]);

  const startExpirationTimer = (token: string) => {
    if (expirationTimer) {
      clearTimeout(expirationTimer);
    }
    setExpirationTimer(
      setTimeout(() => {
        setToken();
      }, getTokenExpiryDateMS(token) - Date.now())
    );
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleLogin = ({ user }: { user: IUser; token: string }) => {
    syncInstitutions({ user });
  };

  if (!token) {
    return (
      <LoginDialog
        onLogin={handleLogin}
        isOpen={isOpen}
        onClose={handleClose}
      />
    );
  }

  return children;
};

export default AuthenticationBoundary;
