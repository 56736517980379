import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { IIDInput } from "./CreateTransactions";

export interface ISyncInstitutionInput {
  institution: IIDInput;
}

export const SYNC_INSTITUTION = gql`
  mutation SyncInstitution($input: SyncInstitutionInput!) {
    syncInstitution(input: $input) {
      success
      fromDate
      totalTransactions
    }
  }
`;

export interface IPlaidSync {
  success: boolean;
  fromDate: string;
}

export const useSyncInstitution = () => {
  const [syncInstitution, { called, loading, data, error }] = useMutation<
    {
      syncInstitution: IPlaidSync;
    },
    { input: ISyncInstitutionInput }
  >(SYNC_INSTITUTION, {
    update: (cache, { data }) => {
      // TODO update the cache maybe
      /*
      const newAccount = data && data.syncInstitution
      if (newAccount) {
        const cachedData = cache.readQuery<IGetAccountsResponse>({ query: GET_ACCOUNTS })
        const institutions = cachedData && cachedData.institutions || []
        cache.writeQuery({ query: GET_ACCOUNTS, data: {
          institutions: institutions.concat(newAccount)
        }})
      }
      */
    },
  });
  return {
    called,
    syncInstitution,
    loading,
    error,
    success: data && data.syncInstitution.success,
    fromDate: data && data.syncInstitution.fromDate,
  };
};
