import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { print } from "graphql/language/printer";
import { useEffect } from "react";
import { ACCOUNT_FRAGMENT } from "../fragments/Account";
import { defragment } from "../lib/graphqlTools";
import { IAccount } from "../types/Account";

export interface IGetAccountsResponse {
  accounts: IAccount[];
}

export const GET_ACCOUNTS = gql`
  ${defragment(`
    ${print(ACCOUNT_FRAGMENT)}
    query GetAccounts {
      accounts {
        ...AccountFragment
      }
    }
  `)}
`;

export const useAccounts = ({ isLazy = false } = {}) => {
  const [getAccounts, { loading, data, error, called }] =
    useLazyQuery<IGetAccountsResponse>(GET_ACCOUNTS, {
      fetchPolicy: "cache-and-network",
    });
  useEffect(() => {
    if (!isLazy && !called) {
      getAccounts();
    }
  }, []);
  return {
    getAccounts,
    loading,
    error,
    accounts: data && data.accounts,
  };
};
