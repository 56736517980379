import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { map } from "lodash";
import { ACCOUNT_FRAGMENT } from "../fragments/Account";
import { ITransactionField } from "../mapper";
import { AccountType, IAccount } from "../types/Account";
import { IIDInput } from "./CreateTransactions";

export interface IUpsertTransactionFieldMapInput {
  id: string;
  equation: string;
  defaultValue: string;
  field: ITransactionField;
}

export interface IUpdateAccountInput {
  id: string;
  name?: string;
  nickname?: string;
  number?: string;
  type?: AccountType;
  isActive?: boolean;
  institution?: IIDInput;
  csvHasHeader?: boolean;
  csvTransactionFieldMaps?: IUpsertTransactionFieldMapInput[];
}

export const UPSERT_ACCOUNT = gql`
  ${ACCOUNT_FRAGMENT}
  mutation UpdateAccount($account: UpdateAccountInput!) {
    updateAccount(account: $account) {
      ...AccountFragment
    }
  }
`;
export const useUpdateAccount = () => {
  const [update, { called, loading, data, error }] = useMutation<{
    updateAccount: {
      account: IAccount;
    };
  }>(UPSERT_ACCOUNT);

  const updateAccount = ({
    variables: { account },
  }: {
    variables: { account: IUpdateAccountInput };
  }) => {
    return update({
      variables: {
        account: {
          ...account,
          csvTransactionFieldMaps: map(
            account.csvTransactionFieldMaps,
            (fieldMap) => ({
              ...fieldMap,
              defaultValue:
                fieldMap.defaultValue === undefined
                  ? fieldMap.defaultValue
                  : `${fieldMap.defaultValue}`,
              __typename: undefined,
            })
          ),
        },
      },
    });
  };

  return {
    called,
    updateAccount,
    loading,
    error,
    account: data && data.updateAccount && data.updateAccount.account,
  };
};
