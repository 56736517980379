import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import moment from "moment";
import React from "react";
import { isInclusivelyBeforeDay, SingleDatePicker } from "react-dates";
import { ICreateBalanceInput } from "../../mutations/CreateBalance";
import { IBalance } from "../../types/Balance";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);

export interface IAddBalanceFormProps {
  initialBalance: Partial<ICreateBalanceInput>;
  onChange: (balance: ICreateBalanceInput) => void;
}

export const AddBalanceForm = ({
  initialBalance,
  onChange,
}: IAddBalanceFormProps) => {
  const classes = useStyles();

  const [datePickerFocused, setDatePickerFocus] = React.useState(false);
  const [balance, setBalance] = React.useState({
    ...initialBalance,
    date: moment(initialBalance.date),
  });

  const handleDateFocusChange = ({ focused }: { focused: boolean | null }) =>
    setDatePickerFocus(!!focused);

  const handleChange =
    (field: keyof typeof balance, valueField = "value") =>
    (event: any) => {
      const newBalance = { ...balance };
      switch (field) {
        case "balance":
          newBalance.balance = parseFloat(event.target[valueField]);
          break;
        case "date":
          newBalance.date = event;
          break;
        default:
          newBalance[field] = event.target[valueField];
          break;
      }
      setBalance(newBalance);
      onChange({
        ...newBalance,
        date: newBalance.date && newBalance.date.toDate(),
      } as IBalance);
    };

  return (
    <form className={classes.container}>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="balance-amount-input">Balance</InputLabel>
        <Input
          id="balance-amount-input"
          type="number"
          value={balance.balance}
          onChange={handleChange("balance")}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
        />
        <SingleDatePicker
          isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
          id="date-picker"
          focused={datePickerFocused}
          onFocusChange={handleDateFocusChange}
          date={balance.date}
          onDateChange={handleChange("date")}
        />
      </FormControl>
    </form>
  );
};
