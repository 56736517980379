import { createMuiTheme } from "@material-ui/core/styles";
import { merge } from "lodash";
import theme from "./theme.json";

const palette = {
  primary: { main: "#00838F" },
  secondary: { main: "#AB47BC" },
};

export default createMuiTheme(merge(theme, palette));
