import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Snackbar from "@material-ui/core/Snackbar";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useCallback, useState } from "react";
import { ITransfer } from "../../types/Transfer";
import { TransferForm } from "../forms/TransferForm";

export interface ITransferDialogProps {
  isOpen: boolean;
  initialTransfer: Partial<ITransfer>;
  onSubmit: (transfer: ITransfer) => void;
  onDelete: (transfer: ITransfer) => void;
  onClose: () => void;
}

export default function TransferDialog({
  onSubmit,
  onDelete,
  isOpen,
  onClose,
  initialTransfer,
}: ITransferDialogProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [transfer, setTransfer] = useState({
    ...initialTransfer,
    isManual: true,
  } as Partial<ITransfer>);
  const handleSubmit = useCallback(
    (evt) => {
      evt.stopPropagation();
      onSubmit(transfer as ITransfer);
    },
    [transfer]
  );
  const handleDelete = useCallback(
    (evt) => {
      evt.stopPropagation();
      onDelete(transfer as ITransfer);
    },
    [transfer]
  );
  const handleClose = () => {
    onClose();
  };

  const error = null as any;

  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={!!error}
        autoHideDuration={2500}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{error && error.message}</span>}
      />
      <Dialog
        fullScreen={fullScreen}
        disableBackdropClick
        disableEscapeKeyDown
        open={isOpen}
        onClose={handleClose}
      >
        <DialogTitle>Link Accounts</DialogTitle>
        <DialogContent>
          <TransferForm initialTransfer={transfer} onChange={setTransfer} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary">
            Save
          </Button>
          {transfer.id ? (
            <Button onClick={handleDelete} color="secondary">
              Delete
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
