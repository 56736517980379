import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { BALANCE_FRAGMENT } from "../fragments/Balance";
import { IBalance } from "../types/Balance";

export interface IRegenerateBalancesInput {
  accountId?: string;
  institutionId?: string;
  fromDate?: Date;
}

export const REGENERATE_BALANCES = gql`
  ${BALANCE_FRAGMENT}
  mutation RegenerateBalances(
    $accountId: ID
    $institutionId: ID
    $fromDate: DateTime
  ) {
    regenerateBalances(
      accountId: $accountId
      institutionId: $institutionId
      fromDate: $fromDate
    ) {
      ...BalanceFragment
    }
  }
`;

export const useRegenerateBalances = () => {
  const [regenerateBalances, { called, loading, data, error }] = useMutation<{
    regenerateBalances: IBalance[];
  }>(REGENERATE_BALANCES);
  return {
    called,
    regenerateBalances,
    loading,
    error,
    balance: data && data.regenerateBalances && data.regenerateBalances,
  };
};
