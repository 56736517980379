import FormControl from "@material-ui/core/FormControl";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import { getAccountName } from "../../helpers";
import { IAccount } from "../../types/Account";
import Select from "../Select";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);

export interface ISelectAccountFormProps {
  selectedAccount?: IAccount;
  accounts: IAccount[];
  fullWidth?: boolean;
  onChange: (account: IAccount) => void;
}

export const SelectAccountForm = ({
  onChange,
  selectedAccount,
  accounts,
  fullWidth,
}: ISelectAccountFormProps) => {
  const classes = useStyles();
  return (
    <form className={classes.container}>
      <FormControl className={classes.formControl} fullWidth={fullWidth}>
        <Select
          label="Account"
          value={selectedAccount}
          getDisplayName={getAccountName}
          selections={accounts}
          onChange={onChange}
        />
      </FormControl>
    </form>
  );
};
