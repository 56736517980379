import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import {
  filter,
  first,
  flatten,
  groupBy,
  includes,
  map,
  partition,
  uniqBy,
} from "lodash";
import moment from "moment";
import React, { useMemo } from "react";
import { hasTransfer } from "../../helpers";
import { useAccounts } from "../../hooks/accounts";
import { search } from "../../hooks/search";
import { useSearchText } from "../../hooks/state";
import { useTransactions } from "../../hooks/transactions";
import { AccountType } from "../../types/Account";
import { ITransaction } from "../../types/Transaction";
import ErrorCatcher from "../ErrorCatcher";
import { MonthlyAverages } from "../MonthlyAverages";
import { Transfers } from "../Transfers";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 340,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function ReportsView() {
  const classes = useStyles();
  const {
    accounts,
    error: accountsError,
    loading: accountsLoading,
  } = useAccounts();
  const input = useMemo(() => {
    return {
      accountIds: map(
        filter(accounts, (account) =>
          includes(
            [AccountType.Chequeings, AccountType.Credit, AccountType.Savings],
            account.type
          )
        ),
        "id"
      ),
      date: {
        from: moment().startOf("month").month(-12).toDate(),
        to: moment().toDate(),
      },
    };
  }, [accounts]);
  const {
    transactions,
    error: transactionsError,
    loading: transactionsLoading,
  } = useTransactions(input);
  const [searchText] = useSearchText();

  const { expenses, income, transfers } = useMemo(() => {
    const [searchedTransactions, transferTransactions] = partition(
      transactions && searchText
        ? search(transactions, searchText, {
            threshold: 0.3,
            keys: ["category"],
          })
        : transactions,
      (transaction) => !hasTransfer(transaction)
    );

    const { debit: expenses, credit: income } = groupBy(
      searchedTransactions,
      "type"
    );

    const getTransfers = (transaction: ITransaction) => [
      ...transaction.incomingTransfers,
      ...transaction.outgoingTransfers,
    ];
    const transfers = uniqBy(
      flatten(
        map(
          groupBy(transferTransactions, (transaction) => {
            return first(getTransfers(transaction))?.id;
          }),
          (transactions) => getTransfers(first(transactions)!)
        )
      ),
      "id"
    );

    return {
      expenses,
      income,
      transfers,
    };
  }, [transactions, searchText]);

  const loading = accountsLoading || transactionsLoading;
  const error = accountsError || transactionsError;

  return (
    <React.Fragment>
      <ErrorCatcher error={error} />
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3} justify="center">
          <Grid item xs={12} md={10} lg={8}>
            <Transfers transfers={transfers} />
          </Grid>
          <Grid item xs={12} md={6}>
            <MonthlyAverages
              loading={loading}
              title="Average Monthly Income"
              transactions={income}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MonthlyAverages
              loading={loading}
              initialOrder="asc"
              title="Average Monthly Expenses"
              transactions={expenses}
            />
          </Grid>
          {/* TODO Investment Holdings */}
          {/* TODO Investment Performance */}
          {/* TODO Recurring costs */}
        </Grid>
      </Container>
    </React.Fragment>
  );
}
