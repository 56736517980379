import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { filter, groupBy, map } from "lodash";
import moment from "moment";
import React, { useMemo } from "react";
import {
  formatCurrency,
  getBalanceStyle,
  getTotalBalance,
  hasTransfer,
} from "../helpers";
import { useSyncInstitutions } from "../hooks/institution";
import { useTransactions } from "../hooks/transactions";
import { IAccount } from "../types/Account";
import { IBalance } from "../types/Balance";
import { ITransaction, TransactionType } from "../types/Transaction";

export interface IAccountsSummaryProps {
  accounts: IAccount[];
  showInactiveAccounts: boolean;
  toggleInactiveAccounts: () => void;
}

const useStyles = makeStyles((theme) => ({
  left: {
    textAlign: "start",
  },
}));

const AccountsSummaryValue = ({
  balance,
  summary,
}: {
  balance: IBalance;
  summary?: Array<{ account: IAccount; total: IBalance }>;
}) => {
  const innerValue = (
    <div style={{ display: "flex" }}>
      <Typography
        component="span"
        variant="h6"
        style={getBalanceStyle(balance)}
      >
        {formatCurrency(balance.balance)}
      </Typography>
    </div>
  );

  if (summary) {
    return (
      <Tooltip
        title={map(summary, ({ account, total }) => (
          <div key={account.id}>
            {account.name} {formatCurrency(total.balance)}
          </div>
        ))}
      >
        {innerValue}
      </Tooltip>
    );
  } else {
    return innerValue;
  }
};

export const AccountsSummary = ({
  accounts,
  showInactiveAccounts,
  toggleInactiveAccounts,
}: IAccountsSummaryProps) => {
  const classes = useStyles();
  const { syncInstitutions } = useSyncInstitutions();
  const input = useMemo(
    () => ({
      date: {
        from: moment().startOf("month").subtract(1, "month").toDate(),
        to: moment().startOf("month").toDate(),
      },
    }),
    []
  );
  const { transactions } = useTransactions(input);
  const { income, expenses, net, incomeSummary, expensesSummary } =
    useMemo(() => {
      const relevantTransactions = filter(
        transactions,
        (transaction) => !hasTransfer(transaction)
      );
      const {
        [TransactionType.credit]: credits,
        [TransactionType.debit]: debits,
      } = groupBy(relevantTransactions, "type");
      console.log({
        relevantTransactions,
        credits,
        debits,
      });
      const getSummary = (transactions: ITransaction[]) =>
        map(groupBy(transactions, "account.id"), (transactions) => ({
          account: transactions[0].account,
          total: getTotalBalance(transactions),
        }));
      const results = {
        income: getTotalBalance(credits || []),
        expenses: getTotalBalance(debits || []),
        net: getTotalBalance(relevantTransactions),
        incomeSummary: getSummary(credits),
        expensesSummary: getSummary(debits),
      };

      /*
    let netSummary = getSummary(relevantTransactions);
    const expensesSummaryByAccountId = keyBy(results.expensesSummary, 'account.id');
    netSummary = map(netSummary, accountSummary => {
      const expenseSummary = expensesSummaryByAccountId[accountSummary.account.id]
      if (expenseSummary) {
        return {
          ...accountSummary,
          total: subtractBalances(accountSummary.total, expenseSummary.total),
        }
      }
      return accountSummary;
    });
    return {
      ...results,
      netSummary,
    };
    */
      return results;
    }, [transactions]);

  return (
    <Card>
      <Box p={1}>
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography variant="h6" color="primary" className={classes.left}>
              Income & Expenses
            </Typography>
          </Box>
          {/**
            <Box flexShrink={1}>
              <DropdownMenu items={[{
              onClick: syncInstitutions,
              icon: <SyncIcon />,
              title: 'Sync Institutions',
            }, {
                title: `${showInactiveAccounts ? 'Hide' : 'Show'} Inactive Accounts`,
                icon: <AccountBalanceIcon color="disabled" />,
                onClick: toggleInactiveAccounts,
              }]} />
            </Box>
            */}
        </Box>
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography
              variant="subtitle1"
              color="primary"
              className={classes.left}
            >
              Income
            </Typography>
          </Box>
          <Box flexShrink={1}>
            <AccountsSummaryValue balance={income} summary={incomeSummary} />
          </Box>
        </Box>
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography
              variant="subtitle1"
              color="primary"
              className={classes.left}
            >
              Savings
            </Typography>
          </Box>
          <Box flexShrink={1}>
            <AccountsSummaryValue balance={net} />
          </Box>
        </Box>
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography
              variant="subtitle1"
              color="primary"
              className={classes.left}
            >
              Expenses
            </Typography>
          </Box>
          <Box flexShrink={1}>
            <AccountsSummaryValue
              balance={expenses}
              summary={expensesSummary}
            />
          </Box>
        </Box>
      </Box>
    </Card>
  );
};
