import { get } from "lodash";
import { useEffect, useState } from "react";
import client from "../client";
import {
  GET_BALANCES,
  IGetBalancesInput,
  useBalances as useBalancesQuery,
} from "../queries/GetBalances";
import { IBalance } from "../types/Balance";
import { IExchangeRate } from "../types/ExchangeRate";
import { useEquivalentAmount } from "./equivalentAmounts";

export const useEquivalentBalances = (
  balances: IBalance[] | undefined,
  currency = "CAD"
) => {
  const {
    items: equivalentBalances,
    loading,
    error,
  } = useEquivalentAmount(balances, {
    currency,
    getCurrency: (balance) => get(balance, ["account", "currency"]),
    getDate: (balance) => balance.date,
    getResult: (balance, exchangeRate) => ({
      ...balance,
      equivalentBalance: balance.asset
        ? balance.balance
        : balance.balance * exchangeRate.close,
      equivalentAssetPrice:
        balance.assetPrice && balance.assetPrice * exchangeRate.close,
    }),
  });

  return {
    equivalentBalances,
    loading,
    error,
  };
};

export const useBalances = (
  input: IGetBalancesInput,
  { lazy = false, currency = "CAD" } = {}
) => {
  const [exchangeRateCache, setExchangeRateCache] = useState<
    Record<string, IExchangeRate[]>
  >({});

  const { getBalances, balances, ...results } = useBalancesQuery(input, {
    lazy,
  });
  const { equivalentBalances, loading, error } = useEquivalentBalances(
    balances,
    currency
  );

  useEffect(() => {
    client.writeQuery({
      query: GET_BALANCES,
      data: {
        balances: equivalentBalances,
      },
    });
  }, [equivalentBalances]);

  return {
    getBalances,
    balances: equivalentBalances,
    loading: results.loading || loading,
    error: results.error || error,
  };
};
