import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { omit } from "lodash";
import { PORTFOLIO_FRAGMENT } from "../fragments/Portfolio";
import { IPortfolio } from "../types/Portfolio";

export type IUpsertPortfolioInput = Partial<Omit<IPortfolio, "cashflows">>;

export const UPSERT_PORTFOLIO = gql`
  ${PORTFOLIO_FRAGMENT}
  mutation UpsertPortfolio($portfolio: UpsertPortfolioInput!) {
    upsertPortfolio(portfolio: $portfolio) {
      ...PortfolioFragment
    }
  }
`;

export const useUpsertPortfolio = () => {
  const [upsertPortfolio, { called, loading, data, error }] = useMutation<
    {
      upsertPortfolio: IPortfolio;
    },
    {
      portfolio: IUpsertPortfolioInput;
    }
  >(UPSERT_PORTFOLIO, {
    refetchQueries: ["GetPortfolios", "GetCashflows"],
  });

  const handleUpsertPortfolio = async (portfolio: IPortfolio) => {
    const portfolioInput = omit(portfolio, ["cashflows", "__typename"]) as any;
    const { data, errors } = await upsertPortfolio({
      variables: {
        portfolio: portfolioInput,
      },
    });
    if (errors) {
      throw errors;
    }
    return data?.upsertPortfolio!;
  };

  return {
    called,
    upsertPortfolio: handleUpsertPortfolio,
    loading,
    error,
    portfolio: data && data.upsertPortfolio,
  };
};
