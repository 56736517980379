import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";
import { Decimal } from "decimal.js";
import { filter, flatten, isUndefined, map, reduce, sortBy } from "lodash";
import moment from "moment";
import { Index, TimeRange, TimeSeries } from "pondjs";
import React, { useEffect, useLayoutEffect, useRef } from "react";
import {
  BarChart,
  Baseline,
  ChartContainer,
  ChartRow,
  Charts,
  Resizable,
  YAxis,
} from "react-timeseries-charts";
import { ITransaction, TransactionType } from "../types/Transaction";
import { ChartTitleWithDateRange } from "./Chart";
import { ErrorBoundary } from "./ErrorCatcher";
import { Progress } from "./Progress";
import Title from "./Title";

export interface IChartProps {
  disableTitle?: boolean;
  maxDate?: moment.Moment;
  barSize?: "day" | "week" | "month" | "year";
  timerange: typeof TimeRange;
  setTimerange: (timerange?: typeof TimeRange) => void;
  loading: boolean;
  transactions: ITransaction[];
  setSelectedTimeRange?: (timerange?: typeof TimeRange) => void;
  onTitleClick?: () => void;
}

const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;
const ONE_MONTH_IN_MS = 31 * ONE_DAY_IN_MS;
const ONE_YEAR_IN_MS = 365 * ONE_DAY_IN_MS;

const getBarSize = (timerange: any) => {
  if (!timerange) {
    return "day";
  }
  const dDate = timerange.duration();

  if (dDate < ONE_MONTH_IN_MS) {
    return "day";
  } else if (dDate < ONE_MONTH_IN_MS * 6) {
    return "week";
  } else if (dDate < ONE_YEAR_IN_MS * 3) {
    return "month";
  } else {
    return "year";
  }
};

const getBarCount = (
  timerange: any,
  barSize: "day" | "week" | "month" | "year"
) => {
  if (!timerange) {
    return 0;
  }
  const barDuration = timerange.duration();
  let barSizeMS;
  switch (barSize) {
    case "day": {
      barSizeMS = ONE_DAY_IN_MS;
      break;
    }
    case "week": {
      barSizeMS = ONE_DAY_IN_MS * 7;
      break;
    }
    case "month": {
      barSizeMS = ONE_MONTH_IN_MS;
      break;
    }
    default: {
      barSizeMS = ONE_YEAR_IN_MS;
      break;
    }
  }

  return Math.ceil(barDuration / barSizeMS);
};

export function CashflowChart({
  loading,
  transactions,
  timerange,
  setTimerange,
  barSize,
  maxDate,
  disableTitle = false,
  setSelectedTimeRange,
  onTitleClick,
}: IChartProps) {
  const theme = useTheme();
  barSize = barSize || getBarSize(timerange);
  const barCount = getBarCount(timerange, barSize);

  const getIndex = (date: Date | string | number | moment.Moment) => {
    if (!(date instanceof Date)) {
      date = new Date(moment(date).toDate());
      const offset = date.getTimezoneOffset();
      date.setUTCHours(Math.round(offset / 60), offset % 60);
    }
    if (barSize === "day") {
      return Index.getDailyIndexString(date);
    } else if (barSize === "week") {
      return Index.getIndexString("7d", date);
    } else if (barSize === "month") {
      return Index.getMonthlyIndexString(date);
    } else {
      return Index.getYearlyIndexString(date);
    }
  };

  const IN_INDEX = 0;
  const OUT_INDEX = 1;
  const points = map(
    reduce(
      sortBy(transactions, "date"),
      (points, transaction) => {
        const index = getIndex(transaction.date);
        points[index] = points[index] || [new Decimal(0), new Decimal(0)];

        if (!transaction.investment) {
          const amount = transaction.equivalentAmount || transaction.amount;
          if (transaction.type === TransactionType.credit) {
            points[index][IN_INDEX] = points[index][IN_INDEX].plus(amount);
          } else {
            points[index][OUT_INDEX] = points[index][OUT_INDEX].plus(amount);
          }
        }
        return points;
      },
      {} as { [index: string]: Decimal[] }
    ),
    (values, index) => [
      index,
      ...values,
      reduce(
        values,
        (sum, v, i) => (i === OUT_INDEX ? sum.minus(v) : sum.plus(v)),
        new Decimal(0)
      ),
    ]
  );

  const values = flatten(map(points, (p) => p.slice(3)));

  const series = new TimeSeries({
    name: "cashflow_history",
    columns: ["index", "in", "out", "net"],
    points,
  });

  const selectedSeries =
    timerange && transactions.length ? series.crop(timerange) : series;
  const maxAmount =
    transactions.length === 0
      ? new Decimal(0)
      : Decimal.max(
          0,
          selectedSeries.aggregate(
            (vals: Decimal[]) => Decimal.max(...filter(vals, (val) => !!val)),
            "in"
          ) || 0
        );
  const minAmount =
    transactions.length === 0
      ? new Decimal(0)
      : Decimal.min(
          0,
          -selectedSeries.aggregate(
            (vals: Decimal[]) => Decimal.max(...filter(vals, (val) => !!val)),
            "out"
          ) || 0
        );

  const infoStyle = {
    border: "5px solid red",
    fill: theme.palette.background.paper,
    stroke: theme.palette.text.primary,
    pointerEvents: "none",
  };

  const chartStyle = {
    in: {
      normal: { fill: theme.palette.success.main },
      highlighted: { fill: theme.palette.success.light },
      selected: { fill: theme.palette.success.dark },
      muted: { fill: theme.palette.success.light, opacity: 0.4 },
    },
    out: {
      normal: { fill: theme.palette.error.main },
      highlighted: { fill: theme.palette.error.light },
      selected: { fill: theme.palette.error.dark },
      muted: { fill: theme.palette.error.light, opacity: 0.4 },
    },
    net: {
      /*
      normal: {
        stroke: theme.palette.info.main,
        strokeWidth: 3,
      }
      */
      normal: { fill: theme.palette.primary.main },
      highlighted: { fill: theme.palette.primary.light },
      selected: { fill: theme.palette.primary.dark },
      muted: { fill: theme.palette.primary.light, opacity: 0.4 },
    },
  };

  const [highlight, setHighlight] = React.useState<any>();
  const [selection, setSelection] = React.useState<any>();

  useEffect(() => {
    if (setSelectedTimeRange) {
      setSelectedTimeRange(selection && selection.event.timerange());
    }
  }, [selection]);

  const ref = useRef(null as any);
  const [width, setWidth] = React.useState(32);
  const spacing = 6;

  useLayoutEffect(() => {
    if (ref.current) {
      setWidth(
        Math.floor((ref.current.offsetWidth / barCount - 2 * spacing) / 2)
      );
    }
  }, []);

  let infoValues: any[] = [];
  if (highlight) {
    const inflow = highlight.event.get("in");
    const outflow = highlight.event.get("out");
    const net = highlight.event.get("net");
    infoValues = [
      {
        label: "Net",
        value: `${net < 0 ? "-" : ""}$${Math.abs(Math.round(net * 100) / 100)}`,
      },
      /*
      {
        label: 'In',
        value: `$${Math.round(inflow * 100)/100}`
      },
      {
        label: 'Out',
        value: `$${Math.round(outflow * 100)/100}`
      },
       */
    ];
  }
  const averageNet = series.aggregate(
    (values: Decimal[]) =>
      reduce(values, (sum, value) => sum.plus(value), new Decimal(0))
        .div(values.length)
        .toFixed(2),
    "net"
  );

  return (
    <>
      {disableTitle ? null : (
        <ChartTitleWithDateRange
          maxDate={maxDate}
          timerange={timerange}
          onTitleClick={onTitleClick}
          onChange={setTimerange}
          title="Cashflow"
        />
      )}
      {loading ? <Progress /> : null}
      <div ref={ref}>
        {(!loading && points.length === 0 && (
          <Grid
            container
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <Title>No transactions found for the given time period</Title>
          </Grid>
        )) || (
          <Resizable>
            <ChartContainer
              timeRange={timerange}
              onBackgroundClick={() => setSelection(undefined)}
            >
              <ChartRow height="380">
                <Charts>
                  <BarChart
                    style={chartStyle}
                    axis="amount"
                    columns={["net"]}
                    series={series}
                    info={infoValues}
                    infoStyle={infoStyle}
                    highlighted={highlight}
                    onHighlightChange={setHighlight}
                    selected={selection}
                    onSelectionChange={setSelection}
                  />
                  <Baseline
                    axis="amount"
                    value={0}
                    style={{ line: { strokeDasharray: "none" } }}
                  />
                  <Baseline
                    axis="amount"
                    label={`Avg. $${averageNet}`}
                    position="right"
                    value={averageNet}
                    style={{
                      label: {
                        fontSize: 12,
                        fontWeight: "bold",
                        fill: !isUndefined(averageNet)
                          ? theme.palette.text.secondary
                          : "transparent",
                      },
                      line: {
                        stroke: !isUndefined(averageNet)
                          ? theme.palette.text.secondary
                          : "transparent",
                        strokeWidth: 1,
                        opacity: 0.6,
                      },
                    }}
                  />
                  {/**
                <BarChart
                  style={chartStyle}
                  axis="amount"
                  spacing={spacing}
                  columns={["in"]}
                  size={width}
                  series={series}
                  info={infoValues}
                  infoHeight={75}
                  infoWidth={130}
                  highlighted={highlight}
                  onHighlightChange={setHighlight}
                  selected={selection}
                  onSelectionChange={setSelection}
                />
                <BarChart
                  style={chartStyle}
                  axis="amount"
                  spacing={spacing}
                  columns={["out"]}
                  size={width}
                  offset={width}
                  series={series}
                  info={infoValues}
                  infoHeight={75}
                  infoWidth={130}
                  highlighted={highlight}
                  onHighlightChange={setHighlight}
                  selected={selection}
                  onSelectionChange={setSelection}
                />
                <LineChart
                  style={chartStyle}
                  axis="amount"
                  columns={["net"]}
                  series={series}
                />
                */}
                </Charts>
                <YAxis
                  id="amount"
                  min={minAmount}
                  max={maxAmount}
                  format="$.0f"
                  width="70"
                  type="linear"
                />
              </ChartRow>
            </ChartContainer>
          </Resizable>
        )}
      </div>
    </>
  );
}

export const CashflowChartBoundary = (props: any) => {
  return (
    <ErrorBoundary>
      <CashflowChart {...props} />
    </ErrorBoundary>
  );
};

export default CashflowChartBoundary;
