import DateFnsUtils from "@date-io/date-fns";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { filter, keys, mapValues, omit, round } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useToast } from "../../hooks/toast";
import {
  calculateMortgageCashflow,
  ICalculateMortgageCashflow,
} from "../../lib/calculators";
import { IMortgageCashflow, PaymentFrequency } from "../../types/Cashflow";
import Select from "../Select";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    justifyContent: "space-between",
  },
  item: {
    padding: theme.spacing(2),
    fontSize: 16,
  },
  itemControl: {
    width: "100%",
  },
  fixedHeight: {
    height: 480, // Set this based on device size
  },
}));

export interface IMortgageFormProps {
  onChange?: (mortgage: ICalculateMortgageCashflow) => void;
  onSubmit?: (mortgage: IMortgageCashflow) => void;
  showCompute?: boolean;
  initialValue?: IMortgageCashflow;
}

const NON_NUMERIC_PROPERTIES = [
  "description",
  "title",
  "notes",
  "paymentPeriod",
  "startDate",
  "id",
  "parentId",
];

export const useComputeMortgage = () => {
  const [, setToast] = useToast();
  return useCallback((options: ICalculateMortgageCashflow) => {
    console.log("computing", options);
    try {
      const missingKeys: Array<string | number> = filter(
        keys(options),
        (key: keyof ICalculateMortgageCashflow) =>
          options[key] === null ||
          (options[key] as any) === "" ||
          (typeof options[key] === "number" && isNaN(options[key] as number))
      ) as any;
      console.log(missingKeys);
      if (missingKeys.length === 1) {
        const cashflow = calculateMortgageCashflow(
          mapValues(omit(options, missingKeys[0]), (val, key) =>
            NON_NUMERIC_PROPERTIES.includes(key) ? val : parseFloat(val)
          )
        );
        setToast({
          message: `Successfully calculated the ${missingKeys[0]}`,
          severity: "success",
        });
        return {
          ...cashflow,
          annualInterestRate: round(cashflow.annualInterestRate, 4),
        } as any;
      } else if (missingKeys.length === 0) {
        const newOptions = mapValues(omit(options, "payment"), (val, key) =>
          NON_NUMERIC_PROPERTIES.includes(key) ? val : parseFloat(val as any)
        );
        const cashflow = calculateMortgageCashflow(newOptions as any);
        if (cashflow.payment !== parseFloat((options as any).payment as any)) {
          setToast({
            message:
              "The current values are invalid. Please clear one and re-compute.",
            severity: "warning",
          });
        } else {
          setToast({
            message: `Nothing to do. The current values are all valid!`,
            severity: "success",
          });
          return cashflow;
        }
      } else {
        setToast({
          message:
            "Not enough info! Please fill in more of the inputs. All but one is needed.",
          severity: "warning",
        });
      }
    } catch (err) {
      console.log(err);
    }
  }, []);
};

export const MortgageForm = ({
  onChange,
  onSubmit,
  showCompute = false,
  initialValue,
}: IMortgageFormProps) => {
  const classes = useStyles();
  const [, setToast] = useToast();

  const [state, setState] = useState<Record<string, string | number>>({
    startDate: moment() as unknown as string,
    askingPrice: 0,
    downDeposit: 0,
    paymentPeriod: PaymentFrequency.MONTHLY,
    years: 25,
    payment: "",
    annualInterestRate: 0.02,
    ...(omit(initialValue, ["interestRate"]) as any),
  });

  const handleChange = useCallback(
    (property, mapToState?: (value: string | number) => string | number) =>
      (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = evt.target.value;
        const newState: typeof state = omit(state, property);
        if (value !== "") {
          newState[property] = mapToState ? mapToState(value) : value;
        } else {
          // TODO Get rid of strings in the state where numbers should live
          newState[property] = "";
        }
        setState(newState);
      },
    [state]
  );

  useEffect(() => {
    onChange &&
      onChange({
        ...state,
      } as unknown as ICalculateMortgageCashflow);
  }, [state]);

  const calculateMortgageCashflow = useComputeMortgage();
  const handleClickCompute = useCallback(
    (evt: any) => {
      const computed = calculateMortgageCashflow({
        ...state,
      });
      if (computed) {
        setState(computed);
        onSubmit && onSubmit(computed);
      }
    },
    [state]
  );

  const handleChangeStartDate = useCallback(
    (startDate: string | null | Date) => {
      setState({
        ...state,
        startDate: startDate as string,
      });
    },
    [state]
  );

  return (
    <Grid container className={classes.container}>
      <Grid className={classes.item} item xs={12} sm={4}>
        <FormControl className={classes.itemControl}>
          <InputLabel htmlFor="standard-text">Title</InputLabel>
          <Input
            id="standard-text"
            value={state.title || "untitled"}
            onFocus={(event) => event.target.select()}
            onChange={handleChange("title")}
          />
        </FormControl>
      </Grid>
      <Grid className={classes.item} item xs={12} sm={8}>
        <FormControl className={classes.itemControl}>
          <InputLabel htmlFor="standard-text">Description</InputLabel>
          <Input
            id="standard-text"
            value={state.description}
            onFocus={(event) => event.target.select()}
            onChange={handleChange("description")}
          />
        </FormControl>
      </Grid>
      <Grid className={classes.item} item xs={6} sm={4}>
        <FormControl className={classes.itemControl}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="none"
              id="start-date-picker"
              label="Start Date"
              value={state.startDate}
              onChange={handleChangeStartDate}
              onFocus={(event) => event.target.select()}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </FormControl>
      </Grid>
      <Grid className={classes.item} item xs={6} sm={4}>
        <FormControl className={classes.itemControl}>
          <InputLabel htmlFor="standard-adornment-amount">
            Asking Price
          </InputLabel>
          <Input
            id="standard-adornment-amount"
            value={state.askingPrice}
            onChange={handleChange("askingPrice")}
            onFocus={(event) => event.target.select()}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          />
        </FormControl>
      </Grid>
      <Grid className={classes.item} item xs={6} sm={4}>
        <FormControl className={classes.itemControl}>
          <InputLabel htmlFor="standard-adornment-amount">
            Down Deposit
          </InputLabel>
          <Input
            id="standard-adornment-amount"
            value={state.downDeposit}
            onChange={handleChange("downDeposit")}
            onFocus={(event) => event.target.select()}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          />
        </FormControl>
      </Grid>
      <Grid className={classes.item} item xs={6} sm={4}>
        <FormControl className={classes.itemControl}>
          <InputLabel htmlFor="standard-adornment-amount">Payment</InputLabel>
          <Input
            id="standard-adornment-amount"
            value={state.payment}
            onChange={handleChange("payment")}
            onFocus={(event) => event.target.select()}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          />
        </FormControl>
      </Grid>
      <Grid className={classes.item} item xs={6} sm={4}>
        <FormControl className={classes.itemControl}>
          <InputLabel htmlFor="standard-number">Years</InputLabel>
          <Input
            id="standard-number"
            value={state.years}
            onFocus={(event) => event.target.select()}
            onChange={handleChange("years")}
          />
        </FormControl>
      </Grid>
      <Grid className={classes.item} item xs={6} sm={4}>
        <FormControl className={classes.itemControl}>
          <Select
            label="Payment Period"
            value={state.paymentPeriod as any}
            selections={[
              PaymentFrequency.WEEKLY,
              PaymentFrequency.BI_WEEKLY,
              PaymentFrequency.MONTHLY,
              PaymentFrequency.ANNUALLY,
            ]}
            onChange={(value) =>
              handleChange("paymentPeriod")({ target: { value } } as any)
            }
          />
        </FormControl>
      </Grid>
      <Grid className={classes.item} item xs={6} sm={4}>
        <FormControl className={classes.itemControl}>
          <InputLabel htmlFor="standard-percent">
            Annual Interest Rate
          </InputLabel>
          <Input
            id="standard-percent"
            value={
              state.annualInterestRate === ""
                ? ""
                : Math.round((state.annualInterestRate as number) * 100 * 1e5) /
                  1e5
            }
            onChange={handleChange("annualInterestRate", (v: any) => v / 100)}
            onFocus={(event) => event.target.select()}
            endAdornment={<InputAdornment position="end">%</InputAdornment>}
          />
        </FormControl>
      </Grid>
      {showCompute ? (
        <Grid className={classes.item} item xs={6} sm={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickCompute}
          >
            Compute
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
};
