import { format } from "graphql-formatter";

const getClosingBracket = (query: string) => {
  let openBracketIndex = query.indexOf("{");
  let closeBracketIndex = query.indexOf("}");
  let bracketDepth = 0;
  while (openBracketIndex > -1) {
    if (openBracketIndex < closeBracketIndex) {
      bracketDepth++;
      const nextOpenIndex = query.slice(openBracketIndex + 1).indexOf("{");
      openBracketIndex =
        nextOpenIndex > -1
          ? nextOpenIndex + openBracketIndex + 1
          : nextOpenIndex;
    } else {
      if (bracketDepth === 1) {
        break;
      }
      bracketDepth--;
      const nextClosingIndex = query.slice(closeBracketIndex + 1).indexOf("}");
      closeBracketIndex =
        nextClosingIndex > -1
          ? nextClosingIndex + closeBracketIndex + 1
          : nextClosingIndex;
    }
  }
  return closeBracketIndex;
};

export const defragment = (query: string) => {
  const fragments: Record<string, string> = {};
  let fragmentIndex = query.indexOf("fragment");
  let defragmentedQuery = query;
  while (fragmentIndex > -1) {
    const fragmentClosingIndex = getClosingBracket(defragmentedQuery);
    const fragmentOpenIndex = defragmentedQuery.indexOf("{") + 1;
    const fragmentContent = defragmentedQuery.slice(
      fragmentOpenIndex,
      fragmentClosingIndex
    );
    const fragmentName = defragmentedQuery
      .slice(fragmentIndex + 9, fragmentOpenIndex)
      .trim()
      .split(" ")[0];
    fragments[fragmentName] = fragmentContent;
    defragmentedQuery = defragmentedQuery.slice(fragmentClosingIndex + 1);
    fragmentIndex = defragmentedQuery.indexOf("fragment");
  }

  let spreadIndex = defragmentedQuery.indexOf("...");
  while (spreadIndex > -1) {
    const fragmentName = defragmentedQuery
      .slice(spreadIndex + 3)
      .split(" ")[0]
      .trim();
    if (!fragments[fragmentName]) {
      throw new Error(`No fragment found for ${fragmentName}`);
    }
    defragmentedQuery = `${defragmentedQuery.slice(0, spreadIndex)}
      ${fragments[fragmentName]}
    ${defragmentedQuery.slice(spreadIndex + 3 + fragmentName.length)}`;

    spreadIndex = defragmentedQuery.indexOf("...");
  }

  defragmentedQuery = format(defragmentedQuery.trim());

  return defragmentedQuery;
};
