import jwtDecode from "jwt-decode";
const TOKEN_SESSION_KEY = "token";

export const getToken = () => window.sessionStorage.getItem(TOKEN_SESSION_KEY);
export const clearToken = () =>
  window.sessionStorage.removeItem(TOKEN_SESSION_KEY);
export const setToken = (token: string) =>
  window.sessionStorage.setItem(TOKEN_SESSION_KEY, token);
export const decodeToken = (token: string) => jwtDecode<any>(token);
export const getTokenExpiryDateMS = (token?: string) =>
  token ? decodeToken(token).exp * 1000 : Date.now();
export const isTokenExpired = (token: string) =>
  getTokenExpiryDateMS(token) < Date.now();
export const isTokenValid = (token: string | null | undefined) => {
  return token && !isTokenExpired(token);
};
