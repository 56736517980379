/* eslint-disable no-script-url */

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography, { TypographyProps } from "@material-ui/core/Typography";
import { Button } from "antd";
import { find } from "lodash";
import React, { useMemo } from "react";
import {
  formatCurrency,
  formatDate,
  getBalanceStyle,
  getEquivalentCashBalance,
  getTotalBalance,
  prettyDate,
} from "../helpers";
import {
  IRegenerateBalancesInput,
  useRegenerateBalances,
} from "../mutations/RegenerateBalances";
import { IAccount } from "../types/Account";
import { IBalance } from "../types/Balance";
import DialogSelect from "./dialogs/DialogSelect";
import { Progress } from "./Progress";
import Title from "./Title";

const useStyles = makeStyles((theme) => ({
  depositContext: {},
  balanceContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignContent: "center",
    alignItems: "center",
  },
  title: {
    marginRight: 5,
  },
}));

export interface IBalanceOldProps {
  loading?: boolean;
  selectedAccount?: IAccount;
  onAccountChange?: (account: IAccount) => void;
  accounts?: IAccount[];
}

export interface IBalanceProps {
  balance: IBalance;
  title?: string;
  variant?: TypographyProps["variant"];
  slim?: boolean;
}

export interface IMinimalBalanceProps {
  balance: IBalance;
  variant?: TypographyProps["variant"];
}

const MinimalBalance = ({ balance, variant }: IMinimalBalanceProps) => (
  <Typography
    component="span"
    variant={variant}
    style={getBalanceStyle(balance.balance)}
  >
    {formatCurrency(getEquivalentCashBalance(balance))}
  </Typography>
);

export const Balance = ({
  balance,
  title,
  variant = "h6",
  slim,
}: IBalanceProps) => {
  const classes = useStyles();
  const dateVariant = useMemo(() => {
    let dateVariant: TypographyProps["variant"] = "subtitle1";
    switch (variant) {
      case "h6":
        dateVariant = "subtitle1";
        break;
      case "subtitle1":
        dateVariant = "subtitle2";
        break;
      case "subtitle2":
        dateVariant = "body1";
        break;
      case "body1":
        dateVariant = "body2";
        break;
    }
    return dateVariant;
  }, [variant]);

  if (slim) {
    return (
      <Tooltip placement="left" title={`on ${prettyDate(balance.date)}`}>
        <span className={classes.balanceContainer}>
          <MinimalBalance balance={balance} variant={variant} />
        </span>
      </Tooltip>
    );
  }

  return (
    <Grid
      container
      alignItems="flex-start"
      justify="flex-start"
      direction="row"
    >
      {title && (
        <Grid item>
          <Typography
            component="p"
            color="primary"
            className={classes.title}
            variant={variant}
          >{`${title}:`}</Typography>
        </Grid>
      )}
      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <MinimalBalance balance={balance} variant={variant} />
          </Grid>
          <Grid item>
            <Typography
              color="textSecondary"
              variant={dateVariant}
              className={classes.depositContext}
            >
              on {prettyDate(balance.date)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default function BalanceOld({
  loading,
  selectedAccount,
  accounts,
  onAccountChange,
}: IBalanceOldProps) {
  const classes = useStyles();
  const [isOpen, setDialogOpen] = React.useState(false);
  const toggleDialog = () => setDialogOpen(!isOpen);
  const { regenerateBalances, loading: regenerateLoading } =
    useRegenerateBalances();

  const handleAccountChange = (account: IAccount) => {
    toggleDialog();
    onAccountChange && onAccountChange(account);
  };

  const handleRegenerateBalancesClick = async () => {
    const variables: IRegenerateBalancesInput = {};
    if (selectedAccount) {
      variables["accountId"] = selectedAccount.id;
    }
    await regenerateBalances({
      variables,
      refetchQueries: ["GetTransactions"],
    });
  };

  accounts = accounts || [];

  let balanceStyle: any;
  let title: string;
  let amount: string;
  let date: string;
  if (selectedAccount) {
    // TODO Convert all balances to $CAD using conversion tools
    const cashBalance = find(selectedAccount.currentBalances, ["asset", null]);

    if (cashBalance) {
      balanceStyle = getBalanceStyle(cashBalance.balance);
      amount = formatCurrency(cashBalance.balance);
      date = formatDate(cashBalance.date);
    } else {
      balanceStyle = getBalanceStyle(0);
      amount = formatCurrency(0);
      date = formatDate(new Date());
    }
    title = selectedAccount.name;
  } else {
    const total = getTotalBalance(accounts);
    balanceStyle = getBalanceStyle(total);
    title = "All Accounts";
    amount = formatCurrency(total);
    //const minDate = min(map(accounts, account => account.balance ? account.balance.date : new Date()))
    const minDate = undefined;
    date = formatDate(minDate ? new Date(minDate) : new Date());
  }

  return (
    <React.Fragment>
      {onAccountChange && (
        <DialogSelect
          isOpen={isOpen}
          title="Pick an account"
          label="Account"
          noneTitle="All Accounts"
          value={selectedAccount}
          selections={accounts}
          displayField="name"
          onSubmit={handleAccountChange}
          onClose={toggleDialog}
        />
      )}
      <Title>{title}</Title>
      {loading ? (
        <Progress />
      ) : (
        <React.Fragment>
          <Typography component="p" variant="h4" style={balanceStyle}>
            {amount}
          </Typography>
          <Typography color="textSecondary" className={classes.depositContext}>
            on {date}
          </Typography>
        </React.Fragment>
      )}
      <div>
        <Button
          loading={regenerateLoading}
          onClick={handleRegenerateBalancesClick}
        >
          Regenerate Balances
        </Button>
        {onAccountChange && (
          <Button onClick={toggleDialog}>Select Account</Button>
        )}
      </div>
    </React.Fragment>
  );
}
