import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { IPortfolio } from "../types/Portfolio";

export interface IIDInput {
  id: string;
}

export const DELETE_PORTFOLIO = gql`
  mutation DeletePortfolio($id: ID!) {
    deletePortfolio(id: $id) {
      id
    }
  }
`;

export const useDeletePortfolio = () => {
  const [deletePortfolio, { called, loading, data, error }] = useMutation<
    {
      deletePortfolio: IPortfolio;
    },
    IIDInput
  >(DELETE_PORTFOLIO, {
    refetchQueries: ["GetPortfolios"],
  });

  const handleDeletePortfolio = async (portfolio: IPortfolio) => {
    if (portfolio.id) {
      return deletePortfolio({
        variables: {
          id: portfolio.id,
        },
      });
    }
  };

  return {
    called,
    deletePortfolio: handleDeletePortfolio,
    loading,
    error,
    portfolio: data && data.deletePortfolio,
  };
};
