import { makeVar, useReactiveVar } from "@apollo/client";
import { filter, isEqual, map, mapValues, merge, omit } from "lodash";
import moment from "moment";
import { TimeRange } from "pondjs";
import { useCallback, useEffect, useMemo } from "react";
import { useLocalStorage } from "react-use";
import {
  ArrayParam,
  ObjectParam,
  StringParam,
  useQueryParam,
} from "use-query-params";
import makeVarPersisted from "../lib/makeVarPersisted";
import { IAccount } from "../types/Account";
import { IPortfolio } from "../types/Portfolio";
import { useAccounts } from "./accounts";

export const useSearchText = () => {
  const [search, setSearch] = useQueryParam("search", StringParam);
  const handleSetSearch = useCallback(
    (search?: string | null) => {
      if (!search) {
        setSearch(undefined);
      } else {
        setSearch(search);
      }
    },
    [search, setSearch]
  );
  return [search, handleSetSearch] as [typeof search, typeof handleSetSearch];
};

export const useSelectedAccountNames = () =>
  useQueryParam("accounts", ArrayParam);

export interface IIntegrationSyncStatus {
  isSyncing?: boolean;
  error?: Error;
  warning?: string;
}
export interface ISyncStatus {
  [integrationId: string]: IIntegrationSyncStatus;
}
const syncStatusPersistedVar = makeVarPersisted<ISyncStatus>(
  {},
  {
    storage: window.sessionStorage,
    storageKey: "syncStatus",
  }
);
const syncStatusVar = makeVar<ISyncStatus>({});
export const useSyncStatus = () => {
  const syncStatusPerisisted = useReactiveVar(syncStatusPersistedVar);
  const syncStatus = useReactiveVar(syncStatusVar);
  const setSyncStatus = (syncStatus: ISyncStatus) => {
    syncStatusVar(syncStatus);
    const newSyncStatusPerisisted = {
      ...syncStatusPerisisted,
      ...mapValues(syncStatus, (institutionSyncStatus) =>
        omit(institutionSyncStatus, ["isSyncing"])
      ),
    };
    syncStatusPersistedVar(newSyncStatusPerisisted);
  };
  return [
    useMemo(
      () => merge(syncStatusPerisisted, syncStatus),
      [syncStatus, syncStatusPerisisted]
    ),
    setSyncStatus,
  ] as [ISyncStatus, typeof setSyncStatus];
};

export const useInstitutionSyncStatus = (integrationId: string) => {
  const [syncStatus, setSyncStatus] = useSyncStatus();
  const institutionSyncStatus: IIntegrationSyncStatus = syncStatus[
    integrationId
  ] || { isSyncing: false };
  const setIntegrationSyncStatus = (
    integrationSyncStatus: IIntegrationSyncStatus
  ) =>
    setSyncStatus({
      ...syncStatus,
      [integrationId]: institutionSyncStatus,
    });
  return [institutionSyncStatus, setIntegrationSyncStatus] as [
    typeof institutionSyncStatus,
    typeof setIntegrationSyncStatus
  ];
};

export const useSelectedAccounts = () => {
  const { accounts } = useAccounts();
  const [accountNames, setAccountNames] = useSelectedAccountNames();
  const setAccounts = useMemo(
    () => (accounts: IAccount[]) => {
      if (accounts.length === 0) {
        setAccountNames(undefined);
      } else {
        setAccountNames(map(accounts, "name"));
      }
    },
    [setAccountNames]
  );
  const selectedAccounts = useMemo(() => {
    if (accounts && accountNames && accountNames.length) {
      return filter(accounts, (account) => accountNames.includes(account.name));
    }
    return [];
  }, [accounts, accountNames]);
  return [selectedAccounts, setAccounts] as [IAccount[], typeof setAccounts];
};

export const useTimerange = (defaultTimerange?: typeof TimeRange) => {
  const [timerangeParam, setTimerangeParam] = useQueryParam(
    "timerange",
    ObjectParam
  );
  const timerange = useMemo(
    () =>
      timerangeParam && timerangeParam.begin && timerangeParam.end
        ? new TimeRange(
            moment(timerangeParam.begin, "YYYY-MM-DD"),
            moment(timerangeParam.end, "YYYY-MM-DD")
          )
        : null,
    [timerangeParam]
  );
  const setTimerange = useMemo(
    () => (timerange: typeof TimeRange) => {
      const begin = timerange.begin();
      const end = timerange.end();
      const newTimerangeParam = {
        begin: moment(begin).format("YYYY-MM-DD"),
        end: moment(end).format("YYYY-MM-DD"),
      };
      if (!isEqual(timerangeParam, newTimerangeParam)) {
        setTimerangeParam(newTimerangeParam);
      }
    },
    [timerangeParam, setTimerangeParam]
  );
  useEffect(() => {
    if (defaultTimerange && !timerange) {
      setTimerange(defaultTimerange);
    }
  }, []);
  return [timerange, setTimerange] as [typeof TimeRange, typeof setTimerange];
};

export enum ChartMode {
  balance = "balance",
  cashflow = "cashflow",
}

export const useChartMode = (defaultChartMode?: ChartMode) => {
  const [plot, setPlot] = useQueryParam("plot", StringParam);
  useEffect(() => {
    if (defaultChartMode && !plot) {
      setPlot(defaultChartMode);
    }
  }, []);
  return [plot, setPlot] as [
    ChartMode | null | undefined,
    (chartMode?: ChartMode | null) => void
  ];
};

const DEFAULT_PORTFOLIO = { cashflows: [] };

export const useCurrentPortfolio = (initialPortfolio?: IPortfolio) => {
  const [portfolio, setPortfolio, removePortfolio] = useLocalStorage(
    "portfolio",
    initialPortfolio || DEFAULT_PORTFOLIO
  ) as any;
  const setter = useMemo(
    () => (portfolio?: IPortfolio) => {
      if (portfolio === undefined) {
        removePortfolio();
      } else {
        setPortfolio(portfolio);
      }
    },
    []
  );
  return [portfolio || DEFAULT_PORTFOLIO, setter] as [
    IPortfolio,
    typeof setter
  ];
};
