import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useTheme } from "@material-ui/core/styles";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import { default as Edit, default as EditIcon } from "@material-ui/icons/Edit";
import {
  default as FilterList,
  default as FilterListIcon,
} from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { map } from "lodash";
import MaterialTable, { MaterialTableProps } from "material-table";
import React, { forwardRef, useRef } from "react";
import { useToggle } from "react-use";

const defaultTableIcons: MaterialTableProps<any>["icons"] = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export const Table = (
  props: MaterialTableProps<any> & {
    extraActions?: any;
    handleClickRowAdd?: () => void;
    label?: string;
  }
) => {
  const theme = useTheme();
  const tableIcons: MaterialTableProps<any>["icons"] = {
    ...defaultTableIcons,
    Delete: forwardRef((props, ref) => (
      <DeleteOutline
        {...props}
        ref={ref}
        style={{ color: theme.palette.primary.main }}
      />
    )),
    Edit: forwardRef((props, ref) => (
      <Edit
        {...props}
        ref={ref}
        style={{ color: theme.palette.primary.main }}
      />
    )),
  };
  const [isFiltering, toggleIsFiltering] = useToggle(false);
  const [isMoreActionsOpen, setIsMoreActionsOpen] = React.useState(false);
  const [isEditing, toggleIsEditing] = useToggle(false);
  const actions = props.actions || [];
  const extraActions = props.extraActions || [];
  const editable = props.editable && {
    ...props.editable,
  };
  const options = {
    ...(props.options || {}),
  };

  const handleMoreAction = (action: any) => () => {
    setIsMoreActionsOpen(false);
  };

  const filterListRef = useRef(null);

  if (!props.actions) {
    if (extraActions.length) {
      actions.push({
        icon: () => {
          return (
            <>
              <MoreHorizOutlinedIcon ref={filterListRef} />
              <Menu
                id="more-actions-menu"
                anchorEl={filterListRef.current}
                keepMounted
                open={isMoreActionsOpen}
                onClose={() => setIsMoreActionsOpen(false)}
              >
                {map(extraActions, (action) => (
                  <MenuItem onClick={action.onClick}>
                    {action.tooltip || null}
                    {action.icon ? (
                      <span
                        style={{
                          marginLeft: 6,
                          display: "flex",
                          alignContent: "center",
                        }}
                      >
                        {action.icon()}
                      </span>
                    ) : null}
                  </MenuItem>
                ))}
              </Menu>
            </>
          );
        },
        tooltip: "More Actions",
        isFreeAction: true,
        onClick: () => setIsMoreActionsOpen(!isMoreActionsOpen),
      });
    }
    if (options.filtering === undefined) {
      actions.push({
        icon: () => <FilterListIcon />,
        tooltip: `Filter${props.label ? ` ${props.label}` : ""}`,
        isFreeAction: true,
        onClick: toggleIsFiltering,
      });
      options.filtering = isFiltering;
    }

    if (editable && (editable.onRowDelete || editable.onRowUpdate)) {
      actions.push({
        icon: () => <EditIcon />,
        tooltip: `Edit${props.label ? ` ${props.label}` : ""}`,
        isFreeAction: true,
        onClick: toggleIsEditing,
      });

      if (!isEditing) {
        delete editable.onRowUpdate;
        delete editable.onRowDelete;
      }
    }

    if (props.handleClickRowAdd) {
      actions.push({
        icon: () => <AddBox />,
        tooltip: `Add${props.label ? ` ${props.label}` : ""}`,
        isFreeAction: true,
        onClick: props.handleClickRowAdd,
      });
    }
  }

  return (
    <MaterialTable
      icons={tableIcons}
      {...props}
      actions={actions}
      editable={editable}
      options={options}
    />
  );
};
