import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

export interface IGetAuthTokenResponse {
  plaid: {
    linkAccount: {
      linkToken: string;
    };
  };
}

export const GET_AUTH_TOKEN = gql`
  query GetAuthToken($input: LinkAccountInput!) {
    plaid {
      linkAccount(input: $input) {
        linkToken
      }
    }
  }
`;

interface IUseAuthTokenProps {
  userId: string;
  accessToken?: string;
}

export const useAuthToken = ({ userId, accessToken }: IUseAuthTokenProps) => {
  const [getToken, { loading, data, error }] =
    useLazyQuery<IGetAuthTokenResponse>(GET_AUTH_TOKEN, {
      variables: { input: { userId, accessToken } },
    });
  return {
    getToken,
    loading,
    error,
    token: data && data.plaid.linkAccount.linkToken,
  };
};
