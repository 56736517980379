import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import { useTheme } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import { Button } from "antd";
import { find, isEqual, keys, map } from "lodash";
import Papa from "papaparse";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { usePrevious } from "react-use";
import {
  IRawTransactionValue,
  ITransactionFieldMap,
  mapRawTransactions,
} from "../../mapper";
import { useCreateTransactions } from "../../mutations/CreateTransactions";
import { useUpdateAccount } from "../../mutations/UpdateAccount";
import { IAccount } from "../../types/Account";
import { ITransaction } from "../../types/Transaction";
import { SelectAccountForm } from "../forms/SelectAccount";
import RawTransactions, { IRawTransaction } from "../RawTransactions";
import SampleTransactions from "../SampleTransactions";
import Title from "../Title";
import AddBankAccountDialog from "./AddBankAccountDialog";

export type DeepPartial<T> = { [P in keyof T]?: DeepPartial<T[P]> };

export interface IAddTransactionsDialogProps {
  isOpen: boolean;
  account?: IAccount;
  accounts: IAccount[];
  userId: string;
  onSubmit: (transactions: ITransaction[]) => void;
  onClose: () => void;
}

const MINT_FIELDS = [
  "Date",
  "Description",
  "Original Description",
  "Amount",
  "Transaction Type",
  "Category",
  "Account Name",
  "Labels",
  "Notes",
];

export default function AddTransactionsDialog(
  props: IAddTransactionsDialogProps
) {
  const theme = useTheme();

  const [mintMode, setMintMode] = React.useState(false);
  const [rawTransactions, setRawTransactions] = React.useState<
    IRawTransaction[]
  >([]);
  const [selectedAccount, setSelectedAccount] = React.useState<
    Partial<IAccount>
  >(props.account || {});
  const { createTransactions, loading, error } = useCreateTransactions();
  const { updateAccount } = useUpdateAccount();
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    multiple: false,
    accept: ".csv",
    noClick: true,
    noKeyboard: true,
  });
  const previousAcceptedFiles = usePrevious(acceptedFiles);
  const [isAddAccountOpen, setIsAddAccountOpen] = useState(false);

  const fullScreen = true;
  const mintFieldMaps: ITransactionFieldMap[] = [
    {
      field: "date",
      equation: "Date",
    },
    {
      field: "account",
      equation: '"Account Name"',
      format: (name: IRawTransactionValue) => {
        const account = find(props.accounts, ["name", name]);
        if (!account) {
          throw new Error(`Account not found "${name}"`);
        }
        return account;
      },
      fix: (rawTransaction: IRawTransaction) => {
        openAddAccountDialog(rawTransaction["Account Name"] as string);
      },
    },
    {
      field: "description",
      equation: "Description",
    },
    {
      field: "originalDescription",
      equation: '"Original Description"',
    },
    {
      field: "type",
      equation: '"Transaction Type"',
    },
    {
      field: "category",
      equation: "Category",
    },
    {
      field: "amount",
      equation: "Amount",
    },
  ];

  const transactionFieldMaps = mintMode
    ? mintFieldMaps
    : selectedAccount.csvTransactionFieldMaps!;

  const hasHeader = mintMode || !!selectedAccount.csvHasHeader;

  const handleParse = (header = hasHeader) => {
    if (acceptedFiles.length > 0) {
      Papa.parse(acceptedFiles[0], {
        header,
        dynamicTyping: true,
        skipEmptyLines: true,
        error: console.error,
        complete: (results) => handleSetRawTransactions(results.data),
      });
    }
  };

  useEffect(handleParse, [
    selectedAccount && selectedAccount.csvTransactionFieldMaps,
  ]);

  const [accountName, setAccountName] = useState<string | number | undefined>();
  const openAddAccountDialog = (name?: string | number) => {
    setAccountName(name);
    setIsAddAccountOpen(true);
  };
  const handleCloseAddAccountDialog = () => {
    setAccountName(undefined);
    setIsAddAccountOpen(false);
  };

  const setHasHeader = (event: any) => {
    setSelectedAccount({
      ...selectedAccount,
      csvHasHeader: event.target.checked,
    });
    handleParse(event.target.checked);
  };

  const handleSetSelectedAccount = (account: IAccount) => {
    setSelectedAccount(account);
  };

  const handleSetMintMode = (newMintMode: boolean) => {
    if (newMintMode !== mintMode) {
      setMintMode(newMintMode);
      handleParse(newMintMode || !!selectedAccount.csvHasHeader);
    }
  };

  const handleSetRawTransactions = (rawTransactions: IRawTransaction[]) => {
    const header = hasHeader ? keys(rawTransactions[0]) : rawTransactions[0];
    handleSetMintMode(isEqual(header, MINT_FIELDS));
    setRawTransactions(rawTransactions);
  };

  const handleSetFieldMaps = (fieldMaps: ITransactionFieldMap[]) => {
    setSelectedAccount({
      ...selectedAccount,
      csvTransactionFieldMaps: fieldMaps,
    });
  };

  const handleSubmit = async () => {
    try {
      const transactions = map(
        mapRawTransactions(transactionFieldMaps, rawTransactions),
        (t) => ({
          type: t.amount < 0 ? "debit" : "credit",
          ...t,
          account: {
            id: (t.account || selectedAccount).id,
          },
          amount: Math.abs(t.amount),
        })
      );
      const { data } = await createTransactions({
        variables: {
          transactions,
        },
      });

      if (!mintMode) {
        await updateAccount({
          variables: {
            account: {
              id: selectedAccount.id!,
              csvTransactionFieldMaps:
                selectedAccount.csvTransactionFieldMaps as any[],
              csvHasHeader: selectedAccount.csvHasHeader,
            },
          },
        });
      }

      props.onSubmit(data ? data.createTransactions : []);
    } catch (e) {
      const errorMsgStart = "Account not found";
      if (e.message.indexOf(errorMsgStart) === 0) {
        const name = e.message
          .slice(errorMsgStart.length)
          .replace(/"/g, "")
          .trim();
        openAddAccountDialog(name);
      }
      console.error(e);
    }
  };

  const onEnter = () => {
    setSelectedAccount(props.account || {});
    handleSetMintMode(false);
    setRawTransactions([]);
  };

  if (!isEqual(acceptedFiles, previousAcceptedFiles)) {
    handleParse();
  }

  const sampleSize = Math.min(rawTransactions.length, 5);

  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={!!error}
        autoHideDuration={2500}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{error && error.message}</span>}
      />
      {accountName && isAddAccountOpen && (
        <AddBankAccountDialog
          isOpen={isAddAccountOpen}
          onClose={handleCloseAddAccountDialog}
          onSubmit={handleCloseAddAccountDialog}
          initialAccount={{ name: accountName as any }}
        />
      )}
      <Dialog
        fullScreen={fullScreen}
        disableBackdropClick
        disableEscapeKeyDown
        open={props.isOpen}
        onClose={props.onClose}
        onEnter={onEnter}
      >
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <DialogTitle>
            <Grid container style={{ flex: 1 }}>
              <Grid item xs={8}>
                Add Transactions
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            {(mintMode && (
              <Typography component="h2" variant="caption" color="primary">
                Mint File Detected
              </Typography>
            )) || (
              <Grid container style={{ flex: 1 }}>
                <Grid
                  item
                  xs={10}
                  style={{ alignItems: "flex-end", justifyContent: "flex-end" }}
                >
                  <SelectAccountForm
                    fullWidth
                    selectedAccount={selectedAccount as IAccount}
                    accounts={props.accounts}
                    onChange={handleSetSelectedAccount}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={hasHeader}
                        onChange={setHasHeader}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    }
                    label="Has A Header"
                  />
                </Grid>
              </Grid>
            )}
            {(rawTransactions.length && (
              <React.Fragment>
                <Title style={{ marginTop: 20 }}>Uploaded Transactions</Title>
                <Divider />
                <RawTransactions
                  rawTransactions={rawTransactions.slice(0, sampleSize)}
                />
                <Title style={{ marginTop: 20 }}>Cleansed Transactions</Title>
                <Divider />
                <SampleTransactions
                  rawTransactions={rawTransactions.slice(0, sampleSize)}
                  account={selectedAccount as IAccount}
                  isEditable={!mintMode}
                  onTransactionFieldMapsChange={handleSetFieldMaps}
                  transactionFieldMaps={transactionFieldMaps}
                />
              </React.Fragment>
            )) || (
              <div style={{ padding: 8 }}>
                <DialogContentText onClick={open}>
                  Drag 'n' drop some files here
                </DialogContentText>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <div style={{ flex: "1 0 0" }} />
            <Button onClick={props.onClose}>Cancel</Button>
            <Button loading={loading} onClick={handleSubmit} color="primary">
              Submit
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </React.Fragment>
  );
}
