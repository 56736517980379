import { makeStyles } from "@material-ui/core/styles";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import ReceiptIcon from "@material-ui/icons/Receipt";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import React, { useMemo } from "react";
import { IAccount } from "../types/Account";
import AddBalanceDialog from "./dialogs/AddBalanceDialog";
import AddBankAccountDialog from "./dialogs/AddBankAccountDialog";
import AddTransactionsDialog from "./dialogs/AddTransactionsDialog";

enum AddMode {
  TRANSACTION,
  BALANCE,
  ACCOUNT,
}

const useStyles = makeStyles((theme) => ({
  addButtonWrapper: {
    position: "absolute",
    bottom: 20,
    right: 20,
    width: 80,
    height: "auto",
    zIndex: 20,
  },
  speedDial: {
    width: "100%",
    height: "auto",
  },
}));

export interface IAddStuffSpeedDialProps {
  userId: string;
  selectedAccounts?: IAccount[];
  accounts: IAccount[];
}

const AddStuffSpeedDial = ({
  userId,
  selectedAccounts,
  accounts,
}: IAddStuffSpeedDialProps) => {
  const classes = useStyles();
  const [speedDialOpen, setSpeedDialOpen] = React.useState(false);
  const [addMode, setAddMode] = React.useState<AddMode | undefined>();

  const speedDialActions = [
    {
      icon: <ReceiptIcon />,
      name: "Transactions",
      onClick: () => setAddMode(AddMode.TRANSACTION),
    },
    {
      icon: <AccountBalanceWalletIcon />,
      name: "Balance",
      onClick: () => setAddMode(AddMode.BALANCE),
    },
    {
      icon: <AccountBalanceIcon />,
      name: "Bank Account",
      onClick: () => setAddMode(AddMode.ACCOUNT),
    },
  ];

  const handleAddDialogClose = () => setAddMode(undefined);
  const handleSpeedDialClose = () => setSpeedDialOpen(false);
  const handleSpeedDialOpen = () => setSpeedDialOpen(true);
  const selectedAccount = useMemo(
    () =>
      selectedAccounts && selectedAccounts.length === 1
        ? selectedAccounts[0]
        : undefined,
    [selectedAccounts]
  );

  return (
    <React.Fragment>
      <div className={classes.addButtonWrapper}>
        <SpeedDial
          ariaLabel="Add Accounts or Transactions"
          className={classes.speedDial}
          icon={<SpeedDialIcon />}
          onClose={handleSpeedDialClose}
          onOpen={handleSpeedDialOpen}
          open={speedDialOpen}
          direction="up"
        >
          {speedDialActions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={action.onClick || handleSpeedDialClose}
            />
          ))}
        </SpeedDial>
      </div>
      <AddTransactionsDialog
        userId={userId}
        account={selectedAccount}
        accounts={accounts}
        isOpen={addMode === AddMode.TRANSACTION}
        onClose={handleAddDialogClose}
        onSubmit={handleAddDialogClose}
      />
      <AddBalanceDialog
        userId={userId}
        account={selectedAccount}
        accounts={accounts}
        isOpen={addMode === AddMode.BALANCE}
        onClose={handleAddDialogClose}
        onSubmit={handleAddDialogClose}
      />
      <AddBankAccountDialog
        isOpen={addMode === AddMode.ACCOUNT}
        onClose={handleAddDialogClose}
        onSubmit={handleAddDialogClose}
      />
    </React.Fragment>
  );
};

export default AddStuffSpeedDial;
