import { gql } from "apollo-boost";

export const TRANSFER_FRAGMENT = gql`
  fragment TransferFragment on Transfer {
    id
    isManual
    incomingTransaction {
      id
      description
      date
      amount
      account {
        id
        name
      }
    }
    outgoingTransaction {
      id
      description
      date
      amount
      account {
        id
        name
      }
    }
  }
`;
