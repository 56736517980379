import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { INSTITUTION_FRAGMENT } from "../fragments/Institution";
import { IInstitution } from "../types/Institution";

export const GET_INSTITUTIONS = gql`
  ${INSTITUTION_FRAGMENT}
  query GetInstitutions {
    institutions {
      ...InstitutionFragment
    }
  }
`;

export const useInstitutions = () => {
  const { loading, data, error } =
    useQuery<{ institutions: IInstitution[] }>(GET_INSTITUTIONS);
  return {
    loading,
    error,
    institutions: data && data.institutions,
  };
};
