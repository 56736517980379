import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import LinkOffIcon from "@material-ui/icons/LinkOff";
import RefreshIcon from "@material-ui/icons/Refresh";
import SyncIcon from "@material-ui/icons/Sync";
import { filter, find, flatten, get, map, min, sortBy } from "lodash";
import moment from "moment";
import React, { useMemo, useRef, useCallback } from "react";
import { getAccountName, getTotalBalance } from "../helpers";
import { useLinkInstitution, useSyncInstitutions } from "../hooks/institution";
import { useInstitutionSyncStatus } from "../hooks/state";
import { useUser } from "../hooks/user";
import { IAccount } from "../types/Account";
import { IInstitution } from "../types/Institution";
import { Balance } from "./Balance";
import { DropdownMenu } from "./DropdownMenu";
import { StatusIcon } from "./StatusIcon";
import {
  IUpdateAccountInput,
  useUpdateAccount,
} from "../mutations/UpdateAccount";

export interface IInstitutionProps {
  institution: IInstitution;
  accounts: IAccount[];
  showInactiveAccounts?: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  balanceContainer: {
    //  paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  title: {
    textAlign: "start",
    color: theme.palette.primary.main,
  },
  text: {
    textAlign: "start",
  },
  center: {
    alignItems: "center",
  },
  rightText: {
    textAlign: "end",
  },
  balance: {
    textAlign: "right",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 480,
  },
  menuIconContainer: {
    marginLeft: 6,
    display: "flex",
    alignContent: "center",
  },
}));

export interface IInstitutionAccountProps {
  account: IAccount;
  showInactive?: boolean;
}

const InstitutionAccount = ({ account, showInactive }: IInstitutionAccountProps) => {
  const { type, id, isActive } = account;
  const classes = useStyles();
  const balance = getTotalBalance([account]);
  const name = getAccountName(account);
  const isLinked = account.plaidId;
  const { updateAccount } = useUpdateAccount();
  const toggleIsActive = useCallback(() => {
  	return updateAccount({
		variables: {
			account: {
				id,
				isActive: !isActive,
			}
		}
	});
  }, [isActive, id]);
  return (
    <Grid container item alignItems="center">
      <Grid item xs={6} sm={8} className={classes.text}>
        <Box display="flex" className={classes.center}>
          <Typography component="p">{name}</Typography>
          {showInactive ? (
            <Tooltip arrow title={`This account is ${isActive ? '' : 'in' }active`}>
              <span className={classes.menuIconContainer} onClick={toggleIsActive}>
                <AccountBalanceIcon color={isActive ? 'inherit' : 'disabled'} />
              </span>
            </Tooltip>
          ) : null}
        </Box>
      </Grid>
      <Grid item xs={3} sm={2} className={classes.rightText}>
        <Typography component="p">{type}</Typography>
      </Grid>
      <Grid item xs={3} sm={2} className={classes.balance}>
        <Grid container justify="flex-end">
          <Box flexShrink={1} display="flex" style={{ gap: 3 }}>
            {!isLinked ? (
              <LinkOffIcon fontSize="small" color="disabled" />
            ) : null}
            <Balance balance={balance} variant="subtitle2" slim />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const Institution = ({ institution, accounts, showInactiveAccounts }: IInstitutionProps) => {
  const [syncStatus] = useInstitutionSyncStatus(institution.id);
  const [user] = useUser();
  const accessToken = useMemo(
    () =>
      user &&
      get(
        find(user.plaidLinks, ["institution.id", institution.id]),
        "accessToken"
      ),
    [user, institution]
  );
  const linkInstitution = useLinkInstitution({ accessToken });
  const { syncInstitutions } = useSyncInstitutions();
  const { updateAccount } = useUpdateAccount();
  const sortedAccounts = useMemo(
    () => sortBy(accounts, [(account) => !account.isAsset, "type", "name"]),
    [accounts]
  );
  const isActive = useMemo(() => find(sortedAccounts, 'isActive') !== undefined, [sortedAccounts]);
  const classes = useStyles();
  const actionsRef = useRef(null);
  const toggleIsActive = useCallback(() => {
  	return Promise.map(filter(sortedAccounts, ['isActive', isActive]), account => updateAccount({
		variables: {
			account: {
				id: account.id,
				isActive: !isActive,
			}
		}
	}));
  }, [isActive, sortedAccounts]);
  const menuItems = useMemo(
    () => {
      const menuItems = [];
	if (accessToken) {
            menuItems.push({
              onClick: () => syncInstitutions({ institution }),
              icon: <SyncIcon />,
              title: "Sync Institution",
            });
            menuItems.push({
              onClick: () => linkInstitution.open(),
              icon: <RefreshIcon />,
              title: "Re-link Institution",
            });
	}

	if (showInactiveAccounts) {
		menuItems.push({
		      onClick: toggleIsActive,
		      icon: <AccountBalanceIcon />,
		      title: `${isActive ? 'Hide' : 'Show'} Accounts`,
		});
	}

	return menuItems;
  }, [showInactiveAccounts, accessToken, syncInstitutions, linkInstitution, isActive, toggleIsActive]);
  const needsManualUpdate = useMemo(() => {
    const oldestDate = min(
      map(flatten(map(accounts, "currentBalances")), "date")
    );
    if (oldestDate) {
      return moment().isAfter(oldestDate, "month");
    }
    return false;
  }, [accounts]);

  return (
    <Card>
      <Box p={1} display="flex">
        <Box flexGrow={1} display="flex" className={classes.center}>
          <Typography variant="h6" color="primary" className={classes.title}>
            {institution.name}
          </Typography>
          <span className={classes.menuIconContainer}>
            <StatusIcon
              size={14}
              loading={syncStatus.isSyncing}
              error={syncStatus.error}
              warning={
                needsManualUpdate
                  ? "One or more accounts are out of date"
                  : syncStatus.warning
              }
            />
          </span>
        </Box>
        <Box flexShrink={1}>
          {menuItems.length ? <DropdownMenu items={menuItems} /> : null}
        </Box>
      </Box>
      <Box p={1}>
        <Grid container className={classes.balanceContainer}>
          <Grid item xs={6} sm={8} className={classes.text}>
            <Typography variant="subtitle1">Name</Typography>
          </Grid>
          <Grid item xs={3} sm={2} className={classes.rightText}>
            <Typography variant="subtitle1">Type</Typography>
          </Grid>
          <Grid item xs={3} sm={2} className={classes.balance}>
            <Typography variant="subtitle1">Balance</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} direction="column">
          {map(sortedAccounts, (account) => (
            <InstitutionAccount account={account} key={account.id} showInactive={showInactiveAccounts} />
          ))}
        </Grid>
      </Box>
    </Card>
  );
};
