import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { TRANSACTION_LABELS_FRAGMENT } from "../fragments/Transaction";
import { ITransaction } from "../types/Transaction";

export interface ILabelTransactionInput {
  id: string;
  description: string;
  date: string | Date;
  originalDescription?: string;
}

export const LABEL_TRANSACTION = gql`
  ${TRANSACTION_LABELS_FRAGMENT}
  mutation LabelTransaction($input: LabelTransactionInput!) {
    labelTransaction(input: $input) {
      id
      ...TransactionLabelsFragment
    }
  }
`;

export const useLabelTransaction = () => {
  const [labelTransaction, { called, loading, data, error }] = useMutation<{
    labelTransaction: Pick<ITransaction, "labels">;
  }>(LABEL_TRANSACTION, {
    refetchQueries: ["LabelTransaction"],
  });

  return {
    called,
    labelTransaction,
    loading,
    error,
    transaction: data && data.labelTransaction,
  };
};
