import { gql } from "apollo-boost";
import { ACCOUNT_FRAGMENT } from "./Account";
import { INSTITUTION_FRAGMENT } from "./Institution";

export const USER_FRAGMENT = gql`
  ${ACCOUNT_FRAGMENT}
  ${INSTITUTION_FRAGMENT}
  fragment UserFragment on User {
    id
    name
    email
    accounts {
      ...AccountFragment
    }
    plaidLinks {
      id
      accessToken
      institution {
        ...InstitutionFragment
      }
    }
  }
`;
