import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { omit, pick } from "lodash";
import { TRANSACTION_BASE_FRAGMENT } from "../fragments/Transaction";
import { ITransfer } from "../types/Transfer";

export interface IIDInput {
  id: string;
}

export interface IUpsertTransferInput {
  id?: string;
  incomingTransaction: IIDInput;
  outgoingTransaction: IIDInput;
}

export const UPSERT_TRANSFERS = gql`
  ${TRANSACTION_BASE_FRAGMENT}
  mutation UpsertTransfer($transfer: UpsertTransferInput!) {
    upsertTransfer(transfer: $transfer) {
      id
      incomingTransaction {
        ...TransactionBaseFragment
      }
      outgoingTransaction {
        ...TransactionBaseFragment
      }
    }
  }
`;

export const useUpsertTransfer = () => {
  const [upsertTransfer, { called, loading, data, error }] = useMutation<
    {
      upsertTransfer: ITransfer;
    },
    {
      transfer: IUpsertTransferInput;
    }
  >(UPSERT_TRANSFERS, {
    refetchQueries: ["GetTransactions"],
  });

  const handleUpsertTransfer = (transfer: ITransfer) => {
    const transferInput = omit(transfer, [
      "incomingTransaction",
      "outgoingTransaction",
      "__typename",
    ]) as any;
    if (transfer.incomingTransaction) {
      transferInput.incomingTransaction = pick(
        transfer.incomingTransaction,
        "id"
      );
    }
    if (transfer.outgoingTransaction) {
      transferInput.outgoingTransaction = pick(
        transfer.outgoingTransaction,
        "id"
      );
    }
    return upsertTransfer({
      variables: {
        transfer: transferInput,
      },
    });
  };

  return {
    called,
    upsertTransfer: handleUpsertTransfer,
    loading,
    error,
    transfer: data && data.upsertTransfer && data.upsertTransfer,
  };
};
