import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import { map } from "lodash";
import React, { useRef, useState } from "react";

const useStyles = makeStyles((theme) => ({
  menuIconContainer: {
    marginRight: 6,
    display: "flex",
    alignContent: "center",
  },
}));

export interface IDropdownMenuItem {
  icon: React.ReactNode;
  title: string;
  onClick: () => void;
}
export interface IDropdownMenuProps {
  items: IDropdownMenuItem[];
}

export const DropdownMenu = ({ items }: IDropdownMenuProps) => {
  const classes = useStyles();
  const ref = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <>
      <MoreHorizOutlinedIcon ref={ref} onClick={() => setIsMenuOpen(true)} />
      <Menu
        anchorEl={ref.current}
        keepMounted
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
      >
        {map(items, ({ onClick, title, icon }, idx) => (
          <MenuItem
            onClick={(evt) => {
              evt.stopPropagation();
              setIsMenuOpen(false);
              setTimeout(onClick, 500);
            }}
            key={idx}
          >
            <span className={classes.menuIconContainer}>{icon}</span>
            {title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
