import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import React from "react";

export interface IStatusIconProps {
  loading?: boolean;
  complete?: string;
  warning?: string;
  error?: Error;
  size?: number;
}

export const StatusIcon = ({
  loading,
  error,
  warning,
  complete,
  size,
}: IStatusIconProps) => {
  if (loading) {
    return <CircularProgress size={size} />;
  } else if (error) {
    return (
      <Tooltip title={error.message}>
        <ErrorOutlineIcon style={{ color: "red", fontSize: size }} />
      </Tooltip>
    );
  } else if (warning) {
    return (
      <Tooltip title={warning}>
        <InfoOutlinedIcon style={{ color: "orange", fontSize: size }} />
      </Tooltip>
    );
  } else if (complete) {
    return (
      <Tooltip title={complete}>
        <CheckCircleOutlinedIcon style={{ color: "green", fontSize: size }} />
      </Tooltip>
    );
  } else {
    return null;
  }
};
