import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { BALANCE_FRAGMENT } from "../fragments/Balance";
import { IBalance } from "../types/Balance";
import { IIDInput } from "./CreateTransactions";

export interface ICreateBalanceInput {
  date: Date;
  balance: number;
  account: IIDInput;
  asset?: string;
}

export const CREATE_BALANCE = gql`
  ${BALANCE_FRAGMENT}
  mutation CreateBalance($balance: CreateBalanceInput!) {
    createBalance(balance: $balance) {
      ...BalanceFragment
    }
  }
`;

export const useCreateBalance = () => {
  const [createBalance, { called, loading, data, error }] = useMutation<{
    createBalance: {
      balance: IBalance;
    };
  }>(CREATE_BALANCE);
  return {
    called,
    createBalance,
    loading,
    error,
    balance: data && data.createBalance && data.createBalance.balance,
  };
};
