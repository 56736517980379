import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { useState } from "react";
import { useLogin } from "../../mutations/Login";
import { IUser } from "../../types/User";
import ErrorCatcher from "../ErrorCatcher";
import { ILoginVariables, LoginForm } from "../forms/LoginForm";
import { useShouldDialogFullscreen } from "./hooks";

export interface ILoginDialogProps {
  isOpen: boolean;
  onLogin: (args: { user: IUser; token: string }) => void;
  onClose?: () => void;
}

export default function LoginDialog({
  isOpen,
  onClose,
  onLogin,
}: ILoginDialogProps) {
  const fullScreen = useShouldDialogFullscreen();
  const [variables, setVariables] = useState<ILoginVariables>({
    email: "",
    password: "",
  });
  const { login, error, loading } = useLogin();
  const handleSubmit = async () => {
    try {
      const { data } = await login({
        variables,
      });
      if (onClose) {
        onClose();
      }
      if (data) {
        onLogin(data.login);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <React.Fragment>
      <ErrorCatcher error={error} />
      <Dialog
        fullScreen={fullScreen}
        disableBackdropClick
        disableEscapeKeyDown
        open={isOpen}
        onClose={onClose}
      >
        <DialogTitle>Login</DialogTitle>
        <DialogContent>
          <LoginForm data={variables} onChange={setVariables} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} color="primary">
            Login
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
