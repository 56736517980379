import { gql } from "apollo-boost";
import { BALANCE_FRAGMENT } from "./Balance";
import { INSTITUTION_FRAGMENT } from "./Institution";

export const ACCOUNT_FRAGMENT = gql`
  ${INSTITUTION_FRAGMENT}
  ${BALANCE_FRAGMENT}
  fragment AccountFragment on Account {
    id
    plaidId
    name
    number
    nickname
    currency
    isAsset
    isActive
    type
    csvHasHeader
    csvTransactionFieldMaps {
      id
      field
      equation
      defaultValue
    }
    currentBalances {
      ...BalanceFragment
    }
    institution {
      ...InstitutionFragment
    }
    assets {
      symbol
      altSymbol
      name
      currency
      isUnverified
    }
  }
`;
