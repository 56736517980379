import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import {
  IAnnualCashflow,
  ICashflow,
  IMortgageCashflow,
} from "../types/Cashflow";

export interface IIDInput {
  id: string;
}

export const DELETE_CASHFLOW = gql`
  mutation DeleteCashflow($id: ID!) {
    deleteCashflow(id: $id) {
      id
    }
  }
`;

export const useDeleteCashflow = () => {
  const [deleteCashflow, { called, loading, data, error }] = useMutation<
    {
      deleteCashflow: IAnnualCashflow | IMortgageCashflow;
    },
    IIDInput
  >(DELETE_CASHFLOW, {
    refetchQueries: ["GetCashflows"],
  });

  const handleDeleteCashflow = async (cashflow: ICashflow) => {
    if (cashflow.id) {
      return deleteCashflow({
        variables: {
          id: cashflow.id,
        },
      });
    }
  };

  return {
    called,
    deleteCashflow: handleDeleteCashflow,
    loading,
    error,
    cashflow: data && data.deleteCashflow,
  };
};
