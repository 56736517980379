import { gql } from "apollo-boost";

export const CASHFLOW_FRAGMENT = gql`
  fragment CashflowFragment on Cashflow {
    id
    title
    description
    annualInterestRate
    interestRate
    numberOfPayments
    payment
    paymentPeriod
    presentValue
    startDate
    type
    portfolios {
      id
      title
      description
    }
    years
    askingPrice
    downDeposit
  }
`;
