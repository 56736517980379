import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { IInstitution } from "../types/Institution";
import { IIDInput } from "./CreateTransactions";

export interface ILinkInstitutionInput {
  code: string;
  institution: IIDInput;
  // TODO Add the accounts here
}

export const LINK_INSTITUTION = gql`
  mutation LinkInstitution($input: LinkInstitutionInput!) {
    linkInstitution(input: $input) {
      success
    }
  }
`;

export interface IPlaidLink {
  id: string;
  institution: IInstitution;
  // TODO accounts: IAccount[]
}

export const useLinkInstitution = () => {
  const [linkInstitution, { called, loading, data, error }] = useMutation<
    {
      linkInstitution: IPlaidLink;
    },
    { input: ILinkInstitutionInput }
  >(LINK_INSTITUTION, {
    update: (cache, { data }) => {
      // TODO update the cache maybe
      /*
      const newAccount = data && data.linkInstitution
      if (newAccount) {
        const cachedData = cache.readQuery<IGetAccountsResponse>({ query: GET_ACCOUNTS })
        const institutions = cachedData && cachedData.institutions || []
        cache.writeQuery({ query: GET_ACCOUNTS, data: {
          institutions: institutions.concat(newAccount)
        }})
      }
      */
    },
  });
  return {
    called,
    linkInstitution,
    loading,
    error,
    link: data && data.linkInstitution,
  };
};
