import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { map, omit, pick } from "lodash";
import { CASHFLOW_FRAGMENT } from "../fragments/Cashflow";
import {
  IAnnualCashflow,
  ICashflow,
  IMortgageCashflow,
} from "../types/Cashflow";

export interface IIDInput {
  id: string;
}

export interface IUpsertCashflowInput
  extends Partial<Omit<ICashflow, "portfolios">> {
  portfolios?: IIDInput[];
}

export const UPSERT_CASHFLOW = gql`
  ${CASHFLOW_FRAGMENT}
  mutation UpsertCashflow($cashflow: UpsertCashflowInput!) {
    upsertCashflow(cashflow: $cashflow) {
      ...CashflowFragment
    }
  }
`;

export const useUpsertCashflow = () => {
  const [upsertCashflow, { called, loading, data, error }] = useMutation<
    {
      upsertCashflow: IAnnualCashflow | IMortgageCashflow;
    },
    {
      cashflow: IUpsertCashflowInput;
    }
  >(UPSERT_CASHFLOW, {
    refetchQueries: ["GetCashflows", "GetPortfolios"],
  });

  const handleUpsertCashflow = async (cashflow: ICashflow) => {
    const cashflowInput = omit(cashflow, ["portfolios", "__typename"]) as any;
    if (cashflow.portfolios) {
      cashflowInput.portfolios = map(cashflow.portfolios, (portfolio) =>
        pick(portfolio, "id")
      );
    }

    return upsertCashflow({
      variables: {
        cashflow: cashflowInput,
      },
    });
  };

  return {
    called,
    upsertCashflow: handleUpsertCashflow,
    loading,
    error,
    cashflow: data && data.upsertCashflow,
  };
};
