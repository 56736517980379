import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { INSTITUTION_FRAGMENT } from "../fragments/Institution";
import { GET_INSTITUTIONS } from "../queries/GetInstitutions";
import { IInstitution } from "../types/Institution";

export interface ICreateInstitutionInput {
  name: string;
  logo?: string;
  plaidId?: string;
}

export const CREATE_INSTITUTION = gql`
  ${INSTITUTION_FRAGMENT}
  mutation CreateInstitution($institution: CreateInstitutionInput!) {
    createInstitution(institution: $institution) {
      ...InstitutionFragment
    }
  }
`;

export const useCreateInstitution = () => {
  const [createInstitution, { called, loading, data, error }] = useMutation<{
    createInstitution: IInstitution;
  }>(CREATE_INSTITUTION, {
    update: (cache, { data }) => {
      const newInstitution = data && data.createInstitution;
      if (newInstitution) {
        const cachedData = cache.readQuery<{ institutions: IInstitution[] }>({
          query: GET_INSTITUTIONS,
        });
        const institutions = (cachedData && cachedData.institutions) || [];
        cache.writeQuery({
          query: GET_INSTITUTIONS,
          data: {
            institutions: institutions.concat(newInstitution),
          },
        });
      }
    },
  });
  return {
    called,
    createInstitution,
    loading,
    error,
    institution: data && data.createInstitution && data.createInstitution,
  };
};
